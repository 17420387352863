import React, { forwardRef, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useTranslate } from "./TranslationText";
// date Picker
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { PickersTextField } from "@mui/x-date-pickers";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatDate } from './../helper/formatDate'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import PhoneNumber from 'libphonenumber-js';

dayjs.extend(utc); // Extend Day.js to include the UTC plugin
dayjs.extend(timezone);
// dayjs.tz.setDefault("Asia/Dubai")

String.prototype.isWhitespacesOrEmpty = function () {
  return this.trim().length < 1;
};

const NetaqInput = forwardRef(({
  name,
  placeholder,
  type,
  value,
  checked,
  defaultChecked,
  label,
  urlLabel,
  onChange,
  maxchars = 0,
  Options,
  initialValue,
  disabled = false,
  textareaRows = 6,
  textAreaHeight = "150px",
  listSelect,
  htmlTags,
  info = false,
  defaultValue,
  isEditRequest = false,
  isWithURL = false,
  url = "",
  required = false,
  minDate,
  maxDate,
  minValue,
  hideLable,
  isClearable = false,
  isMulti = false,
  onClick,
  style = {},
  isDash = false,
  disableEmptyValue = false,
  onKeydown,
  setIsPhoneNumberValid,
  ishorizon = false,

  ...others
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();
  const editorRef = useRef(null);
  const normalDateRef = useRef();
  const alinormalDateRef = useRef();
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (name?.isWhitespacesOrEmpty() ?? true)
    return (
      <span style={{ color: "red" }}>
        Cannot Create Input without "name" attribute!
      </span>
    );

  if (!!onChange && !(onChange instanceof Function))
    throw new Error("OnChange attribute must be a callback function!");

  const transformToLabel = (text) => text.split(/(?=[A-Z])(?<!^)/)?.join(" ");
  const labelText = label ?? transformToLabel(name);
  const handleDateChange = (newDate, name) => {
    if (newDate) {
      // Set time to 00:00:00 to avoid timezone offset issues
      const adjustedDate = dayjs(newDate).startOf('day'); // Reset time to 00:00:00


      onChange(adjustedDate, name); // Pass adjusted date back
    } else {
      onChange(null, name); // Handle null case if date is cleared
    }
  };

  // validate Phone Number
  const validatePhoneNumber = (phoneNumber = "", setIsValid, country) => {
    if (phoneNumber == country?.dialCode) {
      return true;
    }
    const myphonenumber = PhoneNumber;
    try {
      const phoneUtil = myphonenumber(phoneNumber, country?.iso2.toUpperCase());
      if (phoneUtil) {

        const isValidNumber = phoneUtil.isValid();
        const countryCodeLength = country?.dialCode.length;
        let extractedNumber = phoneNumber.substring(countryCodeLength);
        const isValidLength = extractedNumber.length > 6;
        const isValidNumberWithLenght = isValidNumber && isValidLength;
        setIsValid(isValidNumberWithLenght);
        return isValidNumberWithLenght;
      } else return false;
    } catch (error) {
      console.error('Invalid phone number:', error);
      setIsValid(false);
      return false;
    }
  };

  return (
    <div className="form-group">
      {type === "text" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {!isDash && !isWithURL && !!label && label.replace(/-/g, " ")}{" "}
              {isDash && !isWithURL && !!label && label}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          {htmlTags}
          <input
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            style={style}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            value={value}
            required={required}
            disabled={disabled}
            maxLength={maxchars > 0 ? maxchars : "unset"}
            ref={ref}
          />
        </React.Fragment>
      ) : null}
      {type === "horseName" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {!isDash && !isWithURL && !!label && label.replace(/-/g, " ")}{" "}
              {isDash && !isWithURL && !!label && label}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          {htmlTags}
          <input
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            style={style}
            placeholder={placeholder}
            name={name}
            onKeyDown={(e) => {
              if (
                !/^[a-zA-Z.\u0600-\u06FF\u200C\u200D\s]$/.test(e.key) && // Allow English (A-Z), Arabic (\u0600-\u06FF), dot (.), Arabic space (\u200C, \u200D), and normal spaces
                !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key) // Allow navigation keys
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              onChange(e);
            }}
            defaultValue={value}
            value={value}
            required={required}
            disabled={disabled}
            maxLength={maxchars > 0 ? maxchars : "unset"}
          />
        </React.Fragment>
      ) : null}
      {type === "orginisertext" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {
                //name
                !!labelText && labelText.replace(/-/g, " ")
              }{" "}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          {htmlTags}
          <input
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            style={style}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            required={required}
            disabled={disabled}
          />
        </React.Fragment>
      ) : null}
      {type === "textarea" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {
                //name
                !!labelText && labelText.replace(/-/g, " ")
              }{" "}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          {htmlTags}
          <textarea
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            value={value}
            maxLength={maxchars > 0 ? maxchars : "unset"}
            disabled={disabled}
            rows={textareaRows}
            required={required}
            style={{ height: textAreaHeight, marginBottom: 15 }}
            ref={ref}
          />
        </React.Fragment>
      ) : null}
      {type === "textareaorganizer" ? (
        <React.Fragment>
          {!hideLable && (
            <label htmlFor={label} className="capatalize_text">
              {
                //name
                !!labelText && labelText.replace(/-/g, " ")
              }{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          )}
          {htmlTags}
          <textarea
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            maxLength={maxchars > 0 ? maxchars : "unset"}
            disabled={disabled}
            rows={6}
            style={{ height: 150, marginBottom: 15 }}
          />
        </React.Fragment>
      ) : null}
      {/* {type === "select" ? (
        <React.Fragment>
          <label htmlFor={label} className="capatalize_text">
            {label.replace(/-/g, " ")}{required? <i style={{color:'red'}}>*</i>:''}
          </label>
          {htmlTags}
          <select
            type="text"
            className="form-control"
            id={label}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            <option value="">{label}</option>
            {Options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </React.Fragment>
      ) : null} */}
      {type === "selectislam" ? (
        <React.Fragment>
          <select
            type="text"
            className="form-select text-right-rtl"
            id={label}
            style={{ marginBottom: 0 }}
            aria-describedby={label}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            {disableEmptyValue == false &&
              <option value="">{label}</option>
            }
            {Options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </React.Fragment>
      ) : null}
      {type === "email" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {label.replace(/-/g, " ")}{" "}
            {required ? (
              <i style={{ color: "red", margin: "auto 2px" }}>*</i>
            ) : (
              ""
            )}
          </label>
          {htmlTags}
          <input
            type="email"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            defaultValue={value}
            value={value}
            disabled={disabled}
            required={required}
            others
          />
        </React.Fragment>
      ) : null}
      {type === "password" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {label.replace(/-/g, " ")}{" "}
            {required ? (
              <i style={{ color: "red", margin: "auto 2px" }}>*</i>
            ) : (
              ""
            )}
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            disabled={disabled}
            required={required}
          />

          <div className="position-relative">
            <button
              type="button"
              className="passwordButton"
              style={{
                right: currentLanguage == "en" ? 10 : "unset",
                left: currentLanguage == "ar" ? 10 : "unset",
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </React.Fragment>
      ) : null}
      {type === "radio" ? (
        <React.Fragment>
          <div className="d-flex-row-itemscenter-justify-flexStart">
            <input
              className="form-check-input radiobuttonstyle"
              type="radio"
              name={name}
              value={value}
              defaultChecked={checked}
              id={label}
              onChange={onChange}
              disabled={disabled}
            />
            <label className="form-check-label radioLable" htmlFor={label}>
              {label.replace(/-/g, " ")}{" "}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
              {info ? <i class="info_circle  fa-solid fa-circle-info"></i> : ""}
            </label>
          </div>
        </React.Fragment>
      ) : null}

      {type === "radioServiceRequest" ? (
        <React.Fragment>
          <div className="d-flex-row-itemscenter-justify-flexStart">
            <input
              className="form-check-input radiobuttonstyle"
              type="radio"
              name={name}
              value={value}
              defaultChecked={checked}
              checked={checked}
              id={label}
              onChange={onChange}
              disabled={disabled}
            />
            <label className="form-check-label radioLable ms-2" htmlFor={label}>
              {label.replace(/-/g, " ")}{" "}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
              {info ? <i class="info_circle  fa-solid fa-circle-info"></i> : ""}
            </label>
          </div>
        </React.Fragment>
      ) : null}

      {type === "number" ? (
        <div className={ishorizon ? "d-flex" : ""}>
          {!hideLable && (
            <label htmlFor={name} className="capatalize_text">
              {!!label && label.replace(/-/g, " ")}{" "}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          {htmlTags}

          <input
            style={ishorizon ? { maxWidth: 145, marginTop: "-5px" } : {}}
            type="number"
            className={ishorizon ? "form-control ms-1 text-center" : "form-control"}
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            onChange={onChange}
            min={minValue ?? 0}
            onWheel={(e) => e.target.blur()}
            disabled={disabled}
          />
        </div>
      ) : null}
      {type === "tel" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {label.replace(/-/g, " ")}{" "}
            {required ? (
              <i style={{ color: "red", margin: "auto 2px" }}>*</i>
            ) : (
              ""
            )}
          </label>
          <input
            type="tel"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            onChange={onChange}
            disabled={disabled}
            required={required}
          />
        </React.Fragment>
      ) : null}
      {type === "date" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!hideLable && (
            <label htmlFor={name} className="capatalize_text">
              {
                label
                //label.replace(/-/g, " ")
              }{" "}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={disabled}
              format="DD/MM/YYYY"
              onChange={(event) => {
                onChange(event, name);
              }}
              showDaysOutsideCurrentMonth

              timezone="system"
              views={['year', 'month', 'day']}
              clearable
              value={value}
              autoFocus={false}
              minDate={minDate ?? null}
              maxDate={maxDate ?? null}
              sx={{
                // "& .MuiOutlinedInput-input": { fontSize:16 },
                "& .MuiOutlinedInput-input": {
                  fontSize: "12px",
                  paddingLeft: "5px",
                  maxHeight: "34px",
                  maxWidth: "130px",
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "12px",
                  paddingLeft: "5px",
                  background: "#fff",
                  maxHeight: "34px",
                  maxWidth: "130px",
                },
              }}
            />
          </LocalizationProvider>
        </div>
      ) : null}
      {type === "normalDate" ? (
        <React.Fragment>
          {!hideLable &&
            <label htmlFor={name} className="capatalize_text">
              {!!label && label.replace(/-/g, " ")}{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          }
          {/* {htmlTags} */}
          {/* <div className="dateDiv" onClick={() => {
            if (disabled == false) {
              alinormalDateRef.current.showPicker();
            }
          }}>

            {!!value ? formatDate(value) : 'dd/mm/yyyy'}
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </div> */}
          <input
            ref={alinormalDateRef}
            // style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}
            type="date"
            className="form-control" //datePickerPosioition
            id={name}
            key={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            // defaultValue={value}
            value={value}
            onChange={onChange}
            min={minDate ?? 0}
            max={maxDate ?? 0}
            disabled={disabled}
          />
        </React.Fragment>
      ) : null}
      {type === "normalDateService" ? (
        <div style={{ position: "relative" }}>
          {!hideLable &&
            <label ref={ref} htmlFor={name} className="capatalize_text">
              {!!label && label.replace(/-/g, " ")}{" "}
              {required ? <i style={{ color: "red", margin: 'auto 2px' }}>*</i> : ""}
            </label>
          }
          {/* {htmlTags} */}
          <div className="dateDiv" onClick={() => {
            if (disabled == false) {
              normalDateRef.current.showPicker();
            }
          }}>

            {!!value ? formatDate(value) : 'dd/mm/yyyy'}
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </div>
          <input
            ref={normalDateRef}
            style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}  // Make input invisible and unclickable
            type="date"
            className="form-control datePickerPosioition"
            id={name}
            key={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            onKeyDown={onKeydown}
            // defaultValue={value}
            value={value}
            onChange={onChange}
            disabled={disabled}
            min={minDate ?? null}
            max={maxDate ?? null}
          />
        </div>
      ) : null}
      {/* {type === "date" ? (
        <React.Fragment>
          <label htmlFor={name} className="capatalize_text">
            {
              label
              //label.replace(/-/g, " ")
            } {required ? <i style={{ color: 'red' }}>*</i> : ''}
          </label>
          <input
            type="date"
            className="form-control"
            id={name}
            aria-describedby={name}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            onChange={onChange}
            disabled={disabled}
            max="2999-12-31"
          />
        </React.Fragment>
      ) : null} */}
      {type === "checkbox" ? (
        <React.Fragment>
          <div className="form-check">
            <input
              ref={ref}
              className="form-check-input"
              type="checkbox"
              value={value}
              id={label}
              name={name}
              placeholder={placeholder}
              checked={checked} // Set checked based on state
              onChange={onChange} // Handle change event
              onClick={onClick}
              disabled={disabled}
              defaultChecked={defaultChecked}
            />
            <label
              className="form-check-label ml-5 mt--5 netaqCheckboxLabel"
              htmlFor={label}
            >
              {htmlTags}
              {!isDash &&
                !isWithURL &&
                !!label &&
                label.replace(/-/g, " ")}{" "}
              {isDash && !isWithURL && !!label && label}
              {!isDash && isWithURL && (
                <>
                  {label}
                  <Link to={url} target="_blank" className="mx-2">
                    {urlLabel}
                  </Link>
                </>
              )}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          </div>
        </React.Fragment>
      ) : null}

      {type === "select" ? (
        <React.Fragment>
          {hideLable != true ? (
            <label htmlFor={label} className="capatalize_text">
              {label.replace(/-/g, " ")}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          ) : (
            <></>
          )}
          <Select
            options={[
              ...(!!listSelect &&
                listSelect.map((a) => ({
                  label: a.text,
                  value: a.value,
                  ...a,
                }))),
            ]}
            placeholder={placeholder}
            name={name}
            isSearchable
            hideSelectedOptions
            isDisabled={disabled}
            defaultValue={defaultValue}
            isMulti={isMulti}
            onChange={onChange}
            isClearable={isClearable}
            maxMenuHeight={175}
            required={required}
            value={value}
            noOptionsMessage={() => translate("web.noOptions", "No Options")}
          />
        </React.Fragment>
      ) : null}
      {type == "htmlEditor" && (
        <>
          {hideLable != true ? (
            <label htmlFor={label} className="capatalize_text">
              {label.replace(/-/g, " ")}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          ) : (
            <></>
          )}

          <Editor
            apiKey="r3rxq69ks9bgn5m2q3ranq0nm9jc9j41x3ucqupecnmrdlqb"
            onInit={(_evt, editor) => (editorRef.current = editor)}
            initialValue={initialValue}
            onEditorChange={(e) => {
              onChange(e);
            }}
            name={name}
            init={{
              height: 500,
              menubar: true,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <style>{`.tox-statusbar__branding{display:none !important}`}</style>
        </>
      )}
      {type === "phone" ? (
        <>
          {!hideLable && (
            <label
              htmlFor={label} className="capatalize_text">
              {!isDash && !isWithURL && !!label && label.replace(/-/g, " ")}{" "}
              {isDash && !isWithURL && !!label && label}
              {required ? (
                <i style={{ color: "red", margin: "auto 2px" }}>*</i>
              ) : (
                ""
              )}
            </label>
          )}
          {htmlTags}
          <PhoneInput
            disabled={disabled}
            country={'ae'}
            countryCodeEditable={false}
            inputStyle={{ direction: 'ltr' }}
            buttonStyle={{ direction: 'ltr' }}
            dropdownClass="PhoneDropDown"
            onChange={onChange}
            value={value}
            autoFormat={true}
            containerClass="w-100"
            inputClass="w-100"
            isValid={(inputNumber, country, countries) => {
              return validatePhoneNumber(inputNumber, setIsPhoneNumberValid, country);

            }}
            enableSearch={true}
            copyNumbersOnly={true}
            inputProps={{ required: required }}
          />
        </>
      ) : null}
    </div>
  );
});
export default NetaqInput;
