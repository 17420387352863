import React, { useEffect, useState } from "react";
import ImagesSlider from "../../ImagesSlider";
import CronometerCounter from "../../CronometerCounter";
import ActionButton from "../../ActionButton";
import { useNavigate } from "react-router";
import axios from "../../../axios/axios";
import NetaqInput from "../../NetaqInput";
import FileUpload from "../../FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../TranslationText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader";
import { formatDate } from "../../../helper/formatDate";
import { convertToCamelCase } from "../../../helper/convertToCamelCase";
import { FetchBankDetailsthunk } from "../../../Redux/LookUp/LookUpAction";
import { Link } from "react-router-dom";
import { CompareDate } from "../../../helper/CompareDate";
import Popup from "reactjs-popup";

// import { useLocation } from 'react-router-dom';

const styles = {
  TimerContainer: {
    width: "80%",
    marginLeft: -20,
    paddingLeft: 30,
  },
  TimerIconContainer: { padding: "20px 0" },
  TimerIcon: { color: "#fff", fontSize: 30 },
};

const AuctionTimer = ({ startDate, endDate , auctionTimer }) => {
  const [label, setLabel] = useState("Starts in");
  const [targetDate, setTargetDate] = useState(new Date());
  const [counterDirection, setCounterDirection] = useState(false);
  const { translate } = useTranslate();
  const tt =auctionTimer;

  useEffect(() => {
    const firstDate =
      startDate?.constructor === Date ? startDate : new Date(startDate);
    const secondDate =
      endDate?.constructor === Date ? endDate : new Date(endDate);

    if (firstDate > new Date()) {
      setLabel("Start in:");
      setTargetDate(firstDate);
      setCounterDirection(false);
    } else if (secondDate > new Date()) {
      setLabel("Ends at:");
      setTargetDate(secondDate);
      setCounterDirection(false);
    } else {
      setLabel("Timed out since:");
      setTargetDate(secondDate);
      setCounterDirection(true);
    }
  }, [startDate, endDate]);

  return (
    <>
      <div
        className="text-center noTextEffect"
        style={styles.TimerContainer}
      >
        <span>{translate(`web.${convertToCamelCase(label)}`, label)}</span>
        <div
          className="text-center noTextEffect"
          style={styles.TimerIconContainer}
        >
          <i className="fa-regular fa-clock" style={styles.TimerIcon}></i>
        </div>
        <CronometerCounter  endDate={targetDate} countUp={counterDirection} />
      </div>
    </>
  );
};

/**
 * Auction Header Component
 * @param {*} slider
 * @param {*} details
 * @returns JSX Component element
 */
export default function AuctionHeader({ slider, details, getPaymentStatus, auctionHorses, bidderNumber, bidderId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { bankDetailsData } = useSelector((state) => state.lookUpSlice);

  const { translate } = useTranslate();
  const dismissAll = () => toast.dismiss();
  const [isBidForOthers, setIsBidForOthers] = useState(false);

  const {
    auctionName: name,
    auctionType: type,
    auctionStartDate: startDate,
    auctionTimer: auctionTimer,
    auctionEndDate: endDate,
    auctionNumberOfHorses: horsesCount,
    auuctionId: auctionID,
    auctionFees,
  } = details;

  // console.log("details", details);
  // const location = useLocation();

  // Accessing the current URL including all queries
  // const currentURL = location.href + location.search;
  const currentURL = window.location.href;

  const accessToken = localStorage.getItem("accessTokenforEAHS");
  const [userProfile, setUserProfile] = useState({
    "Full Name": "",
    Nationality: "",
    Address: "",
    "Phone Number": "",
    "Emirates ID": "",
  });
  const [showAuctionPaymentConfirmation, setAuctionPaymentConfirmation] =
    useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [paymentConfirmation, setPaymentConfirmation] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [enrollButtonText, setEnrollButtonText] = useState(
    translate("web.enrollNow", "Enroll Now")
  );
  const [showEnrollBtn, setShowEnrollBtn] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [uploadBankTransfer, setUploadBankTransfer] = useState(null);

  const handleFilesUploaded = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        checkFileSize = false;
      } else {
        if (e.target.files && e.target.files.length > 0) {
          setUploadBankTransfer({
            file: URL.createObjectURL(e.target.files[0]),
            name: e.target.files[0].name,
          });

          setUploadedFiles(e.target.files[0]);
        }
      }
    }

    if (!checkFileSize) {
      toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
    }
  };

  async function handelEnrollment(pressed = true) {
    try {
      if (accessToken != null && accessToken !== "") {
        const userProfile = await axios.get("/account/profile");
        const formattedData = {
          "Full Name": userProfile.data.result.fullName,
          Nationality: userProfile.data.result.nationalityName,
          Address: userProfile.data.result.address,
          "Phone Number": userProfile.data.result.phone,
          "Emirates ID": userProfile.data.result.idNumber,
        };
        setUserProfile(formattedData);

        const userID = localStorage.getItem("EAHSUserID");
        const response = await axios.get(
          `./bidders/isauctionuserenrolled?userId=${userID}&auctionId=${auctionID}`
        );
        if (response.data.status.responseCode === "100") {
          // console.log("enrollment status is", response);
          setEnrollButtonText(translate("web.enrollNow", "Enroll Now"));
          if (pressed) {
            setAuctionPaymentConfirmation(true);
          }
          getPaymentStatus("PendingPayment");
        } else {
          if (pressed) {
            setAuctionPaymentConfirmation(true);
          }
          getPaymentStatus(
            response.data.result.paymentStatus,
            response.data.result.bidderId,
            response.data.result.bidderNumber
          );
          if (response.data.result.paymentStatus === "PendingPayment") {
            setEnrollButtonText(
              translate("web.completePayment", "Complete Payment")
            );
          }
          if (response.data.result.paymentStatus !== "PendingPayment") {
            // console.log("payment Status??", response.data.result.paymentStatus);
            setShowEnrollBtn(false);
          }
        }
        // console.log(" ", response);
      } else {
        if (pressed) {
          navigate(`/login?redirectURL=/singleauction?id=${auctionID}`);
        }
        getPaymentStatus("");
      }
    } catch (error) {
      // console.log("error in handle enrolment", error);
    }
  }

  async function closepaymentconfirmation() {
    setAuctionPaymentConfirmation(false);
  }

  const handleCheckboxChange = (e) => {
    setAcceptTerms(e.target.checked);
  };

  async function confirmAndPayNow() {
    setPaymentConfirmation(true);
    setAuctionPaymentConfirmation(false);
  }
  const handleExternalLink = (url) => {
    window.location.href = url + `&returnUrl=${currentURL}`; // Opens the URL in a new tab
  };

  async function makePayment(paymentType = paymentMethod) {
    const userID = localStorage.getItem("EAHSUserID");
    setLoader(true);
    try {
      switch (paymentType) {
        case "onlinepayment":
          const payment = await axios.post(
            "/bidders/enrollbidder",
            {
              UserId: userID,
              EnrolledAuctionId: auctionID,
              DepositPayment: "EnrollWithPayment",
              PaymentStatus: "Paid",
              PaymentMethod: "OnlinePayment",
              BidderFiles: "",
              IsBidForOthers: isBidForOthers
            },
            {
              headers: {
                "content-type": "multipart/form-data",
              },
            }
          );
          const paymentURL = payment.data.result.url;

          setLoader(false);
          if (payment.data.status.responseCode === "200") {
            handleExternalLink(paymentURL);
            // console.log("payment is ", payment.data);
          } else if (payment.data.status.responseCode === "100") {
            toast.error(translate(`web.${payment.data?.result}`, payment.data?.result));
          }

          //TODO: Complete Payment flow
          /**
           * setPaymentConfirmation(false)
           *  */

          break;
        case "banktransfer":
          const bankpayment = await axios.post(
            "/bidders/enrollbidder",
            {
              UserId: userID,
              EnrolledAuctionId: auctionID,
              DepositPayment: "EnrollWithPayment",
              PaymentStatus: "Paid",
              PaymentMethod: "BankTransfer",
              BidderFiles: uploadedFiles,
              IsBidForOthers: isBidForOthers
              //BidderFiles: uploadedFiles[0],
            },
            {
              headers: {
                "content-type": "multipart/form-data",
              },
            }
          );
          setLoader(false);
          if (bankpayment.data.status.responseCode === "200") {
            // console.log("bankpayment is ", bankpayment.data);
            toast.success(
              translate(
                "web.bankTransfer",
                "it may take up to 3 working days to completed "
              )
            );
            setPaymentConfirmation(false);
          }
          break;
        case "cash":
          const cashpayment = await axios.post(
            "/bidders/enrollbidder",
            {
              UserId: userID,
              EnrolledAuctionId: auctionID,
              DepositPayment: "EnrollWithPayment",
              PaymentStatus: "Paid",
              PaymentMethod: "Cash",
              IsBidForOthers: isBidForOthers,
              BidderFiles: "",
            },
            {
              headers: {
                "content-type": "multipart/form-data",
              },
            }
          );
          setLoader(false);
          if (cashpayment.data.status.responseCode === "200") {
            toast.success(
              translate(
                "web.cashEnrollMsg",
                "Please Proceed to complete the payment at EAHS office "
              )
            );
            setPaymentConfirmation(false);
          }
          break;

        default:
          toast.error(
            translate(
              "web.pleaseSelectPaymentMethod",
              "Please select payment method"
            )
          );
          break;
      }
    } catch (error) {
      setLoader(false);
      toast.error(translate('web.errorInMakePaymentPleaseContactAdmin', 'error in make payment Please Contact Admin'))
    }
  }

  // check the auction end date 3 days
  const threeDaysAgo = new Date(endDate);
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
  const currentDate = new Date();

  // endDate > now + 3

  // Compare the dates
  const isBeforeThreeDays = currentDate < threeDaysAgo;

  useEffect(() => {
    handelEnrollment(false);
  }, [auctionID]);

  useEffect(() => {
    dispatch(FetchBankDetailsthunk());
  }, []);

  const isBetweenDates = false;
  // if we want to show this live URL in this page just uncomment this code
  // const isBetweenDates = CompareDate(
  //   details.auctionStartDate,
  //   details.auctionEndDate
  // );

  // find Horse in Arena
  const inArenaHorse = auctionHorses?.filter((horse) => horse.isHorseInArena) ?? [];
  


  return (
    <>
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      <div className="row rtl AuctionHeaderRow">

        <div className="col-lg-8 col-md-8 col-sm-12 zindex200 p0">
          <ImagesSlider
            data={slider}
            sliderName={"auctionSlider"}
            type={"auctionSlider"}
            VideoURL={
              isBetweenDates && details.auctionType !== "Online"
                ? [details.liveUrl, details.introVideoUrl]
                : [details.introVideoUrl]
            }
          />
        </div>


        <div className="col-lg-4 col-md-4 col-sm-12 AuctionHeaderDetails ">
          <h3 className="mt-2 ">{name}</h3>
          {/* <p className="AuctionHeaderDetails_Type ">
            {translate(`web.${type.toLowerCase()}`, type)}
          </p> */}
          <br />
          <br />
          <div className="AuctionHeaderDate  right-to-left rtl-padding">
            <i className="fa fa-calendar goldenicon" aria-hidden="true"></i>
            <span className="ml-15">
              {translate("web.startDate", "Start Date")}:{" "}
              {formatDate(startDate)}
            </span>
            <span className="ml-25">
              {new Date(startDate).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
          <br />
          <div className="AuctionHeaderDate  right-to-left rtl-padding">
            <i className="fa fa-calendar goldenicon" aria-hidden="true"></i>
            <span className="ml-15">
              {translate("web.endDate", "End Date")}: {formatDate(endDate)}
            </span>
            <span className="ml-25">
              {new Date(endDate).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
          <br />
          <div className="AuctionHeaderNOH  right-to-left rtl-padding">
            <span>
              {translate("web.numberOfHourses", "Number of Hourses")}:{" "}
              <span className="web-numberOfHourses">{horsesCount}</span>
            </span>
            {showEnrollBtn && details.isEnrollOpened == true && new Date(endDate) > new Date() && (
              <ActionButton
                type={"enrollNow"}
                onClick={handelEnrollment}
                width={150}
                text={enrollButtonText}
              />
            )}
            {!!details.eventId &&
              <ActionButton
                type={"internalLink"}
                to={`/book-table?eventid=${details.eventId}`}
                width={150}
                margin={10}
                text={translate('web.bookTable', 'Book Table')}
              />
            }
            {inArenaHorse.length > 0 && false &&
              <ActionButton
                type={"startbiding"}
                text={translate("web.watchNow", "Watch Now")}
                margin={"5px"}
                width={150}
                to={
                  inArenaHorse[0].auctionHorseId +
                  "&" +
                  "auctionID=" +
                  details.auuctionId +
                  "&" +
                  "HorseId=" +
                  inArenaHorse[0].horseId +
                  "&" +
                  "bidderId=" +
                  bidderId +
                  "&" +
                  "BidderNumber=" +
                  bidderNumber +
                  "&" +
                  "VideoURL=" +
                  details.introVideoUrl
                }
              />
            }
            {CompareDate(startDate, endDate) && details.auctionType.toLowerCase() === "hybrid" &&
              <ActionButton
                type={"startbidinghybrid"}
                text={translate("web.watchNow", "Watch Now")}
                margin={"5px"}
                width={150}
                to={"/hybridBidding?auctionID=" + details.auuctionId}
              />
            }
          </div>
          {new Date(endDate) > new Date() && (
            <AuctionTimer auctionTimer={auctionTimer} startDate={startDate} endDate={endDate} />
          )}
        </div>
        {/* {currentLanguage === "ar" && (
          <div className="col-lg-8 col-md-8 col-sm-12 p0 zindex200">
            <ImagesSlider
              data={slider}
              sliderName={"auctionSlider"}
              type={"auctionSlider"}
              VideoURL={
                isBetweenDates && details.auctionType !== "Online"
                  ? [details.liveUrl, details.introVideoUrl]
                  : [details.introVideoUrl]
              }
            />
          </div>
        )} */}
        {showAuctionPaymentConfirmation && (
          <div className="showAuctionPaymentConfirmation">
            <div className="container">
              <div className="row showAuctionPaymentConfirmationRow">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="text-center">
                    <span className="showAuctionPaymentConfirmationspan">
                      {translate("web.enrollment", "Enrollment")}
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="showAuctionPaymentConfirmationspan color-eahsred">
                      {translate("web.auctionFees", "Auction Fees")}:{" "}
                      {auctionFees} {translate("web.aed", "AED")}
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />

                {Object.entries(userProfile).map(([key, value]) => (
                  <div className="row rtl">
                    <div className="col-lg-6 col-md-6 col-sm-6 mt-1 mb-1 text-center auctionPaymentuserKeyCol ">
                      <span className="auctionPaymentuserkeyspan">
                        {translate(`web.${convertToCamelCase(key)}`, key)}
                      </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mt-1 mb-1 text-center auctionPaymentuserValCol ">
                      <span className="auctionPaymentuserValspan">{value}</span>
                    </div>
                    <br />
                    <br />
                  </div>
                ))}
                <div className="row rtl">
                  <div className="form-check-inline  rtl ">
                    <label
                      className="form-check-label"
                      htmlFor="isBidForOthers"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="isBidForOthers"
                        name="isBidForOther"
                        value="accepted"
                        checked={isBidForOthers}
                        onChange={(e) => setIsBidForOthers(e.target.checked)}
                      />
                      <div key={'iiFileItem'} className="iAgreeToTheTermsAndConditionsOfTheAuction" style={{ minWidth: 120, display: 'inline-block' }}>
                        {translate(
                          "web.isBidForOthers",
                          "Is Bid For Others"
                        )}
                      </div>
                    </label>
                  </div>
                  <div className="form-check-inline  rtl ">
                    <label
                      className="form-check-label"
                      htmlFor="accepttermsandconditions"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="accepttermsandconditions"
                        name="qazwsx"
                        value="accepted"
                        checked={acceptTerms}
                        onChange={handleCheckboxChange}
                      />

                      <Popup
                        modal
                        trigger={
                          <div key={'iFileItem'} className="iAgreeToTheTermsAndConditionsOfTheAuction" style={{ minWidth: 120, display: 'inline-block' }}>
                            {translate(
                              "web.iAgreeToTheTermsAndConditionsOfTheAuction",
                              "I agree to the terms and conditions of the auction"
                            )}
                          </div>
                        }
                        closeOnDocumentClick={true}
                        closeOnEscape
                        className="popupUnsetmaxWidth"
                      >
                        {(close) => (
                          <React.Fragment>
                            <div
                              className="text-start"
                              style={{ maxHeight: '60vh', overflowY: 'scroll' }}
                              dangerouslySetInnerHTML={{ __html: currentLanguage == 'en' ? details.termsAndConditionsTextEn : details.termsAndConditionsTextAr }}
                            />
                            <br />
                            <div
                              className="rtl"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                              }}
                            >
                              <ActionButton type={'btn'} label={translate("web.close", "close")} onClick={close} text={translate("web.close", "close")} />
                            </div>
                          </React.Fragment>
                        )}
                      </Popup>
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 text-center pt-10 ">
                    <ActionButton
                      margin={0}
                      onClick={closepaymentconfirmation}
                      label={translate("web.cancel", "Cancel")}
                      type={"loadmore"}
                      key={"cancel"}
                    />
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 text-center position-relative pt-10 "
                    style={{ opacity: acceptTerms ? 1 : 0.5 }}
                  >
                    {!acceptTerms && <div className="acceptTerms"></div>}
                    {loader ? (
                      <Loader />
                    ) : (
                      <ActionButton
                        margin={0}
                        text={translate(
                          "web.confirmAndPayNow",
                          "Confirm & Pay Now"
                        )}
                        // onClick={() => confirmAndPayNow()}
                        onClick={() => makePayment('onlinepayment')}
                        type={"confirmandpay"}
                        key={"Payment"}
                      />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {paymentConfirmation && (
          <div className="paymentConfirmation ">
            <div className="container">
              <div className="row showAuctionPaymentConfirmationRow rtl">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="text-center ">
                    <span className="showAuctionPaymentConfirmationspan">
                      {translate(
                        "web.youNeedtoPayAuctionFees",
                        "You Need to Pay Auction Fees"
                      )}
                    </span>
                  </div>
                  <div className="text-center ">
                    <span className="showAuctionPaymentConfirmationspan color-eahsred">
                      {auctionFees} {translate("web.aed", "AED")}
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div className="row rtl">
                  <NetaqInput
                    checked={paymentMethod === "onlinepayment"}
                    onChange={() => setPaymentMethod("onlinepayment")}
                    type={"radio"}
                    name={"paymentmethod"}
                    key={"OnlinePayment"}
                    value={"onlinepayment"}
                    label={translate("web.onlinePayment", "online payment")}
                  />
                  {isBeforeThreeDays && (
                    <>
                      <NetaqInput
                        checked={paymentMethod === "banktransfer"}
                        onChange={() => setPaymentMethod("banktransfer")}
                        type={"radio"}
                        name={"paymentmethod"}
                        key={"BankTransfer"}
                        value={"banktransfer"}
                        label={translate("web.bankTransfer", "Bank Transfer")}
                      />
                      {paymentMethod === "banktransfer" && (
                        <div className="studBookHorseCompetitionDataContainer">
                          <div className="col-md-9 col-sm-12">
                            <div className="col-md-12 col-sm-12">
                              <span className="showAuctionPaymentConfirmationspan C89C66 display-inline">
                                {translate("web.name", "Name")} :{" "}
                              </span>
                              <span className="showAuctionPaymentConfirmationspan display-inline">
                                {bankDetailsData.name}
                              </span>
                            </div>
                            <div className="col-md-12 col-sm-12">
                              <span className="showAuctionPaymentConfirmationspan C89C66 display-inline">
                                {translate("web.bankName", "Bank Name")} :{" "}
                              </span>
                              <span className="showAuctionPaymentConfirmationspan display-inline">
                                {bankDetailsData.bankName}
                              </span>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <span className="showAuctionPaymentConfirmationspan C89C66 display-inline">
                                {translate("web.accountNo", "Account No")} :{" "}
                              </span>
                              <span className="showAuctionPaymentConfirmationspan display-inline">
                                {bankDetailsData.accountNo}
                              </span>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <span className="showAuctionPaymentConfirmationspan C89C66 display-inline">
                                {translate("web.iban", "IBan")} :{" "}
                              </span>
                              <span className="showAuctionPaymentConfirmationspan display-inline">
                                {bankDetailsData.ban}
                              </span>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <span className="showAuctionPaymentConfirmationspan C89C66 display-inline">
                                {translate("web.swiftCode", "Swift Code")} :{" "}
                              </span>
                              <span className="showAuctionPaymentConfirmationspan display-inline">
                                {bankDetailsData.swift}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <span className="showAuctionPaymentConfirmationspan  C89C66">
                              {translate(
                                "web.bankTransferReceipt",
                                "Bank Transfer Receipt"
                              )}
                            </span>
                            <span className="col-lg-2 col-md-2 col-sm-12 text-center label upload-label width-auto mt-20">
                              {translate("web.upload", "Upload")}
                              <input
                                className="upload-input"
                                type="file"
                                onChange={handleFilesUploaded}
                                placeholder={translate("web.upload", "Upload")}
                              />
                            </span>

                            {!!uploadBankTransfer && (
                              <Link
                                to={uploadBankTransfer.file}
                                className="C89C66 flex-center cursorPointer mt-20"
                                target="_blank"
                              >
                                <i className="fa fa-file fa-3x"></i>
                                <span className="black-color fontsize18 marginrightleft-10">
                                  {uploadBankTransfer.name}
                                </span>
                              </Link>
                            )}

                            {
                              // <FileUpload
                              //   multiple={false}
                              //   key={"Bank Transfer"}
                              //   onFilesUploaded={handleFilesUploaded}
                              // />
                            }
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <NetaqInput
                    checked={paymentMethod === "cash"}
                    onChange={() => setPaymentMethod("cash")}
                    type={"radio"}
                    name={"paymentmethod"}
                    key={"Cash"}
                    value={"cash"}
                    label={translate("web.cash", "Cash")}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 text-center pt-10 ">
                  <ActionButton
                    margin={0}
                    onClick={() => setPaymentConfirmation(false)}
                    label={translate("web.cancel", "cancel")}
                    type={"loadmore"}
                    key={"Cancel"}
                  />
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 text-center position-relative pt-10 "
                  style={{ opacity: paymentMethod !== "" ? 1 : 0.5 }}
                >
                  {paymentMethod === "" && <div className="acceptTerms"></div>}
                  {loader ? (
                    <Loader />
                  ) : (
                    <ActionButton
                      margin={0}
                      onClick={makePayment}
                      text={translate("web.pay", "Pay")}
                      type={"confirmandpay"}
                      key={"Payment"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export { AuctionTimer };
