import axios from "../../axios/axios";

export const FetchCreateOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("employeeId", param.employeeId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("requestDetails.horseId", param.requestDetails.horseId);
    formData.append("requestDetails.ownerId", param.requestDetails.ownerId);
    formData.append(
      "requestDetails.newOwnerId",
      param.requestDetails.newOwnerId
    );
    formData.append(
      "requestDetails.attachmentFile",
      param.requestDetails.attachmentFile
    );
    formData.append(
      "requestDetails.documentTypeId",
      param.requestDetails.documentTypeId
    );
    formData.append("requestDetails.createdBy", param.requestDetails.createdBy);
    formData.append("requestDetails.effectiveDate", param.requestDetails.effectiveDate);
    formData.append("requestDetails.isCollectSemen", param.requestDetails.isCollectSemen);
    formData.append("requestDetails.numberOfDoses", param.requestDetails.numberOfDoses);

    const response = await axios.post(
      `/ServicesRequests/CreateOwnerShipTransferServicesRequests`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAllServicesRequestsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetAllServicesRequests?language=${param.language}&userId=${param.userId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetServicesRequestByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetServicesRequestById?id=${param.id}&language=${param.language}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchDeleteOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/DeleteOwnerShipTransferServicesRequests?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAllServicesRequestsOwnerToApproveAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetAllServicesRequestsOwnerToApprove?language=${param.language}&userId=${param.userId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateOwnerShipTransferServicesRequestsAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("comment", param.comment);
    formData.append("employeeId", param.employeeId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("requestDetails.horseId", param.requestDetails.horseId);
    formData.append("requestDetails.ownerId", param.requestDetails.ownerId);
    formData.append(
      "requestDetails.newOwnerId",
      param.requestDetails.newOwnerId
    );
    formData.append(
      "requestDetails.attachmentFile",
      param.requestDetails.attachmentFile
    );
    formData.append(
      "requestDetails.documentTypeId",
      param.requestDetails.documentTypeId
    );
    formData.append("requestDetails.effectiveDate", param.requestDetails.effectiveDate);
    formData.append("requestDetails.address", param.requestDetails.address);
    formData.append("requestDetails.mobile", param.requestDetails.mobile);
    formData.append("requestDetails.receiveType", param.requestDetails.receiveType);
    formData.append("requestDetails.isCollectSemen", param.requestDetails.isCollectSemen);
    formData.append("requestDetails.numberOfDoses", param.requestDetails.numberOfDoses);

    const response = await axios.post(
      `/ServicesRequests/UpdateOwnerShipTransferServicesRequests`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchApproveRejectRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/ServicesRequests/ApproveRejectRequest`, {
      id: param.id,
      employeeId: param.employeeId,
      status: param.status,
      comment: param.comment,
      address: param.address,
      mobile: param.mobile,
      receiveType: param.receiveType,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetServiceDefinitionByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetServiceDefinitionById?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetFileAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetFile?filePath=${param.filePath}&fileType=${param.Type}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetFilebase64API = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetFilebase64?filePath=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetDocumentTypesByServiceIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ServicesRequests/GetDocumentTypesByServiceId?id=${param.id}&lang=${param.language}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetRegisteredHorsesAPI = async (param) => {
  try {
    let response = await axios.post(`/ServicesRequests/GetRegisteredHorses`, {
      searchValue: param.searchValue,
      lang: param.lang,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//covering certificate
export const FetchCreateCoveringCertificateServiceRequestAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("coveringCertificateId", param.coveringCertificateId);
    formData.append("status", param.status);
    formData.append("isViolated", param.isViolated);

    formData.append("isStallionRegistered", param.isStallionRegistered);
    formData.append("notStallionOwner", param.notStallionOwner);
    formData.append("horseStallion", param.horseStallion);
    formData.append("horseNameEnStallionUnReg", param.horseNameEnStallionUnReg);
    formData.append("horseNameArStallionUnReg", param.horseNameArStallionUnReg);
    formData.append("damEnStallionUnReg", param.damEnStallionUnReg);
    formData.append("damArStallionUnReg", param.damArStallionUnReg);
    formData.append("sireEnStallionUnReg", param.sireEnStallionUnReg);
    formData.append("sireArStallionUnReg", param.sireArStallionUnReg);
    formData.append("ownerNameEnStallionUnReg", param.ownerNameEnStallionUnReg);
    formData.append("ownerNameArStallionUnReg", param.ownerNameArStallionUnReg);
    formData.append("breederNameEnStallionUnReg", param.breederNameEnStallionUnReg);
    formData.append("breederNameArStallionUnReg", param.breederNameArStallionUnReg);
    formData.append("horseAddressStallionUnReg", param.horseAddressStallionUnReg);
    formData.append("currentRegNoStallionUnReg", param.currentRegNoStallionUnReg);
    formData.append("uelnStallionUnReg", param.uelnStallionUnReg);
    formData.append("colorStallionUnReg", param.colorStallionUnReg);
    formData.append("originStallionUnReg", param.originStallionUnReg);
    formData.append("horseCountryStallionUnReg", param.horseCountryStallionUnReg);
    formData.append("birthDateStallionUnRegString", param.birthDateStallionUnReg);
    formData.append("isMareRegistered", param.isMareRegistered);
    formData.append("horseMareReg", param.horseMareReg);
    formData.append("horseNameEnMareUnReg", param.horseNameEnMareUnReg);
    formData.append("horseNameArMareUnReg", param.horseNameArMareUnReg);
    formData.append("damEnMareUnReg", param.damEnMareUnReg);
    formData.append("damArMareUnReg", param.damArMareUnReg);
    formData.append("sireEnMareUnReg", param.sireEnMareUnReg);
    formData.append("sireArMareUnReg", param.sireArMareUnReg);
    formData.append("breederNameEnMareUnReg", param.breederNameEnMareUnReg);
    formData.append("breederNameArMareUnReg", param.breederNameArMareUnReg);
    formData.append("horseAddressMareUnReg", param.horseAddressMareUnReg);
    formData.append("currentRegNoMareUnReg", param.currentRegNoMareUnReg);
    formData.append("uelnMareUnReg", param.uelnMareUnReg);
    formData.append("colorMareUnReg", param.colorMareUnReg);
    formData.append("originMareUnReg", param.originMareUnReg);
    formData.append("horseCountryMareUnReg", param.horseCountryMareUnReg);
    formData.append("birthDateMareUnRegString", param.birthDateMareUnReg);
    formData.append("typeOfCovering", param.typeOfCovering);
    formData.append("coveringDateString", param.coveringDate);
    formData.append("breedingHappendAbroad", param.breedingHappendAbroad);
    formData.append("embryoIsImported", param.embryoIsImported);
    formData.append("stallionOwnerSignedPermit", param.stallionOwnerSignedPermit);
    formData.append("breedingHappendAbroadAttachmentFile", param.breedingHappendAbroadAttachmentFile);
    formData.append("embryoIsImportedAttachmentFile", param.embryoIsImportedAttachmentFile);
    formData.append("stallionOwnerSignedPermitAttachmentFile", param.stallionOwnerSignedPermitAttachmentFile);
    formData.append("importedSemenCertificateAttachmentFile", param.importedSemenCertificateAttachmentFile);
    formData.append("ownerIdNotStallionReg", param.ownerIdNotStallionReg);
    formData.append("referenceNumberSemenCollectionCertificate", param.referenceNumberSemenCollectionCertificate);

    formData.append("inseminationImportedType", param.inseminationImportedType);
    formData.append("importedReferenceNumberSemen", param.importedReferenceNumberSemen);
    formData.append("embryoTransfer", param.embryoTransfer);
    formData.append("recipientMare", param.recipientMare);
    formData.append("recipientMareId", param.recipientMareId);
    formData.append("recipientMareNameEnNotRegistered", param.recipientMareNameEnNotRegistered);
    formData.append("recipientMareNameArNotRegistered", param.recipientMareNameArNotRegistered);
    formData.append("microchipNo", param.microchipNo);
    formData.append("transferDateString", param.transferDateString);
    formData.append("veterinarian", param.veterinarian);
    formData.append("transferAddress", param.transferAddress);
    formData.append("veterinarianReportAttachmentFile", param.veterinarianReportAttachmentFile);

    formData.append("ownerId", param.ownerId);

    const response = await axios.post(
      `/CoveringCertificateServiceRequest/CreateOrEdit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateCoveringCertificateServiceRequestAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("date", param.date);
    formData.append("status", param.status);
    formData.append("isViolated", param.isViolated);
    formData.append("coveringCertificateId", param.coveringCertificateId);

    formData.append("isStallionRegistered", param.isStallionRegistered);
    formData.append("notStallionOwner", param.notStallionOwner);
    formData.append("horseStallion", param.horseStallion);
    formData.append("horseNameEnStallionUnReg", param.horseNameEnStallionUnReg);
    formData.append("horseNameArStallionUnReg", param.horseNameArStallionUnReg);
    formData.append("damEnStallionUnReg", param.damEnStallionUnReg);
    formData.append("damArStallionUnReg", param.damArStallionUnReg);
    formData.append("sireEnStallionUnReg", param.sireEnStallionUnReg);
    formData.append("sireArStallionUnReg", param.sireArStallionUnReg);
    formData.append("ownerNameEnStallionUnReg", param.ownerNameEnStallionUnReg);
    formData.append("ownerNameArStallionUnReg", param.ownerNameArStallionUnReg);
    formData.append("breederNameEnStallionUnReg", param.breederNameEnStallionUnReg);
    formData.append("breederNameArStallionUnReg", param.breederNameArStallionUnReg);
    formData.append("horseAddressStallionUnReg", param.horseAddressStallionUnReg);
    formData.append("currentRegNoStallionUnReg", param.currentRegNoStallionUnReg);
    formData.append("uelnStallionUnReg", param.uelnStallionUnReg);
    formData.append("colorStallionUnReg", param.colorStallionUnReg);
    formData.append("originStallionUnReg", param.originStallionUnReg);
    formData.append("horseCountryStallionUnReg", param.horseCountryStallionUnReg);
    formData.append("birthDateStallionUnRegString", param.birthDateStallionUnReg);
    formData.append("isMareRegistered", param.isMareRegistered);
    formData.append("horseMareReg", param.horseMareReg);
    formData.append("horseNameEnMareUnReg", param.horseNameEnMareUnReg);
    formData.append("horseNameArMareUnReg", param.horseNameArMareUnReg);
    formData.append("damEnMareUnReg", param.damEnMareUnReg);
    formData.append("damArMareUnReg", param.damArMareUnReg);
    formData.append("sireEnMareUnReg", param.sireEnMareUnReg);
    formData.append("sireArMareUnReg", param.sireArMareUnReg);
    formData.append("breederNameEnMareUnReg", param.breederNameEnMareUnReg);
    formData.append("breederNameArMareUnReg", param.breederNameArMareUnReg);
    formData.append("horseAddressMareUnReg", param.horseAddressMareUnReg);
    formData.append("currentRegNoMareUnReg", param.currentRegNoMareUnReg);
    formData.append("uelnMareUnReg", param.uelnMareUnReg);
    formData.append("colorMareUnReg", param.colorMareUnReg);
    formData.append("originMareUnReg", param.originMareUnReg);
    formData.append("horseCountryMareUnReg", param.horseCountryMareUnReg);
    formData.append("birthDateMareUnRegString", param.birthDateMareUnReg);
    formData.append("typeOfCovering", param.typeOfCovering);
    formData.append("coveringDateString", param.coveringDate);
    formData.append("breedingHappendAbroad", param.breedingHappendAbroad);
    formData.append("embryoIsImported", param.embryoIsImported);
    formData.append("stallionOwnerSignedPermit", param.stallionOwnerSignedPermit);
    formData.append("breedingHappendAbroadAttachmentFile", param.breedingHappendAbroadAttachmentFile);
    formData.append("embryoIsImportedAttachmentFile", param.embryoIsImportedAttachmentFile);
    formData.append("stallionOwnerSignedPermitAttachmentFile", param.stallionOwnerSignedPermitAttachmentFile);
    formData.append("importedSemenCertificateAttachmentFile", param.importedSemenCertificateAttachmentFile);
    formData.append("ownerIdNotStallionReg", param.ownerIdNotStallionReg);
    formData.append("referenceNumberSemenCollectionCertificate", param.referenceNumberSemenCollectionCertificate);
    formData.append("ownerId", param.ownerId);
    formData.append("createdBy", param.createdBy);
    formData.append("createdBy", param.eventName);

    const response = await axios.post(
      `/CoveringCertificateServiceRequest/UpdateCoveringCertificateServiceRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchApproveRejectRequestCoveringCertificateAPI = async (param) => {
  try {
    let response = await axios.post(`/CoveringCertificateServiceRequest/ApproveRejectRequestCoveringCertificate`, {
      id: param.id,
      status: param.status,
      comment: param.comment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCancelRequestAPI = async (param) => {
  try {
    let response = await axios.get(`/CoveringCertificateServiceRequest/CancelRequest?serviceId=${param.serviceId}&ownerId=${param.ownerId}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekAPI = async (param) => {
  try {
    let response = await axios.get(`/CoveringCertificateServiceRequest/CheckLastChildrenOfHorseMareRegLessThanOneWeek?horseId=${param.horseId}&coveringDate=${param.coveringDate}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Embryo Oocyte Transfer Permit
export const FetchCreateEmbryoOocyteTransferPermitServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/EmbryoOocyteTransferPermitServiceRequest/CreateEmbryoOocyteTransferPermitServiceRequest`, {
      serviceDefinitionId: param.serviceDefinitionId,
      //date: param.date,
      status: param.status,
      eventName: param.eventName,
      horse: param.horse,
      ownerId: param.ownerId,
      createdBy: param.createdBy,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateEmbryoOocyteTransferPermitServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/EmbryoOocyteTransferPermitServiceRequest/UpdateEmbryoOocyteTransferPermitServiceRequest`, {
      id: param.id,
      serviceDefinitionId: param.serviceDefinitionId,
      //date: param.date,
      status: param.status,
      eventName: param.eventName,
      horse: param.horse,
      ownerId: param.ownerId,
      createdBy: param.createdBy,
      embryoOocyteTransferPermitId: param.embryoOocyteTransferPermitId,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchIsHaseRequestEmbryoOocyteTransferPermitAPI = async (param) => {
  try {
    let response = await axios.get(`/EmbryoOocyteTransferPermitServiceRequest/IsHaseRequestEmbryoOocyteTransferPermit?horseId=${param.horseId}&serviceDefinitionId=${param.serviceDefinitionId}&ownerId=${param.ownerId}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Embryo Transfer Right Purchace Agreement
export const FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestAPI = async (param) => {
  try {

    let response = await axios.post(`/EmbryoTransferRightPurchaceAgreementRequest/CreateOrEdit`, {
      id: param.id,
      status: param.status,
      eventName: param.eventName,

      ownerId: param.ownerId,
      buyerId: param.buyerId,
      coveringCertificateId: param.coveringCertificateId,
      agreementDateString: param.agreementDate,

      newBreeder: param.newBreeder,
      buyerComment: param.buyerComment,

      //serviceDefinitionId: param.serviceDefinitionId,
      //createdBy: param.createdBy,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/EmbryoTransferRightPurchaceAgreementRequest/UpdateEmbryoTransferRightPurchaceAgreementServiceRequest`, {
      id: param.id,
      serviceDefinitionId: param.serviceDefinitionId,
      status: param.status,
      eventName: param.eventName,
      buyerComment: param.buyerComment,

      ownerId: param.ownerId,
      buyerId: param.buyerId,
      coveringCertificateId: param.coveringCertificateId,
      agreementDateString: param.agreementDate,
      createdBy: param.createdBy,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementAPI = async (param) => {
  try {
    let response = await axios.post(`/EmbryoTransferRightPurchaceAgreementRequest/ApproveRejectRequestEmbryoTransferRightPurchaceAgreement`, {
      id: param.id,
      ownerId: param.ownerId,
      status: param.status,
      buyerComment: param.buyerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchOwnerCoveringCertificatesDropDownAPI = async (param) => {
  try {
    let response = await axios.get(`/EmbryoTransferRightPurchaceAgreementRequest/OwnerCoveringCertificatesDropDown?ownerId=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//registering New Arabian Horse Foal
export const FetchCreateRegisteringNewArabianHorseFoalServiceRequestAPI = async (
  param
) => {
  try {

    let response = await axios.post(`/RegisteringNewArabianHorseFoalServiceRequest/CreateOrEdit`, {
      id: param.id,
      coveringCertificateId: param.coveringCertificateId,
      status: param.status,
      eventName: param.eventName,
      ownerId: param.ownerId,

      horseNameEn: param.horseNameEn,
      horseNameAr: param.horseNameAr,
      birthDateString: param.birthDateString,
      color: param.color,
      sex: param.sex,
      emirate: param.emirate,
      area: param.area,
      latitude: param.latitude,
      longitude: param.longitude,

      receiveType: param.receiveType,
      address: param.address,
      mobile: param.mobile,
      ownerNote: param.ownerNote,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateRegisteringNewArabianHorseFoalServiceRequestAPI = async (
  param
) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);


    formData.append("status", param.status);
    formData.append("eventName", param.eventName);
    formData.append("ownerId", param.ownerId);
    formData.append("coveringCertificateId", param.coveringCertificateId);
    formData.append("horseNameEn", param.horseNameEn);
    formData.append("horseNameAr", param.horseNameAr);
    formData.append("birthDateString", param.birthDateString);
    formData.append("color", param.color);
    formData.append("sex", param.sex);
    formData.append("emirate", param.emirate);
    formData.append("area", param.area);
    formData.append("latitude", param.latitude);
    formData.append("longitude", param.longitude);
    formData.append("receiveType", param.receiveType);
    formData.append("address", param.address);
    formData.append("mobile", param.mobile);
    formData.append("ownerNote", param.ownerNote);

    // formData.append("locationOnMap", param.locationOnMap);
    formData.append("registeringNewArabianHorseFoalId", param.registeringNewArabianHorseFoalId);
    formData.append("serviceDefinitionId", param.serviceDefinitionId);
    formData.append("createdBy", param.createdBy);
    formData.append("embryoTransfer", param.embryoTransfer);
    formData.append("recipientMare", param.recipientMare);
    formData.append("recipientMareId", param.recipientMareId);
    formData.append("recipientMareNameEnNotRegistered", param.recipientMareNameEnNotRegistered);
    formData.append("recipientMareNameArNotRegistered", param.recipientMareNameArNotRegistered);
    formData.append("microchipNo", param.microchipNo);
    formData.append("transferDateString", param.transferDateString);
    formData.append("veterinarian", param.veterinarian);
    formData.append("transferAddress", param.transferAddress);
    formData.append("veterinarianReportAttachmentFile", param.veterinarianReportAttachmentFile);

    const response = await axios.post(
      `/RegisteringNewArabianHorseFoalServiceRequest/UpdateRegisteringNewArabianHorseFoalServiceRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownAPI = async (param) => {
  try {
    let response = await axios.get(`/RegisteringNewArabianHorseFoalServiceRequest/OwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDown?ownerId=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//HorseOwnershipCertificates
export const FetchCreateOrEditHorseOwnershipCertificatesServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/HorseOwnershipCertificates/CreateOrEdit`, {
      id: param.id,
      ownerId: param.ownerId,
      certificateOption: param.certificateOption,
      status: param.status,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Stud Registration Certificates
export const FetchCreateOrEditStudRegistrationCertificatesServiceRequestAPI = async (param) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("studRegistrationId", param.studRegistrationId);
    formData.append("studName", param.studName);
    formData.append("studNameAr", param.studNameAr);
    formData.append("ownerId", param.ownerId);
    formData.append("regionId", param.regionId);
    formData.append("emirateId", param.emirateId);
    formData.append("studAddress", param.studAddress);
    formData.append("studPhone", param.studPhone);
    formData.append("isShowCategory", param.isShowCategory);
    formData.append("isRaceCategory", param.isRaceCategory);
    formData.append("isEnduranceCategory", param.isEnduranceCategory);
    formData.append("isOtherCategory", param.isOtherCategory);
    formData.append("gpsLocationLink", param.gpsLocationLink);
    formData.append("latitude", param.latitude);
    formData.append("longitude", param.longitude);
    formData.append("status", param.status);
    formData.append("ownerComment", param.ownerComment);
    formData.append("landOwnershipAttachmentFile", param.landOwnershipAttachmentFile);

    const response = await axios.post(
      `/StudRegistrationCertificates/CreateOrEdit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;

  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetLoacationNameByLatLangAPI = async (param) => {
  try {
    let response = await axios.get(`/StudRegistrationCertificates/GetLoacationNameByLatLang?latVal=${param.latVal}&longVal=${param.longVal}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//updateArabianHorseData
export const FetchCreateOrEditUpdateArabianHorseDataServiceRequestAPI = async (param) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("horse", param.horse);
    formData.append("updateArabianHorseDataId", param.updateArabianHorseDataId);
    formData.append("ownerId", param.ownerId);
    formData.append("fromColor", param.fromColor);
    formData.append("toColor", param.toColor);
    formData.append("changeSex", param.changeSex);
    formData.append("fromSex", param.fromSex);
    formData.append("toSex", param.toSex);
    formData.append("veterinarianReportAttachmentFile", param.veterinarianReportAttachmentFile);
    formData.append("castredDate", param.castredDate);
    formData.append("status", param.status);
    formData.append("ownerComment", param.ownerComment);

    const response = await axios.post(
      `/UpdateArabianHorseData/CreateOrEdit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;

  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Submit Journalist Request
export const FetchSubmitJournalistRequestAPI = async (param) => {
  try {
    const formData = new FormData();
    formData.append("showId", param.showId);
    formData.append("name", param.name);
    formData.append("email", param.email);
    formData.append("mobileNo", param.mobileNo);
    formData.append("gender", param.gender);
    formData.append("idOrPassportCopy", param.idOrPassportCopy);
    formData.append("organizerType", param.organizerType);
    formData.append("organizationName", param.organizationName);
    formData.append("websiteOrSocialMediaLinks", param.websiteOrSocialMediaLinks);
    formData.append("isPolicyAccepted", param.isPolicyAccepted);

    const response = await axios.post(
      `/Contact/SubmitJournalistRequest`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;

  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Get Show Journalist Request Terms Data
export const FetchGetShowJournalistRequestTermsDataAPI = async (param) => {
  try {
    let response = await axios.get(`/Contact/GetShowJournalistRequestTermsData?ShowId=${param?.showId}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Declaration Of Arabian Horse Death
export const FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/DeclarationOfArabianHorseDeath/CreateOrEdit`, {
      id: param.id,
      ownerId: param.ownerId,
      horse: param.horse,
      deathDate: param.deathDate,
      status: param.status,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Inactive Horse Registration Request
export const FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/InactiveHorseRegistrationRequest/CreateOrEdit`, {
      id: param.id,
      ownerId: param.ownerId,
      horse: param.horse,
      reasons: param.reasons,
      status: param.status,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//add Racing Name For Imported Arabian Horse
export const FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/AddRacingNameForImportedArabianHorse/CreateOrEdit`, {
      id: param.id,
      ownerId: param.ownerId,
      horse: param.horse,
      racingNameEn: param.racingNameEn,
      racingNameAr: param.racingNameAr,
      status: param.status,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//IssuingHorsePassportDuplicateDamaged
export const FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestAPI = async (param) => {
  try {
    let response = await axios.post(`/IssuingHorsePassportDuplicateDamaged/CreateOrEdit`, {
      id: param.id,
      ownerId: param.ownerId,
      horse: param.horse,
      passportType: param.passportType,
      status: param.status,
      ownerComment: param.ownerComment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//importHorseFrozenSemen
export const FetchCreateOrEditImportHorseFrozenSemenServiceRequestAPI = async (param) => {
  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("importHorseFrozenSemenId", param.importHorseFrozenSemenId);
    formData.append("ownerId", param.ownerId);
    formData.append("stallionName", param.stallionName);
    formData.append("importCountry", param.importCountry);
    formData.append("originCountry", param.originCountry);
    formData.append("horseCategory", param.horseCategory);
    formData.append("ueln", param.ueln);
    formData.append("sire", param.sire);
    formData.append("dam", param.dam);
    formData.append("originStudbookRefNo", param.originStudbookRefNo);
    formData.append("currentStudbookRefNo", param.currentStudbookRefNo);
    formData.append("approvedCollectionCenter", param.approvedCollectionCenter);
    formData.append("noStraws", param.noStraws);
    formData.append("noDoses", param.noDoses);
    formData.append("dispatchNumber", param.dispatchNumber);
    formData.append("uaeHospitalSemenStocking", param.uaeHospitalSemenStocking);
    formData.append("importDate", param.importDate);
    formData.append("shippingAgent", param.shippingAgent);
    formData.append("horsePassportAttachmentFile", param.horsePassportAttachmentFile);
    formData.append("letterSemenStorageCenterAttachmentFile", param.letterSemenStorageCenterAttachmentFile);
    formData.append("residencePermitAttachmentFile", param.residencePermitAttachmentFile);
    formData.append("status", param.status);
    formData.append("ownerComment", param.ownerComment);

    const response = await axios.post(
      `/ImportHorseFrozenSemen/CreateOrEdit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//TemporaryHorseImport
export const FetchCreateOrEditTemporaryHorseImportAPI = async (param) => {

  try {
    const formData = new FormData();
    formData.append("id", param.id);
    formData.append("ownerId", param.ownerId);
    formData.append("status", param.status);
    formData.append("ownerComment", param.ownerComment);
    formData.append("temporaryHorseImportsId", param.temporaryHorseImportsId);
    formData.append("horseImportType", param.horseImportType);
    formData.append("eventName", param.eventName);
    formData.append("importFrom", param.importFrom);
    formData.append("purposeOfImport", param.purposeOfImport);
    formData.append("dateOfImport", param.dateOfImport);
    formData.append("endDateOfImport", param.endDateOfImport);
    formData.append("uAEPlaceOfDestination", param.uAEPlaceOfDestination);
    formData.append("uaeQuarantine", param.uaeQuarantine);
    formData.append("authAgentPhoneNo", param.authAgentPhoneNo);
    formData.append("ownerType", param.ownerType);
    formData.append("shippingAgentId", param.shippingAgentId);
    formData.append("ownerFullName", param.ownerFullName);
    formData.append("ownerEmail", param.ownerEmail);
    formData.append("ownerPhoneNo", param.ownerPhoneNo);
    formData.append("ownerPassportOrEmiratesIdCopyFile", param.ownerPassportOrEmiratesIdCopyFile);

    param.horses.forEach((element, i) => {
      //formData.append(`horseTempImportDetailsDto[${i}].id`, element.id);
      //formData.append(`horseTempImportDetailsDto[${i}].temporaryHorseImportId`, param.temporaryHorseImportId);
      formData.append(`horseTempImportDetailsDto[${i}].horseName`, element.horseName);
      formData.append(`horseTempImportDetailsDto[${i}].horseNameAr`, element.horseNameAr);
      formData.append(`horseTempImportDetailsDto[${i}].registerNumber`, element.regNo);
      formData.append(`horseTempImportDetailsDto[${i}].sire`, element.sire);
      formData.append(`horseTempImportDetailsDto[${i}].dam`, element.dam);
      formData.append(`horseTempImportDetailsDto[${i}].birthDate`, element.dateFoaled);
      formData.append(`horseTempImportDetailsDto[${i}].microchipNo`, element.microchipNo);
      formData.append(`horseTempImportDetailsDto[${i}].sex`, element.sex === "Mare" ? 1 : element.sex === "Stallion" ? 2 : 3);
      formData.append(`horseTempImportDetailsDto[${i}].color`, element.color);
      formData.append(`horseTempImportDetailsDto[${i}].horsePassportCopyFile`, element.horsePassport);
    });

    const response = await axios.post(
      `/TemporaryHorseImport/CreateOrEdit`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//Shipping Agents
export const FetchGetShippingAgentsAPI = async (param) => {
  try {
    let response = await axios.get(`/ImportHorseFrozenSemen/GetShippingAgents`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//ConfirmImportDate
export const FetchConfirmImportDateAPI = async (param) => {
  try {
    let response = await axios.get(`/ImportHorseFrozenSemen/ConfirmImportDate?id=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//AddFollowUp
export const FetchAddFollowUpAPI = async (param) => {
  try {
    let response = await axios.post(`/ServicesRequests/AddFollowUp`, {
      ownerId: param.ownerId,
      serviceId: param.serviceId,
      comment: param.comment,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

//getFollowUpdata
export const FetchGetFollowUpDataAPI = async (param) => {
  try {
    let response = await axios.get(`/ServicesRequests/GetFollowUpData?serviceId=${param.serviceId}&type=${param.type}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};