import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchAddFollowUpAPI,
  FetchApproveRejectRequestAPI,
  FetchApproveRejectRequestCoveringCertificateAPI,
  FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementAPI,
  FetchCancelRequestAPI,
  FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekAPI,
  FetchConfirmImportDateAPI,
  FetchCreateCoveringCertificateServiceRequestAPI,
  FetchCreateEmbryoOocyteTransferPermitServiceRequestAPI,
  FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestAPI,
  FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestAPI,
  FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestAPI,
  FetchCreateOrEditHorseOwnershipCertificatesServiceRequestAPI,
  FetchCreateOrEditImportHorseFrozenSemenServiceRequestAPI,
  FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestAPI,
  FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestAPI,
  FetchCreateOrEditStudRegistrationCertificatesServiceRequestAPI,
  FetchCreateOrEditTemporaryHorseImportAPI,
  FetchCreateOrEditUpdateArabianHorseDataServiceRequestAPI,
  FetchCreateOwnerShipTransferServicesRequestsAPI,
  FetchCreateRegisteringNewArabianHorseFoalServiceRequestAPI,
  FetchDeleteOwnerShipTransferServicesRequestsAPI,
  FetchGetAllServicesRequestsAPI,
  FetchGetAllServicesRequestsOwnerToApproveAPI,
  FetchGetDocumentTypesByServiceIdAPI,
  FetchGetFileAPI,
  FetchGetFilebase64API,
  FetchGetFollowUpDataAPI,
  FetchGetLoacationNameByLatLangAPI,
  FetchGetRegisteredHorsesAPI,
  FetchGetServiceDefinitionByIdAPI,
  FetchGetServicesRequestByIdAPI,
  FetchGetShippingAgentsAPI,
  FetchGetShowJournalistRequestTermsDataAPI,
  FetchIsHaseRequestEmbryoOocyteTransferPermitAPI,
  FetchOwnerCoveringCertificatesDropDownAPI,
  FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownAPI,
  FetchSubmitJournalistRequestAPI,
  FetchUpdateCoveringCertificateServiceRequestAPI,
  FetchUpdateEmbryoOocyteTransferPermitServiceRequestAPI,
  FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestAPI,
  FetchUpdateOwnerShipTransferServicesRequestsAPI,
  FetchUpdateRegisteringNewArabianHorseFoalServiceRequestAPI,
} from "./ServicesRequestsApi";

export const FetchCreateOwnerShipTransferServicesRequeststhunk =
  createAsyncThunk(
    "ServicesRequests/FetchCreateOwnerShipTransferServicesRequests",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOwnerShipTransferServicesRequestsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchGetAllServicesRequeststhunk = createAsyncThunk(
  "ServicesRequests/FetchGetAllServicesRequests",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetAllServicesRequestsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetServicesRequestByIdthunk = createAsyncThunk(
  "ServicesRequests/FetchGetServicesRequestById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetServicesRequestByIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchDeleteOwnerShipTransferServicesRequeststhunk =
  createAsyncThunk(
    "ServicesRequests/FetchDeleteOwnerShipTransferServicesRequests",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchDeleteOwnerShipTransferServicesRequestsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchGetAllServicesRequestsOwnerToApprovethunk = createAsyncThunk(
  "ServicesRequests/FetchGetAllServicesRequestsOwnerToApprove",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetAllServicesRequestsOwnerToApproveAPI(
        param
      );
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateOwnerShipTransferServicesRequeststhunk =
  createAsyncThunk(
    "ServicesRequests/FetchUpdateOwnerShipTransferServicesRequests",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateOwnerShipTransferServicesRequestsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchApproveRejectRequestthunk = createAsyncThunk(
  "ServicesRequests/FetchApproveRejectRequest",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchApproveRejectRequestAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetServiceDefinitionByIdthunk = createAsyncThunk(
  "ServicesRequests/FetchGetServiceDefinitionById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetServiceDefinitionByIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetFilethunk = createAsyncThunk(
  "ServicesRequests/FetchGetFile",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetFileAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetFilebase64thunk = createAsyncThunk(
  "ServicesRequests/FetchGetFilebase64",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetFilebase64API(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetDocumentTypesByServiceIdthunk = createAsyncThunk(
  "ServicesRequests/FetchGetDocumentTypesByServiceId",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetDocumentTypesByServiceIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetRegisteredHorsesthunk = createAsyncThunk(
  "ServicesRequests/FetchGetRegisteredHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetRegisteredHorsesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//covering certificate
export const FetchCreateCoveringCertificateServiceRequestthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchCreateCoveringCertificateServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateCoveringCertificateServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchUpdateCoveringCertificateServiceRequestthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchUpdateCoveringCertificateServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateCoveringCertificateServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchApproveRejectRequestCoveringCertificatethunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchApproveRejectRequestCoveringCertificate",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchApproveRejectRequestCoveringCertificateAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchCancelRequestthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchCancelRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCancelRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk =
  createAsyncThunk(
    "CoveringCertificateServiceRequest/FetchCheckLastChildrenOfHorseMareRegLessThanOneWeek",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Embryo Oocyte Transfer Permit
export const FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk =
  createAsyncThunk(
    "EmbryoOocyteTransferPermitServiceRequest/FetchCreateEmbryoOocyteTransferPermitServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateEmbryoOocyteTransferPermitServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk =
  createAsyncThunk(
    "EmbryoOocyteTransferPermitServiceRequest/FetchUpdateEmbryoOocyteTransferPermitServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateEmbryoOocyteTransferPermitServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchIsHaseRequestEmbryoOocyteTransferPermitthunk =
  createAsyncThunk(
    "EmbryoOocyteTransferPermitServiceRequest/FetchIsHaseRequestEmbryoOocyteTransferPermit",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchIsHaseRequestEmbryoOocyteTransferPermitAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Embryo Transfer Right Purchace Agreement
export const FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestthunk =
  createAsyncThunk(
    "EmbryoTransferRightPurchaceAgreementRequest/FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateEmbryoTransferRightPurchaceAgreementServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestthunk =
  createAsyncThunk(
    "EmbryoTransferRightPurchaceAgreementRequest/FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateEmbryoTransferRightPurchaceAgreementServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementthunk =
  createAsyncThunk(
    "EmbryoTransferRightPurchaceAgreementRequest/FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreement",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchApproveRejectRequestEmbryoTransferRightPurchaceAgreementAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchOwnerCoveringCertificatesDropDownthunk =
  createAsyncThunk(
    "EmbryoTransferRightPurchaceAgreementRequest/FetchOwnerCoveringCertificatesDropDown",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchOwnerCoveringCertificatesDropDownAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Registering New Arabian Horse Foal
export const FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk =
  createAsyncThunk(
    "RegisteringNewArabianHorseFoalServiceRequest/FetchCreateRegisteringNewArabianHorseFoalServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateRegisteringNewArabianHorseFoalServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk =
  createAsyncThunk(
    "RegisteringNewArabianHorseFoalServiceRequest/FetchUpdateRegisteringNewArabianHorseFoalServiceRequest",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchUpdateRegisteringNewArabianHorseFoalServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk =
  createAsyncThunk(
    "RegisteringNewArabianHorseFoalServiceRequest/FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDown",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//HorseOwnershipCertificates
export const FetchCreateOrEditHorseOwnershipCertificatesServiceRequestthunk =
  createAsyncThunk(
    "HorseOwnershipCertificatesServiceRequest/FetchCreateOrEditHorseOwnershipCertificatesServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditHorseOwnershipCertificatesServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//StudRegistrationCertificates
export const FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk =
  createAsyncThunk(
    "StudRegistrationCertificatesServiceRequest/FetchCreateOrEditStudRegistrationCertificatesServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditStudRegistrationCertificatesServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export const FetchGetLoacationNameByLatLangthunk =
  createAsyncThunk(
    "StudRegistrationCertificatesServiceRequest/FetchGetLoacationNameByLatLangAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchGetLoacationNameByLatLangAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Update Arabian Horse Data
export const FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk =
  createAsyncThunk(
    "UpdateArabianHorseDataServiceRequest/FetchCreateOrEditUpdateArabianHorseDataServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditUpdateArabianHorseDataServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Submit Journalist Request
export const FetchSubmitJournalistRequestthunk =
  createAsyncThunk(
    "UpdateArabianHorseDataServiceRequest/FetchSubmitJournalistRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchSubmitJournalistRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Get Show Journalist Request Terms Data
export const FetchGetShowJournalistRequestTermsDatathunk =
  createAsyncThunk(
    "UpdateArabianHorseDataServiceRequest/FetchGetShowJournalistRequestTermsDataAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchGetShowJournalistRequestTermsDataAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Declaration Of Arabian Horse Death
export const FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestthunk =
  createAsyncThunk(
    "DeclarationOfArabianHorseDeathServiceRequest/FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditDeclarationOfArabianHorseDeathServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Inactive Horse Registration Request
export const FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestthunk =
  createAsyncThunk(
    "InactiveHorseRegistrationRequestServiceRequest/FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditInactiveHorseRegistrationRequestServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//Add Racing Name For Imported Arabian Horse
export const FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestthunk =
  createAsyncThunk(
    "AddRacingNameForImportedArabianHorseServiceRequest/FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditAddRacingNameForImportedArabianHorseServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//IssuingHorsePassportDuplicateDamaged
export const FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestthunk =
  createAsyncThunk(
    "IssuingHorsePassportDuplicateDamagedServiceRequest/FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditIssuingHorsePassportDuplicateDamagedServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//importHorseFrozenSemen
export const FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk =
  createAsyncThunk(
    "ImportHorseFrozenSemenServiceRequest/FetchCreateOrEditImportHorseFrozenSemenDtoServiceRequestAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditImportHorseFrozenSemenServiceRequestAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//ImportHorseTemporary
export const FetchCreateOrEditTemporaryHorseImportthunk =
  createAsyncThunk(
    "ImportHorseTemporary/FetchCreateOrEditTemporaryHorseImportAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchCreateOrEditTemporaryHorseImportAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//ShippingAgents
export const FetchGetShippingAgentsthunk =
  createAsyncThunk(
    "ImportHorseFrozenSemenServiceRequest/FetchGetShippingAgentsAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchGetShippingAgentsAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//ConfirmImportDate
export const FetchConfirmImportDatethunk =
  createAsyncThunk(
    "ImportHorseFrozenSemenServiceRequest/FetchConfirmImportDateAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchConfirmImportDateAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//AddFollowUp
export const FetchAddFollowUpthunk =
  createAsyncThunk(
    "ServicesRequests/FetchAddFollowUpAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchAddFollowUpAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

//GetFollowUpData
export const FetchGetFollowUpDatathunk =
  createAsyncThunk(
    "ServicesRequests/FetchGetFollowUpDataAPI",
    async (param, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await FetchGetFollowUpDataAPI(
          param
        );
        const data = response.data;
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );