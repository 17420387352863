export function convertDubaiToUTC(dubaiTimeString) {
    const newTime = dubaiTimeString + 'Z';
    // Create a Date object assuming input is in Dubai time (UTC+4)
    const dubaiDate = new Date(newTime);

    // Convert to UTC by subtracting 4 hours
    dubaiDate.setUTCHours(dubaiDate.getUTCHours() - 4);

    // Return the UTC time in ISO format
    return dubaiDate.toISOString();
}

