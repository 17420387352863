import React from 'react'
import { Link } from 'react-router-dom'

export default function StudBookCard({ title, text, isSire = false, sireId, isDam = false, damId, isOwner = false, navigate = () => { }, ownerDetailsData = {}, isBreader = false , breederOwnerDetailsData={} }) {
    return (
        <div
            className="col-lg-3 col-md-3 col-sm-4 pl0-mb15"
        >
            <span className="spanblockcolor7">
                {title}
            </span>
            {!isSire && !isDam && !isOwner && !isBreader &&
                <span className='spanblockbold'>
                    {text}
                </span>
            }
            {isOwner &&
                <div
                    onClick={() => {
                        navigate("/ownerdetails", {
                            state: ownerDetailsData
                        });
                    }}
                >
                    <span
                        style={
                            {
                                display: "block",
                                fontWeight: "bold",
                                color: "#337ab7",
                                cursor: "pointer"
                            }
                        }
                    >
                        {text}
                    </span>
                </div>
            }
            {isBreader &&
                <div
                onClick={() => {
                      navigate("/ownerdetails", {
                        state: breederOwnerDetailsData,
                      });
                  }}
                >
                    <span
                        style={
                            {
                                display: "block",
                                fontWeight: "bold",
                                color: "#337ab7",
                                cursor: "pointer"
                            }
                        }
                    >
                        {text}
                    </span>
                </div>
            }
            {isSire &&
                <Link
                    to={`/horseDetails?horseId=${sireId}`}
                >
                    <span
                        style={
                            {
                                display: "block",
                                fontWeight: "bold",
                                color: "#337ab7",
                            }
                        }
                    >
                        {text}
                    </span>
                </Link>
            }
            {isDam &&
                <Link
                    to={`/horseDetails?horseId=${damId}`}
                >
                    <span
                        style={
                            {
                                display: "block",
                                fontWeight: "bold",
                                color: "rgb(254, 0, 125)",
                            }
                        }
                    >
                        {text}
                    </span>
                </Link>
            }
        </div>
    )
}
