import React, { useEffect, useState } from "react";
import ActionButton from "../../ActionButton";
import { Link } from "react-router-dom";
import { useTranslate } from "../../TranslationText";
import { useDispatch, useSelector } from "react-redux";
import Album from "../../Album";
import OwlCarousel from "react-owl-carousel";
import { showFirstFourWords, showFirstSixWords } from "../../../helper/showFirstSixWords";
import Lightbox from "react-18-image-lightbox";
import {
  FetchShowsGallery,
  FetchShowsNews
} from "../../../Redux/Shows/ShowsAction";
import { FetchStudBookHorseCompetitionthunk } from "../../../Redux/StudBook/StudBookAction";
import CompetitionsTab from "../../CompetitionsTab";
import Popup from "reactjs-popup";
import { incrementPageHorseCompetition } from "../../../Redux/StudBook/StudBookSlice";

const BaseURL = process.env.REACT_APP_BASE_URL;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;



export default function AuctionDetails({
  data,
  auctionHorses,
  auctionData,
  paymentStatus,
  bidderNumber,
  bidderId,
}) {
  const TabStyle = {
    display: "block",
    marginRight: 10,
    padding: "10px 20px",
    textAlign: "center",
    background: "rgba(0,0,0,0.08)",
    cursor: "pointer",
    color: "#000",
    transition: "all 300ms",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  };

  const {
    showNewsData,
    showMediaData,
  } = useSelector((state) => state.showsSlice);
  const {

    studBookHorseCompetitionData,
    takeHorseCompetition,
    pageHorseCompetition,
    totalCountHorseCompetition,
    studBookPedigreeData
  } = useSelector((state) => state.studBookSlice);
  const handlePageChangeHorseCompetition = (page) => {
    dispatch(incrementPageHorseCompetition(page));
  };
  const renderPaginationHorseCompetition = () => {
    const pages = [];

    const numPages = Math.ceil(
      totalCountHorseCompetition / takeHorseCompetition
    );

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <React.Fragment>
          <span
            key={i}
            style={{
              ...pagenumberStyle,
              background:
                i === pageHorseCompetition
                  ? "rgba(0, 0, 0)"
                  : pagenumberStyle.background,
              color:
                i === pageHorseCompetition ? "white" : pagenumberStyle.color,
            }}
            onClick={() => handlePageChangeHorseCompetition(i)}
          >
            {i}
          </span>
        </React.Fragment>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageHorseCompetition === 1) {
      _pages = pages
        .slice(pageHorseCompetition - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseCompetition > 1) {
      _pages = pages.slice(pageHorseCompetition);
    }
    if (pageHorseCompetition > 1 && numPages - pageHorseCompetition > 3) {
      _pages = pages
        .slice(pageHorseCompetition - 1, pageHorseCompetition)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseCompetition === 1 && numPages - pageHorseCompetition <= 3) {
      _pages = pages;
      if (studBookHorseCompetitionData.length === 0) {
        _pages = [];
      }
    }

    if (pageHorseCompetition > 1 && numPages - pageHorseCompetition <= 3) {
      _pages = pages
        .slice(pageHorseCompetition - 1, pageHorseCompetition)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const paginiateStyle = {
    // width: 50,
    padding: '10px 20px',
    // height: 50,
    // background: "#E44D52",
    // display: "inline-block",
    // lineHeight: "50px",
    color: "#fff",
    cursor: "pointer",
    margin: 'auto',
    borderRadius: 5,
  };

  const pagenumberStyle = {
    ...paginiateStyle,
    width: 50,
    height: 50,
    background: "#fff",
    display: "inline-block",
    lineHeight: "50px",
    color: "#000",
    cursor: "pointer",
    margin: 5,
    borderRadius: 5,
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const currentlang = localStorage.getItem('eahsLang') ?? 'en';

  const [allHorsesHovered, setAllHorsesHovered] = useState(false);
  const [soldHorsesHovered, setSoldHorsesHovered] = useState(false);
  const [unsoldHorsesHovered, setUnsoldHorsesHovered] = useState(false);
  const [allHorsesActive, setAllHorsesActive] = useState(true);
  const [soldHorsesActive, setSoldHorsesActive] = useState(false);
  const [unsoldHorsesActive, setUnsoldHorsesActive] = useState(false);
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();

  function gethourseImageURL(horse) {
    const imageurls = horse.horseAttachment.filter(
      (item) => item.entity === "AuctionHorse"
    );

    if (
      !!imageurls[0] &&
      imageurls[0].fileUrl !=
      "https://dev.eahs.org:8016/ImagesUploads/89def8e1-57e9-4524-a86b-bbf7571e50b7_download.png"
    ) {
      return imageurls[0].fileUrl;
    } else {
      return `${BaseURL}/assets/img/horseauction.png`;
    }
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function AuctionDates({ date, label = "label" }) {
    return (
      <>
        {!!date &&
          <div className="AuctionDatesStyle">
            <div>
              <i className="fa-solid fa-calendar C89C66"></i>
              <span className="ml-10">{translate(`web.${label}`, label)}</span>
            </div>
            <div>
              <span className="C89C66">
                {formatDate(new Date(date))} ||{" "}
                {new Date(date).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
        }
      </>
    );
  }

  // pagination proccess
  const pageSize = 15; // Number of horses per page
  // State variables for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to render pagination buttons
  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <span
          key={i}
          style={{
            ...pagenumberStyle,
            background:
              i === currentPage
                ? "rgba(228, 77, 82, 0.35)"
                : pagenumberStyle.background,
          }}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pages > 1;
  };
  const soldHourses = auctionHorses.filter(
    (horseData) => horseData.horseSellingStatus === "Sold"

  );

  const unsoldHorses = auctionHorses.filter(
    (horseData) =>
      horseData.horseSellingStatus !== "Sold"
  );
  // Update total number of horses based on the active tab
  const filteredHorses = auctionHorses.filter((horseData) => {
    if (allHorsesActive) {
      return true; // Show all horses
    } else if (soldHorsesActive) {
      return horseData.horseSellingStatus === "Sold"; // Show sold horses
    } else if (unsoldHorsesActive) {
      return (
        horseData.horseSellingStatus !== "Sold"
      ); // Show unsold horses
    }
    return true; // Default to show all horses
  });

  // Update totalHorses, totalPages, and currentPage based on filteredHorses
  const totalHorses = filteredHorses.length;
  const totalPages = Math.ceil(totalHorses / pageSize);

  // Calculate index range for current page
  // const startIndex = (currentPage - 1) * pageSize;
  const startIndex = 0;
  const endIndex = Math.min((currentPage) * pageSize, totalHorses);

  // Slice auctionHorses based on current page and filter based on the active tab
  const displayedHorses = filteredHorses.slice(startIndex, endIndex);
  useEffect(() => {
    console.debug('displayedHorses', displayedHorses);

  }, [auctionHorses])

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setimages] = useState([]);
  const dispatch = useDispatch();


  const handleHorseMedia = (index, albumMedia) => {
    setIsOpen(true);
    setPhotoIndex(index);
    setimages(albumMedia.map((media) => MediaBaseURL + media.url));
  };

  useEffect(() => {
    let param = { id: auctionData.auuctionId, langauge: currentLanguage }
    dispatch(FetchShowsGallery(param));
    dispatch(FetchShowsNews(param));
  }, [currentLanguage])



  return (
    <>
      {isOpen && (
        <div>
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
            wrapperClassName='galleryContainer'
          />
        </div>
      )}
      <div className="row AuctionDetailsRow AuctionDetailsRowTabs rtl">
        <div className="col-lg-2">
          <span
            className=""
            style={{
              ...TabStyle,
              background:
                allHorsesHovered || allHorsesActive
                  ? "#E44D52"
                  : TabStyle.background,
              color:
                allHorsesHovered || allHorsesActive ? "#fff" : TabStyle.color,
            }}
            onMouseOver={() => setAllHorsesHovered(true)}
            onMouseOut={() => setAllHorsesHovered(false)}
            onClick={() => {
              setAllHorsesActive(true);
              setSoldHorsesActive(false);
              setUnsoldHorsesActive(false);
              setCurrentPage(1);
            }}
          >
            {translate("web.allHorses", "All Horses")}
          </span>
        </div>
        {soldHourses.length > 0 && new Date(auctionData.auctionStartDate) < new Date() && (
          <div className="col-lg-3">
            <span
              className=""
              style={{
                ...TabStyle,
                background:
                  soldHorsesHovered || soldHorsesActive
                    ? "#E44D52"
                    : TabStyle.background,
                color:
                  soldHorsesHovered || soldHorsesActive
                    ? "#fff"
                    : TabStyle.color,
              }}
              onMouseOver={() => setSoldHorsesHovered(true)}
              onMouseOut={() => setSoldHorsesHovered(false)}
              onClick={() => {
                setAllHorsesActive(false);
                setSoldHorsesActive(true);
                setUnsoldHorsesActive(false);
                setCurrentPage(1);
              }}
            >
              {translate("web.listOfSoldHorses", "List of sold horses")}
            </span>
          </div>
        )}
        {unsoldHorses.length > 0 && new Date(auctionData.auctionStartDate) < new Date() && (
          <div className="col-lg-3">
            <span
              className=""
              style={{
                ...TabStyle,
                background:
                  unsoldHorsesHovered || unsoldHorsesActive
                    ? "#E44D52"
                    : TabStyle.background,
                color:
                  unsoldHorsesHovered || unsoldHorsesActive
                    ? "#fff"
                    : TabStyle.color,
              }}
              onMouseOver={() => setUnsoldHorsesHovered(true)}
              onMouseOut={() => setUnsoldHorsesHovered(false)}
              onClick={() => {
                setAllHorsesActive(false);
                setSoldHorsesActive(false);
                setUnsoldHorsesActive(true);
                setCurrentPage(1);
              }}
            >
              {translate("web.listOfUnsoldHorses", "List of unsold horses")}
            </span>
          </div>
        )}
      </div>
      <div className="row AuctionDetailsRow2 rtl">
        <div className="col-lg-8 col-md-8 col-sm-12 my-3 AuctionDetailsHorseList rtl">
          {displayedHorses.map((horse, index) => (
            <>
              <div className="row p-5">
                <div className="col-lg-6 col-md-6 col-sm-12  position-relative">
                  <img
                    src={gethourseImageURL(horse)}
                    className="w-100"
                    alt="hourseImage"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                    }}
                  />
                  <span className="AuctionDetailsSpan">
                    {translate("web.lotNo", "Lot No")} : {horse.lotNumber}
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 AuctionDetailsHorseDesc">
                  <div className="AuctionDetailsHorseDescDiv">
                    <h3 className="color-eahsred"> {horse.horseName} </h3>
                    <p className='noMarginBottomForP' dangerouslySetInnerHTML={{ __html: horse.horseDescription }} />
                    <div className="AuctionDetailsSpacer"></div>
                    <div className="AuctionDetails_HorseDetails">
                      {!!horse?.horseBirthdate &&
                        <div>
                          <span className="Horse_Details_Auction_Details_Birthdate">
                            {translate("web.birthDate", "Birthdate")}
                          </span>
                          <span className="Horse_Details_Auction_Details_BirthdateVal">
                            {formatDate(new Date(horse.horseBirthdate))}
                          </span>
                        </div>
                      }
                      <div>
                        <span className="Horse_Details_Auction_Details_Birthdate">
                          {translate("web.color", "color")}
                        </span>
                        <span className="Horse_Details_Auction_Details_BirthdateVal">
                          {!!horse.horseColor
                            ? horse.horseColor
                            : translate("web.unknown", "unknown")}
                        </span>
                      </div>
                      <div>
                        <span className="Horse_Details_Auction_Details_Birthdate">
                          {translate("web.owner", "Owner")}
                        </span>
                        <span className="Horse_Details_Auction_Details_BirthdateVal">
                          {!!horse.horseOwner
                            ? horse.horseOwner
                            : translate("web.unknown", "unknown")}
                        </span>
                      </div>
                    </div>

                    <div className="AuctionDetailsSpacer"></div>
                    <span className="AuctionDetailsStartingPrice">
                      {translate("web.startingPrice", "Starting Price")}{" "}
                      <span className="AuctionDetailsStartingPriceVal">
                        {horse.startingPrice.toLocaleString("en-US")} {translate('web.aed', 'AED')}
                      </span>
                    </span>
                    <br />
                    <br />
                    <div className="row justify-content-between">
                      {/* {windowWidth >= 1463 && (
                        <div className="col-lg-3 col-md-3 col-sm-12"></div>
                      )} */}

                      <Popup
                        modal
                        lockScroll={true}
                        onOpen={(e) => {
                          e.preventDefault();
                          let paramsHorseCompetition = {
                            id: horse.horseId,
                            skip: (pageHorseCompetition - 1) * takeHorseCompetition,
                            take: takeHorseCompetition,
                            langauge: currentlang,
                          };
                          dispatch(FetchStudBookHorseCompetitionthunk(paramsHorseCompetition));
                        }}
                        trigger={
                          <div className={
                            windowWidth >= 1463
                              ? "col-lg-4 col-md-4 col-sm-6"
                              : "col-lg-6 text-center col-md-6 col-sm-6"
                          }>
                            <ActionButton
                              type={"normalbtn"}
                              text={translate('web.results', 'Results').toLowerCase()}
                              width={150}
                              className="capatalize_text"
                              margin={"5px"}
                            />
                          </div>

                        }
                        closeOnDocumentClick={false}
                        closeOnEscape

                        contentStyle={{ background: 'rgba(255,255,255,1)', width: '95%', maxWidth: '1100px', padding: '25px 5px', zIndex: 5000 }}
                        overlayStyle={{ background: 'rgba(0,0,0,0.2)', zIndex: 5000 }}
                      >
                        {(close) => (
                          <div className="row rtl justify-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12 judgesList " style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                              <CompetitionsTab
                                studBookHorseCompetitionData={studBookHorseCompetitionData}
                                translate={translate}
                                pageHorseCompetition={pageHorseCompetition}
                                handlePageChangeHorseCompetition={handlePageChangeHorseCompetition}
                                renderPaginationHorseCompetition={renderPaginationHorseCompetition}
                                totalCountHorseCompetition={totalCountHorseCompetition}
                                takeHorseCompetition={takeHorseCompetition}
                                className={'justify-content-start'}
                                isPopUp={true}
                              />
                            </div>
                            <div
                              className="col-lg-12 col-md-12 col-sm-12 text-center mt-3"
                            >
                              <ActionButton text={translate("web.close", "Close")} type={'submit'} onClick={close} margin={'auto'} />
                            </div>
                          </div>
                        )}
                      </Popup>

                      <div
                        className={
                          windowWidth >= 1463
                            ? "col-lg-4 col-md-4 col-sm-6"
                            : "col-lg-6 text-center col-md-6 col-sm-6"
                        }
                      >
                        <ActionButton
                          type={"view-details"}
                          text={translate("web.viewDetails", "View Details")}
                          to={`${horse.auctionHorseId}&auctionid=${auctionData.auuctionId}&relatedUrls=${horse.relatedUrls}&pidgreeid=${horse.horseId}`}
                          width={150}
                          margin={"5px"}
                        />
                      </div>


                      {auctionData.auctionType.toLowerCase() === "hybrid"
                        ? horse.isHorseInArena && new Date(auctionData.auctionStartDate) < new Date() && false && (
                          <div
                            className={
                              windowWidth >= 1463
                                ? "col-lg-4 col-md-4 col-sm-6"
                                : "col-lg-6 text-center col-md-6 col-sm-6"
                            }
                          >
                            <ActionButton
                              type={"startbiding"}
                              text={translate("web.watchNow", "Watch Now")}
                              margin={"5px"}
                              width={150}
                              to={
                                horse.auctionHorseId +
                                "&" +
                                "auctionID=" +
                                auctionData.auuctionId +
                                "&" +
                                "HorseId=" +
                                horse.horseId +
                                "&" +
                                "bidderId=" +
                                bidderId +
                                "&" +
                                "BidderNumber=" +
                                bidderNumber +
                                "&" +
                                "VideoURL=" +
                                auctionData.introVideoUrl
                              }
                            />
                          </div>
                        )
                        : (auctionData.auctionType.toLowerCase() == "online" ||
                          auctionData.auctionType.toLowerCase() ==
                          "live") && new Date(auctionData.auctionStartDate) < new Date() && (
                          <div
                            className={
                              windowWidth >= 1463
                                ? "col-lg-4 col-md-4 col-sm-6"
                                : "col-lg-6 text-center col-md-6 col-sm-6"
                            }
                          >
                            <ActionButton
                              type={"startbiding"}
                              text={translate("web.watchNow", "Watch Now")}
                              margin={"5px"}
                              width={150}
                              to={
                                horse.auctionHorseId +
                                "&" +
                                "auctionID=" +
                                auctionData.auuctionId +
                                "&" +
                                "HorseId=" +
                                horse.horseId +
                                "&" +
                                "bidderId=" +
                                bidderId +
                                "&" +
                                "BidderNumber=" +
                                bidderNumber +
                                "&" +
                                "VideoURL=" +
                                auctionData.introVideoUrl
                              }
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* spacer */}
              {(index + 1) < displayedHorses.length &&
                <div className="AuctionDetailsAnotherSpacer"></div>
              }
            </>
          ))}
          <div className="col-lg-12 col-md-12 col-sm-12   text-center mb-3">
            {/* {currentPage !== 1 && auctionHorses.length > 0 && (
              <span
                style={paginiateStyle}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i className="fa-solid fa-chevron-left"></i>
                <i className="fa-solid fa-chevron-left"></i>
              </span>
            )} */}

            {/* {renderPagination()} */}

            {currentPage !== totalPages && auctionHorses.length > 0 && (
              <span
                className="btn btn-danger"
                style={paginiateStyle}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                {translate('web.loadMore', 'Load More')}
              </span>
            )}
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 p-0-10px my-3 right-to-left">
          <div className="viewDetails">
            <div className="text-center viewDetailsDiv ">
              {translate("web.moreDetails", "more details")}
            </div>
            <div className="AuctionDetailsSpacer"></div>
            <div className="pl-40-15-40">
              {paymentStatus !== "" && (
                <span className="paymentStatusRow">
                  <span>
                    {translate("web.paymentStatus", "Payment Status")}:
                  </span>{" "}
                  <span className="paymentStatusSpan">
                    {paymentStatus !== ""
                      ? translate(
                        `web.${paymentStatus.toLowerCase()}`,
                        paymentStatus
                      )
                      : ""}
                  </span>
                </span>
              )}
              <span className="d-block-c-red-font-18">
                {translate("web.auctionDates", "Auction Dates")}
              </span>

              <div className="d-flex-col-my-15">
                <AuctionDates
                  date={auctionData.auctionStartDate}
                  label={translate("web.startDateTime", "Start Date")}
                />
                <AuctionDates
                  date={auctionData.auctionEndDate}
                  label={translate("web.endDateTime", "End Date")}
                />
                <AuctionDates
                  date={auctionData.reservedBidNoStartDate}
                  label={translate(
                    "web.reservedStartFrom",
                    "Reserved Start From"
                  )}
                />
                <AuctionDates
                  date={auctionData.reservedBidNoEndDate}
                  label={translate("web.reservedInTo", "Reserved In To")}
                />
                <AuctionDates
                  date={auctionData.inspectionStartDate}
                  label={translate(
                    "web.inspectionStartFrom",
                    "Inspection Start From"
                  )}
                />
                <AuctionDates
                  date={auctionData.inspectionEndDate}
                  label={translate(
                    "web.inspectionStartTo",
                    "Inspection Start To"
                  )}
                />
              </div>
              <span className="d-block-c-red-font-18">
                {translate("web.auctionDetails", "Auction Details")}
              </span>
              <div className="d-flex-col-my-15">
                {/* <div className="d-flex-row-space-center-w100-my5">
                  <div>
                    <i className="fa-solid fa-location-dot C89C66"></i>
                    <span className="ml-10">
                      {translate("web.auctionType", "Auction Type")}{" "}
                    </span>
                  </div>
                  <div>
                    <span className="C89C66">
                      {translate(
                        `web.${auctionData.auctionType.toLowerCase()}`,
                        auctionData.auctionType
                      )}
                    </span>
                  </div>
                </div> */}
                {!!auctionData?.auctionFees &&
                  <div className="d-flex-row-space-center-w100-my5">
                    <div>
                      <i className="fa-solid fa-sack-dollar C89C66"></i>
                      <span className="ml-10">
                        {translate("web.auctionFees", "Auction Fees")}
                      </span>
                    </div>
                    <div>
                      <span className="C89C66">
                        {auctionData.auctionFees} {translate("web.aed", "AED")}
                      </span>
                    </div>
                  </div>
                }
              </div>
              {!!auctionData.location && (
                <>
                  <span className="d-block-c-red-font-18">
                    {translate("web.inspectionAddress", "Inspection Address")}
                  </span>
                  <p className="font-13-m-15-0">
                    <i className="fa-solid fa-location-dot C89C66"></i>{" "}
                    <Link
                      target="_blank"
                      to={auctionData.location}
                      className="ml-10"
                    >
                      {" "}
                      {translate("web.showonGoogleMaps", "Show on Google Maps")}
                    </Link>
                  </p>
                </>
              )}
              {(
                <>
                  <span className="d-block-c-red-font-18">
                    {translate(
                      "web.termsAndConditions",
                      "Terms and Conditions"
                    )}
                  </span>
                  {/* <Link
                    to={auctionData.termsUrl}
                    target="_blank"
                    className="d-block-font13-my15-ml-20"
                  >
                    {translate(
                      "web.showTermsandConditions",
                      "Show Terms and Conditions"
                    )}
                  </Link> */}
                  <Popup
                    modal
                    trigger={
                      <div key={'iFileItem'} className="d-block-font13-my15-ml-20" style={{ display: 'inline-block', color: '#0d6efd', cursor: 'pointer' }}>
                        {translate(
                          "web.showTermsandConditions",
                          "Show Terms and Conditions"
                        )}
                      </div>
                    }
                    closeOnDocumentClick={true}
                    closeOnEscape
                    className="popupUnsetmaxWidth"
                  >
                    {(close) => (
                      <React.Fragment>
                        <div
                          className="text-start"
                          style={{ maxHeight: '60vh', overflowY: 'scroll' }}
                          dangerouslySetInnerHTML={{ __html: currentLanguage == 'en' ? auctionData.termsAndConditionsTextEn : auctionData.termsAndConditionsTextAr }}
                        />
                        <br />
                        <div
                          className="rtl"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                          }}
                        >
                          <ActionButton type={'btn'} label={translate("web.close", "close")} onClick={close} text={translate("web.close", "close")} />
                        </div>
                      </React.Fragment>
                    )}
                  </Popup>
                </>
              )}
            </div>
          </div>
        </div>


      </div>
      <div className="row AuctionDetailsRow2">
        {!!showMediaData && showMediaData.length > 0 && (
          <div className="col-md-12 rtl">
            <div className="row">
              <h2>{translate("web.mediaGallery", "Media Gallery")}</h2>
              <div className="col-md-12 showsGallery">
                {!!showMediaData && showMediaData.length > 0 ? (
                  showMediaData.map((item) => (
                    <Album isShowDetailsPage={true} item={item} handleClick={handleHorseMedia} />
                  ))
                ) : (
                  <span className="text-danger text-center dblock">
                    {translate("web.nodataFound", "No Data Found !")}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        {!!showNewsData && showNewsData.length > 0 && (
          <div className="col-md-12 rtl">
            <div className="row">
              <h2>{translate("web.auctionNews", "Auction News")}</h2>
              <br />
              <div className="col-md-12 showsGallery">
                <OwlCarousel>
                  {!!showNewsData && showNewsData.length > 0 && (
                    showNewsData.map((item) => (
                      <div className="justRotate">
                        <Link to={item.newsUrl} target={item.externalLink ? '_blank' : '_self'} className="more_news_post_container">
                          <div className="more_news_image_container">
                            <img src={`${MediaBaseURL}${item.Item_CoverImage}`} alt="post" />
                            <div className="more_news_image_overlay"></div>
                          </div>

                          <div className="more_news_posted_at rtl">{item.Item_Date}</div>
                          <div className="more_news_post_title rtl">
                            {showFirstFourWords(item.Item_Title)}
                            <i className="fa-solid fa-chevron-right "></i>
                          </div>
                          <p className="more_news_post_text">
                            {showFirstSixWords(item.Item_ShortDescription)}
                          </p>
                        </Link>
                      </div>

                    ))
                  )}
                </OwlCarousel>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
