import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  FetchApproveRejectRequestCoveringCertificatethunk,
  FetchCancelRequestthunk,
  FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk,
  FetchCreateCoveringCertificateServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateCoveringCertificateServiceRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import AsyncSelect from "react-select/async";
import axiosInstance from "../axios/axios";
import { formatDate } from "../helper/formatDate";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import Popup from "reactjs-popup";
import { base64ToBlob } from "../helper/base64ToBlob";
import {
  FetchCountriesthunk,
  FetchHorseColorsthunk,
} from "../Redux/LookUp/LookUpAction";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { isLessThanUndefinedYearOld } from "../helper/isLessThanUndefinedYearOld";
import dayjs from "dayjs";
import { CheckDateBeforUndefinedDays } from "../helper/CheckDateBeforUndefinedDays";
import EahsForm from "../components/EahsForm";

export default function RequestCoveringCertificate() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const { countriesData, horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    checkLastChildrenOfHorseMareRegLessThanOneWeekData,
    createCoveringCertificateServiceRequestData
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    isStallionRegistered: "Registered",
    isMareRegistered: "Registered",
    notStallionOwner: false,
    horseStallionReg: "",
    horseStallionRegDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    horseNotStallion: "",
    horseNotStallionDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    ownerIdNotStallionReg: "",
    regNoStallionReg: "",
    horseNameStallionReg: "",
    originStallionReg: "",
    breederStallionReg: "",
    genderStallionReg: "",
    colorStallionReg: "",
    birthDateStallionReg: "",
    sireStallionReg: "",
    damStallionReg: "",
    ownerNameStallionReg: "",
    ownerEmailStallionReg: "",
    ownerMobileStallionReg: "",

    horseNameEnStallionUnReg: "",
    horseNameArStallionUnReg: "",
    currentRegNoStallionUnReg: "",
    damEnStallionUnReg: "",
    damArStallionUnReg: "",
    sireEnStallionUnReg: "",
    sireArStallionUnReg: "",
    colorStallionUnReg: "",
    colorStallionUnRegDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    ownerNameEnStallionUnReg: "",
    ownerNameArStallionUnReg: "",
    originStallionUnReg: "",
    originStallionUnRegDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    uelnStallionUnReg: "",
    genderStallionUnReg: "",
    genderStallionUnRegDrop: {
      label: translate("web.sex", "Sex"),
      value: null,
    },
    birthDateStallionUnReg: null,
    breederNameEnStallionUnReg: "",
    breederNameArStallionUnReg: "",
    horseAddressStallionUnReg: "",
    horseCountryStallionUnReg: "",
    horseCountryStallionUnRegDrop: {
      label: translate("web.horseCountry", "Horse Country"),
      value: null,
    },

    breedingHappendAbroadAttachmentFile: null,
    stallionOwnerSignedPermitAttachmentFile: null,
    embryoIsImportedAttachmentFile: null,
    importedSemenCertificateAttachmentFile: null,

    horseMareReg: "",
    horseMareRegDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    originMareReg: "",
    regNoMareReg: "",
    horseNameMareReg: "",
    genderMareReg: "",
    colorMareReg: "",
    birthDateMareReg: "",
    sireMareReg: "",
    damMareReg: "",
    breederMareReg: "",

    horseNameEnMareUnReg: "",
    horseNameArMareUnReg: "",
    currentRegNoMareUnReg: "",
    damEnMareUnReg: "",
    damArMareUnReg: "",
    sireEnMareUnReg: "",
    sireArMareUnReg: "",
    colorMareUnReg: "",
    colorMareUnRegDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    originMareUnReg: "",
    originMareUnRegDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    horseCountryMareUnReg: "",
    horseCountryMareUnRegDrop: {
      label: translate("web.horseCountry", "Horse Country"),
      value: null,
    },
    uelnMareUnReg: "",
    genderMareUnReg: "",
    genderMareUnRegDrop: {
      label: translate("web.sex", "Sex"),
      value: null,
    },
    birthDateMareUnReg: null,
    breederNameEnMareUnReg: "",
    breederNameArMareUnReg: "",
    horseAddressMareUnReg: "",

    typeOfCovering: "NaturalBreeding",
    coveringDate: null,
    breedingHappendAbroad: false,
    embryoIsImported: false,
    stallionOwnerSignedPermit: false,
    stallionOwnerSignedPermitPart: false,
    filePathBreedingHappendAbroad: "",
    fileTypeBreedingHappendAbroad: "",
    fileNameBreedingHappendAbroad: "",
    documentBase64BreedingHappendAbroad: "",

    filePathEmbryoIsImported: "",
    fileTypeEmbryoIsImported: "",
    fileNameEmbryoIsImported: "",
    documentBase64EmbryoIsImported: "",

    filePathStallionOwnerSignedPermit: "",
    fileTypeStallionOwnerSignedPermit: "",
    fileNameStallionOwnerSignedPermit: "",
    documentBase64StallionOwnerSignedPermit: "",

    filePathImportedSemenCertificate: "",
    fileTypeImportedSemenCertificate: "",
    fileNameImportedSemenCertificate: "",
    documentBase64ImportedSemenCertificate: "",

    eventName: "",
    stallionComment: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",

    coveringCertificateId: "",
    horseMareRegLessThanOneWeek: false,
    semenDoseOwnershipReferenceNumber: "",
    isViolated: false,
    paymentStatus: "",
    violationPayment: "",

    inseminationImportedType: "SemenDoseImported",
    semenDoseImportedReferenceNumber: "",

    embryoTransfer: false,
    recipientMare: "ArabianRegistered",
    recipientMareId: "",
    recipientMareIdDrop: {
      label: translate("web.recipientMareName", "Recipient Mare Name"),
      value: null,
    },
    recipientMareNameEnUnReg: "",
    recipientMareNameArUnReg: "",
    microchipNo: "",
    transferDate: null,
    veterinarian: "",
    transferAddress: "",

    filePathVeterinarianReport: "",
    fileTypeVeterinarianReport: "",
    fileNameVeterinarianReport: "",
    documentBase64VeterinarianReport: "",
    veterinarianReportAttachmentFile: null,
  });

  const loadd = useRef(false);
  const [errorHorseStallionReg, setErrorHorseStallionReg] = useState(false);
  const [errorHorseNameEnStallionUnReg, setErrorHorseNameEnStallionUnReg] = useState(false);
  const [errorHorseNameArStallionUnReg, setErrorHorseNameArStallionUnReg] = useState(false);
  const [errorDamEnStallionUnReg, setErrorDamEnStallionUnReg] = useState(false);
  const [errorDamArStallionUnReg, setErrorDamArStallionUnReg] = useState(false);
  const [errorSireEnStallionUnReg, setErrorSireEnStallionUnReg] = useState(false);
  const [errorSireArStallionUnReg, setErrorSireArStallionUnReg] = useState(false);
  const [errorOwnerNameEnStallionUnReg, setErrorOwnerNameEnStallionUnReg] = useState(false);
  const [errorOwnerNameArStallionUnReg, setErrorOwnerNameArStallionUnReg] = useState(false);
  const [errorBreederNameEnStallionUnReg, setErrorBreederNameEnStallionUnReg] = useState(false);
  const [errorBreederNameArStallionUnReg, setErrorBreederNameArStallionUnReg] = useState(false);
  const [errorHorseAddressStallionUnReg, setErrorHorseAddressStallionUnReg] = useState(false);
  const [errorCurrentRegNoStallionUnReg, setErrorCurrentRegNoStallionUnReg] = useState(false);
  const [errorColorStallionUnReg, setErrorColorStallionUnReg] = useState(false);
  const [errorOriginStallionUnReg, setErrorOriginStallionUnReg] = useState(false);
  const [errorHorseCountryStallionUnReg, setErrorHorseCountryStallionUnReg] = useState(false);
  const [errorBirthDateStallionUnReg, setErrorBirthDateStallionUnReg] = useState(false);
  const [errorHorseMareReg, setErrorHorseMareReg] = useState(false);
  const [errorHorseNameEnMareUnReg, setErrorHorseNameEnMareUnReg] = useState(false);
  const [errorHorseNameArMareUnReg, setErrorHorseNameArMareUnReg] = useState(false);
  const [errorDamEnMareUnReg, setErrorDamEnMareUnReg] = useState(false);
  const [errorDamArMareUnReg, setErrorDamArMareUnReg] = useState(false);
  const [errorSireEnMareUnReg, setErrorSireEnMareUnReg] = useState(false);
  const [errorSireArMareUnReg, setErrorSireArMareUnReg] = useState(false);

  const [errorBreederNameEnMareUnReg, setErrorBreederNameEnMareUnReg] = useState(false);
  const [errorBreederNameArMareUnReg, setErrorBreederNameArMareUnReg] = useState(false);
  const [errorHorseAddressMareUnReg, setErrorHorseAddressMareUnReg] = useState(false);
  const [errorCurrentRegNoMareUnReg, setErrorCurrentRegNoMareUnReg] = useState(false);
  const [errorColorMareUnReg, setErrorColorMareUnReg] = useState(false);
  const [errorOriginMareUnReg, setErrorOriginMareUnReg] = useState(false);
  const [errorHorseCountryMareUnReg, setErrorHorseCountryMareUnReg] = useState(false);
  const [errorBirthDateMareUnReg, setErrorBirthDateMareUnReg] = useState(false);
  const [errorCoveringDate, setErrorCoveringDate] = useState(false);
  const [errorBreedingHappendAbroad, setErrorBreedingHappendAbroad] = useState(false);
  const [errorBreedingHappendAbroadCheck, setErrorBreedingHappendAbroadCheck] = useState(false);
  const [errorEmbryoIsImported, setErrorEmbryoIsImported] = useState(false);
  const [errorEmbryoIsImportedCheck, setErrorEmbryoIsImportedCheck] = useState(false);
  const [errorStallionOwnerSignedPermit, setErrorStallionOwnerSignedPermit] = useState(false);
  const [errorStallionOwnerSignedPermitCheck, setErrorStallionOwnerSignedPermitCheck] = useState(false);
  const [errorImportedSemenCertificate, setErrorImportedSemenCertificate] = useState(false);
  const [errorSemenDoseOwnershipReferenceNumber, setErrorSemenDoseOwnershipReferenceNumber] = useState(false);
  const [errorSemenDoseImportedReferenceNumber, setErrorSemenDoseImportedReferenceNumber] = useState(false);
  const [errorRecipientMare, setErrorRecipientMare] = useState(false);
  const [errorRecipientMareNameEnUnReg, setErrorRecipientMareNameEnUnReg] = useState(false);
  const [errorRecipientMareNameArUnReg, setErrorRecipientMareNameArUnReg] = useState(false);
  const [errorMicrochipNo, setErrorMicrochipNo] = useState(false);
  const [errorTransferDate, setErrorTransferDate] = useState(false);
  const [errorVeterinarian, setErrorVeterinarian] = useState(false);
  const [errorTransferAddress, setErrorTransferAddress] = useState(false);
  const [errorVeterinarianReport, setErrorVeterinarianReport] = useState(false);

  //state ref
  const horseStallionRegRef = useRef(null);
  const horseNameEnStallionUnRegRef = useRef(null);
  const horseNameArStallionUnRegRef = useRef(null);
  const damEnStallionUnRegRef = useRef(null);
  const damArStallionUnRegRef = useRef(null);
  const sireEnStallionUnRegRef = useRef(null);
  const sireArStallionUnRegRef = useRef(null);
  const ownerNameEnStallionUnRegRef = useRef(null);
  const ownerNameArStallionUnRegRef = useRef(null);
  const breederNameEnStallionUnRegRef = useRef(null);
  const breederNameArStallionUnRegRef = useRef(null);
  const horseAddressStallionUnRegRef = useRef(null);
  const currentRegNoStallionUnRegRef = useRef(null);
  const colorStallionUnRegRef = useRef(null);
  const originStallionUnRegRef = useRef(null);
  const horseCountryStallionUnRegRef = useRef(null);
  const birthDateStallionUnRegRef = useRef(null);
  const horseMareRegRef = useRef(null);
  const horseNameEnMareUnRegRef = useRef(null);
  const horseNameArMareUnRegRef = useRef(null);
  const damEnMareUnRegRef = useRef(null);
  const damArMareUnRegRef = useRef(null);
  const sireEnMareUnRegRef = useRef(null);
  const sireArMareUnRegRef = useRef(null);
  const breederNameEnMareUnRegRef = useRef(null);
  const breederNameArMareUnRegRef = useRef(null);
  const horseAddressMareUnRegRef = useRef(null);
  const currentRegNoMareUnRegRef = useRef(null);
  const colorMareUnRegRef = useRef(null);
  const originMareUnRegRef = useRef(null);
  const horseCountryMareUnRegRef = useRef(null);
  const birthDateMareUnRegRef = useRef(null);
  const coveringDateRef = useRef(null);
  const breedingHappendAbroadRef = useRef(null);
  const breedingHappendAbroadCheckRef = useRef(null);
  const embryoIsImportedRef = useRef(null);
  const embryoIsImportedCheckRef = useRef(null);
  const stallionOwnerSignedPermitRef = useRef(null);
  const stallionOwnerSignedPermitCheckRef = useRef(null);
  const importedSemenCertificateRef = useRef(null);
  const semenDoseOwnershipReferenceNumberRef = useRef(null);
  const semenDoseImportedReferenceNumberRef = useRef(null);
  const recipientMareRef = useRef(null);
  const recipientMareNameEnUnRegRef = useRef(null);
  const recipientMareNameArUnRegRef = useRef(null);
  const microchipNoRef = useRef(null);
  const transferDateRef = useRef(null);
  const veterinarianRef = useRef(null);
  const transferAddressRef = useRef(null);
  const veterinarianReportRef = useRef(null);

  const [
    breedingHappendAbroadAttachmentFile,
    setBreedingHappendAbroadAttachmentFile,
  ] = useState([]);
  const [
    stallionOwnerSignedPermitAttachmentFile,
    setStallionOwnerSignedPermitAttachmentFile,
  ] = useState([]);
  const [embryoIsImportedAttachmentFile, setEmbryoIsImportedAttachmentFile] =
    useState([]);
  const [
    importedSemenCertificateAttachmentFile,
    setImportedSemenCertificateAttachmentFile,
  ] = useState([]);
  const [
    veterinarianReportAttachmentFile,
    setVeterinarianReportAttachmentFile,
  ] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupCancel, setOpenPopupCancel] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });

  const subAccountId = localStorage.getItem("currentSubAccountId");

  const _isOnlinePayment = useRef("");
  const _status = useRef("");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  const nowDate = new Date();
  const oneYearAgo = new Date(nowDate);  // Copy the current date
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setDate(oneYearAgo.getDate() - 10);

  //function
  const handleFormChange = (e, input) => {
    if (input === "horseStallionReg") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        horseStallionRegDrop: e,
        horseStallionReg: e.value,
        regNoStallionReg: horse[0]?.registerNumber ?? "",
        horseNameStallionReg: horse[0]?.name ?? "",
        genderStallionReg: horse[0]?.gender ?? "",
        colorStallionReg: horse[0]?.color ?? "",
        birthDateStallionReg: horse[0]?.birthDate ?? "",
        sireStallionReg: horse[0]?.sire ?? "",
        damStallionReg: horse[0]?.dam ?? "",
        originStallionReg: horse[0]?.origin ?? "",
        breederStallionReg: horse[0]?.breeder ?? "",
      });
    } else if (!!input && input === "originStallionUnReg") {
      setFormModel({
        ...formModel,
        originStallionUnReg: e.value,
        originStallionUnRegDrop: e,
      });
    } else if (!!input && input === "horseCountryStallionUnReg") {
      setFormModel({
        ...formModel,
        horseCountryStallionUnReg: e.value,
        horseCountryStallionUnRegDrop: e,
      });
    } else if (!!input && input === "colorStallionUnReg") {
      setFormModel({
        ...formModel,
        colorStallionUnReg: e.value,
        colorStallionUnRegDrop: e,
      });
    } else if (input === "horseNotStallion") {
      if (e?.value != null && e?.value != undefined) {
        setFormModel({
          ...formModel,
          horseNotStallionDrop: e,
          horseNotStallion: e.value,
          ownerEmailStallionReg: e.details.ownerEmail,
          ownerMobileStallionReg: e.details.ownerMobile,
          ownerNameStallionReg: e.details.ownerReg + " - " + e.details.owner,
          originStallionReg: e.details.origin,
          breederStallionReg: e.details.breeder,
          genderStallionReg: e.details.gender,
          colorStallionReg: e.details.color,
          birthDateStallionReg: e.details.birthDate,
          sireStallionReg: e.details.sire,
          damStallionReg: e.details.dam,
          ownerIdNotStallionReg: e.details.ownerIdGuid
        });
      }
    } else if (input === "stallionType") {
      setFormModel({
        ...formModel,
        isStallionRegistered: e.target.value,

        //stallionOwnerSignedPermitPart: e.target.value === "Unregistered",
        //stallionOwnerSignedPermit: false,
        //stallionOwnerSignedPermitAttachmentFile: null,

        notStallionOwner: false,
        horseStallionReg: "",
        horseStallionRegDrop: {
          label: translate("web.horse", "Horse"),
          value: null,
        },
        horseNotStallion: "",
        horseNotStallionDrop: {
          label: translate("web.horse", "Horse"),
          value: "",
        },
        ownerEmailStallionReg: "",
        ownerMobileStallionReg: "",
        ownerNameStallionReg: "",
        originStallionReg: "",
        breederStallionReg: "",
        genderStallionReg: "",
        colorStallionReg: "",
        birthDateStallionReg: "",
        sireStallionReg: "",
        damStallionReg: "",

        horseNameEnStallionUnReg: "",
        horseNameArStallionUnReg: "",
        damEnStallionUnReg: "",
        damArStallionUnReg: "",
        sireEnStallionUnReg: "",
        sireArStallionUnReg: "",
        ownerNameEnStallionUnReg: "",
        ownerNameArStallionUnReg: "",
        breederNameEnStallionUnReg: "",
        breederNameArStallionUnReg: "",
        horseAddressStallionUnReg: "",
        currentRegNoStallionUnReg: "",
        uelnStallionUnReg: "",
        colorStallionUnReg: "",
        colorStallionUnRegDrop: {
          label: translate("web.color", "Color"),
          value: null,
        },
        birthDateStallionUnReg: null,
      });

      //setStallionOwnerSignedPermitAttachmentFile([]);
    } else if (input === "horseMareReg") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      if (e.value !== "" && formModel.coveringDate != null) {
        let params = {
          horseId: e.value,
          // coveringDate: dayjs(formModel.coveringDate)
          coveringDate: dayjs(formModel.coveringDate).format("DD/MM/YYYY")
        }
        dispatch(FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk(params));
      }

      setFormModel({
        ...formModel,
        horseMareRegDrop: e,
        horseMareReg: e.value,
        regNoMareReg: horse[0]?.registerNumber ?? "",
        horseNameMareReg: horse[0]?.name ?? "",
        genderMareReg: horse[0]?.gender ?? "",
        colorMareReg: horse[0]?.color ?? "",
        birthDateMareReg: horse[0]?.birthDate ?? "",
        sireMareReg: horse[0]?.sire ?? "",
        damMareReg: horse[0]?.dam ?? "",
        originMareReg: horse[0]?.origin ?? "",
        breederMareReg: horse[0]?.breeder ?? "",
      });
    } else if (!!input && input === "originMareUnReg") {
      setFormModel({
        ...formModel,
        originMareUnReg: e.value,
        originMareUnRegDrop: e,
      });
    } else if (!!input && input === "horseCountryMareUnReg") {
      setFormModel({
        ...formModel,
        horseCountryMareUnReg: e.value,
        horseCountryMareUnRegDrop: e,
      });
    } else if (!!input && input === "colorMareUnReg") {
      setFormModel({
        ...formModel,
        colorMareUnReg: e.value,
        colorMareUnRegDrop: e,
      });
    } else if (input === "mareType") {
      setFormModel({
        ...formModel,
        isMareRegistered: e.target.value,
        embryoTransfer: false,
        horseMareReg: "",
        horseMareRegDrop: {
          label: translate("web.horse", "Horse"),
          value: null,
        },
        originMareReg: "",
        breederMareReg: "",
        genderMareReg: "",
        colorMareReg: "",
        birthDateMareReg: "",
        sireMareReg: "",
        damMareReg: "",

        horseNameEnMareUnReg: "",
        horseNameArMareUnReg: "",
        damEnMareUnReg: "",
        damArMareUnReg: "",
        sireEnMareUnReg: "",
        sireArMareUnReg: "",
        breederNameEnMareUnReg: "",
        breederNameArMareUnReg: "",
        horseAddressMareUnReg: "",
        currentRegNoMareUnReg: "",
        uelnMareUnReg: "",
        colorMareUnReg: "",
        colorMareUnRegDrop: {
          label: translate("web.color", "Color"),
          value: null,
        },
        birthDateMareUnReg: null,

        recipientMare: "ArabianRegistered",
        recipientMareId: "",
        recipientMareIdDrop: {
          label: translate("web.recipientMareName", "Recipient Mare Name"),
          value: null,
        },
        recipientMareNameEnUnReg: "",
        recipientMareNameArUnReg: "",
        microchipNo: "",
        transferDate: null,
        veterinarian: "",
        transferAddress: "",
        veterinarianReportAttachmentFile: null,

      });
      setVeterinarianReportAttachmentFile([]);
    } else if (input === "notStallionOwner") {
      setFormModel({
        ...formModel,
        notStallionOwner: e.target.checked,
        // stallionOwnerSignedPermitPart: e.target.checked,
        // stallionOwnerSignedPermit: false,
        // stallionOwnerSignedPermitAttachmentFile: null,

        // //typeOfCovering: "NaturalBreeding",
        horseStallionReg: "",
        horseStallionRegDrop: {
          label: translate("web.horse", "Horse"),
          value: null,
        },
        horseNotStallion: "",
        horseNotStallionDrop: {
          label: translate("web.horse", "Horse"),
          value: "",
        },
        ownerEmailStallionReg: "",
        ownerMobileStallionReg: "",
        ownerNameStallionReg: "",
        originStallionReg: "",
        breederStallionReg: "",
        genderStallionReg: "",
        colorStallionReg: "",
        birthDateStallionReg: "",
        sireStallionReg: "",
        damStallionReg: "",
      });

      //setStallionOwnerSignedPermitAttachmentFile([]);
    } else if (input === "breedingHappendAbroad") {
      setFormModel({
        ...formModel,
        breedingHappendAbroad: e.target.checked,
      });
    } else if (input === "embryoIsImported") {
      setFormModel({
        ...formModel,
        embryoIsImported: e.target.checked,
      });
    } else if (input === "stallionOwnerSignedPermit") {
      setFormModel({
        ...formModel,
        stallionOwnerSignedPermit: e.target.checked,
      });
    } else if (!!input && input === "birthDateStallionUnReg") {
      //setBirthdateStallionUnReg(e);

      setFormModel({
        ...formModel,
        birthDateStallionUnReg: e.target.value,
      });
    } else if (!!input && input === "birthDateMareUnReg") {
      //setBirthdateMareUnReg(e);

      setFormModel({
        ...formModel,
        birthDateMareUnReg: e.target.value,
      });
    } else if (!!input && input === "coveringDate") {
      //setCoveringDate(e);
      if (!!e && formModel.horseMareReg !== "") {
        let params = {
          horseId: formModel.horseMareReg,
          // coveringDate: dayjs(e.target.value)
          coveringDate: dayjs(e.target.value).format("DD/MM/YYYY")
        }

        dispatch(FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk(params));
      }

      setFormModel({
        ...formModel,
        coveringDate: e.target.value,
      });
    } else if (!!input && input === "horseNameEnStallionUnReg") {
      // Allow only letters and . and _
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          horseNameEnStallionUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "sireEnStallionUnReg") {
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          sireEnStallionUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "damEnStallionUnReg") {
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          damEnStallionUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "horseNameEnMareUnReg") {
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          horseNameEnMareUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "sireEnMareUnReg") {
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          sireEnMareUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "damEnMareUnReg") {
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          damEnMareUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "recipientMareNameEnUnReg") {
      const regex = /^[a-zA-Z._]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          recipientMareNameEnUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (input === "embryoTransfer") {
      setFormModel({
        ...formModel,
        embryoTransfer: e.target.checked,
        recipientMare: "ArabianRegistered",
        recipientMareId: "",
        recipientMareIdDrop: {
          label: translate("web.recipientMareName", "Recipient Mare Name"),
          value: null,
        },
        recipientMareNameEnUnReg: "",
        recipientMareNameArUnReg: "",
        microchipNo: "",
        transferDate: null,
        veterinarian: "",
        transferAddress: "",
        veterinarianReportAttachmentFile: null,
      });

      setVeterinarianReportAttachmentFile([]);
    } else if (input === "recipientMare") {
      setFormModel({
        ...formModel,
        recipientMare: e.target.value,
        recipientMareId: "",
        recipientMareIdDrop: {
          label: translate("web.recipientMareName", "Recipient Mare Name"),
          value: null,
        },
        recipientMareNameEnUnReg: "",
        recipientMareNameArUnReg: "",
        microchipNo: "",
      });

    } else if (!!input && input === "recipientMareId") {
      setFormModel({
        ...formModel,
        recipientMareId: e.value,
        recipientMareIdDrop: e,
      });
    }

    else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validInputs = () => {
    let valid = true;
    //stallion
    if (
      formModel.isStallionRegistered === "Registered"
      && (
        (formModel.horseStallionReg === "" && !formModel.notStallionOwner)
        || (formModel.horseNotStallion === "" && formModel.notStallionOwner)
      )
    ) {
      setErrorHorseStallionReg(true);

      valid = false;

      horseStallionRegRef?.current?.focus();
      return;
    }
    else {
      setErrorHorseStallionReg(false);
    }

    if (formModel.isStallionRegistered === "Unregistered") {
      if (formModel.horseNameEnStallionUnReg === "") {
        setErrorHorseNameEnStallionUnReg(true);

        valid = false;

        horseNameEnStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseNameEnStallionUnReg(false);
      }

      if (formModel.horseNameArStallionUnReg === "") {

        setErrorHorseNameArStallionUnReg(true);

        valid = false;

        horseNameArStallionUnRegRef?.current?.focus();

        return;
      }
      else {
        setErrorHorseNameArStallionUnReg(false);
      }

      if (formModel.damEnStallionUnReg === "") {
        setErrorDamEnStallionUnReg(true);
        valid = false;
        damEnStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorDamEnStallionUnReg(false);
      }

      if (formModel.damArStallionUnReg === "") {
        setErrorDamArStallionUnReg(true);
        valid = false;
        damArStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorDamArStallionUnReg(false);
      }

      if (formModel.sireEnStallionUnReg === "") {
        setErrorSireEnStallionUnReg(true);
        valid = false;
        sireEnStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorSireEnStallionUnReg(false);
      }

      if (formModel.sireArStallionUnReg === "") {
        setErrorSireArStallionUnReg(true);
        valid = false;
        sireArStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorSireArStallionUnReg(false);
      }

      if (formModel.ownerNameEnStallionUnReg === "") {
        setErrorOwnerNameEnStallionUnReg(true);
        valid = false;
        ownerNameEnStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorOwnerNameEnStallionUnReg(false);
      }

      if (formModel.ownerNameArStallionUnReg === "") {
        setErrorOwnerNameArStallionUnReg(true);
        valid = false;
        ownerNameArStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorOwnerNameArStallionUnReg(false);
      }

      if (formModel.breederNameEnStallionUnReg === "") {
        setErrorBreederNameEnStallionUnReg(true);
        valid = false;
        breederNameEnStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorBreederNameEnStallionUnReg(false);
      }

      if (formModel.breederNameArStallionUnReg === "") {
        setErrorBreederNameArStallionUnReg(true);
        valid = false;
        breederNameArStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorBreederNameArStallionUnReg(false);
      }

      if (formModel.horseAddressStallionUnReg === "") {
        setErrorHorseAddressStallionUnReg(true);
        valid = false;
        horseAddressStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseAddressStallionUnReg(false);
      }

      if (formModel.currentRegNoStallionUnReg === "") {
        setErrorCurrentRegNoStallionUnReg(true);
        valid = false;
        currentRegNoStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorCurrentRegNoStallionUnReg(false);
      }

      if (formModel.colorStallionUnReg === "") {
        setErrorColorStallionUnReg(true);
        valid = false;
        colorStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorColorStallionUnReg(false);
      }

      if (formModel.originStallionUnReg === "") {
        setErrorOriginStallionUnReg(true);
        valid = false;
        originStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorOriginStallionUnReg(false);
      }

      if (formModel.horseCountryStallionUnReg === "") {
        setErrorHorseCountryStallionUnReg(true);
        valid = false;
        horseCountryStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseCountryStallionUnReg(false);
      }

      if (formModel.birthDateStallionUnReg === null) {
        setErrorBirthDateStallionUnReg(true);
        valid = false;
        birthDateStallionUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorBirthDateStallionUnReg(false);
      }

      if (!formModel.stallionOwnerSignedPermit && formModel.typeOfCovering === "NaturalBreeding") {
        setErrorStallionOwnerSignedPermitCheck(true);
        valid = false;
        stallionOwnerSignedPermitCheckRef?.current?.focus();
        return;
      }
      else {
        setErrorStallionOwnerSignedPermitCheck(false);
      }
    }

    //mare
    if (formModel.isMareRegistered === "Registered") {
      if (formModel.horseMareReg === "") {
        setErrorHorseMareReg(true);
        valid = false;
        horseMareRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseMareReg(false);
      }
    }

    if (formModel.isMareRegistered === "Unregistered") {
      if (formModel.horseNameEnMareUnReg === "") {
        setErrorHorseNameEnMareUnReg(true);
        valid = false;
        horseNameEnMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseNameEnMareUnReg(false);
      }

      if (formModel.horseNameArMareUnReg === "") {
        setErrorHorseNameArMareUnReg(true);
        valid = false;
        horseNameArMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseNameArMareUnReg(false);
      }

      if (formModel.damEnMareUnReg === "") {
        setErrorDamEnMareUnReg(true);
        valid = false;
        damEnMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorDamEnMareUnReg(false);
      }

      if (formModel.damArMareUnReg === "") {
        setErrorDamArMareUnReg(true);
        valid = false;
        damArMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorDamArMareUnReg(false);
      }

      if (formModel.sireEnMareUnReg === "") {
        setErrorSireEnMareUnReg(true);
        valid = false;
        sireEnMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorSireEnMareUnReg(false);
      }

      if (formModel.sireArMareUnReg === "") {
        setErrorSireArMareUnReg(true);
        valid = false;
        sireArMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorSireArMareUnReg(false);
      }

      if (formModel.breederNameEnMareUnReg === "") {
        setErrorBreederNameEnMareUnReg(true);
        valid = false;
        breederNameEnMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorBreederNameEnMareUnReg(false);
      }

      if (formModel.breederNameArMareUnReg === "") {
        setErrorBreederNameArMareUnReg(true);
        valid = false;
        breederNameArMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorBreederNameArMareUnReg(false);
      }

      if (formModel.horseAddressMareUnReg === "") {
        setErrorHorseAddressMareUnReg(true);
        valid = false;
        horseAddressMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseAddressMareUnReg(false);
      }

      if (formModel.currentRegNoMareUnReg === "") {
        setErrorCurrentRegNoMareUnReg(true);
        valid = false;
        currentRegNoMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorCurrentRegNoMareUnReg(false);
      }

      if (formModel.colorMareUnReg === "") {
        setErrorColorMareUnReg(true);
        valid = false;
        colorMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorColorMareUnReg(false);
      }

      if (formModel.originMareUnReg === "") {
        setErrorOriginMareUnReg(true);
        valid = false;
        originMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorOriginMareUnReg(false);
      }

      if (formModel.horseCountryMareUnReg === "") {
        setErrorHorseCountryMareUnReg(true);
        valid = false;
        horseCountryMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorHorseCountryMareUnReg(false);
      }

      if (formModel.birthDateMareUnReg === null) {
        setErrorBirthDateMareUnReg(true);
        valid = false;
        birthDateMareUnRegRef?.current?.focus();
        return;
      }
      else {
        setErrorBirthDateMareUnReg(false);
      }

      // if (!formModel.embryoIsImported) {
      //   setErrorEmbryoIsImportedCheck(true);
      //   valid = false;
      // }
      // else {
      //   setErrorEmbryoIsImportedCheck(false);
      // }
    }

    //covering date
    if (formModel.coveringDate == null) {
      setErrorCoveringDate(true);
      valid = false;
      coveringDateRef?.current?.focus();
      return;
    }
    else {
      setErrorCoveringDate(false);
    }

    //Upload Documents
    if (formModel.typeOfCovering === "ArtificialInseminationLocal" && formModel.semenDoseOwnershipReferenceNumber === "") {
      setErrorSemenDoseOwnershipReferenceNumber(true);
      valid = false;
      semenDoseOwnershipReferenceNumberRef?.current?.focus();
      return;
    }
    else {
      setErrorSemenDoseOwnershipReferenceNumber(false);
    }

    if (formModel.typeOfCovering === "ArtificialInseminationImported") {

      if (formModel.inseminationImportedType === "Certificate"
        && formModel.documentBase64ImportedSemenCertificate === ""
        && importedSemenCertificateAttachmentFile.length === 0
      ) {
        setErrorImportedSemenCertificate(true);
        valid = false;
        importedSemenCertificateRef?.current?.focus();
        return;
      }
      else {
        setErrorImportedSemenCertificate(false);
      }

      if (formModel.inseminationImportedType === "SemenDoseImported" && formModel.semenDoseImportedReferenceNumber === "") {
        setErrorSemenDoseImportedReferenceNumber(true);
        valid = false;
        semenDoseImportedReferenceNumberRef?.current?.focus();
        return;
      }
      else {
        setErrorSemenDoseImportedReferenceNumber(false);
      }

      // if (!formModel.breedingHappendAbroad) {
      //   setErrorBreedingHappendAbroadCheck(true);
      //   valid = false;
      // }
      // else {
      //   setErrorBreedingHappendAbroadCheck(false);
      // }

      // if (!formModel.embryoIsImported) {
      //   setErrorEmbryoIsImportedCheck(true);
      //   valid = false;
      // }
      // else {
      //   setErrorEmbryoIsImportedCheck(false);
      // }
    }

    if (!formModel.embryoIsImported && (formModel.isMareRegistered === "Unregistered" || formModel.breedingHappendAbroad)) {
      setErrorEmbryoIsImportedCheck(true);
      valid = false;
      embryoIsImportedCheckRef?.current?.focus();
      return;
    }
    else {
      setErrorEmbryoIsImportedCheck(false);
    }

    if (
      formModel.breedingHappendAbroad
      && (breedingHappendAbroadAttachmentFile.length === 0 && formModel.documentBase64BreedingHappendAbroad === "")
    ) {
      setErrorBreedingHappendAbroad(true);
      valid = false;
      breedingHappendAbroadRef?.current?.focus();
      return;
    }
    else {
      setErrorBreedingHappendAbroad(false);
    }

    if (
      formModel.embryoIsImported
      && (embryoIsImportedAttachmentFile.length === 0 && formModel.documentBase64EmbryoIsImported === "")
    ) {
      setErrorEmbryoIsImported(true);
      valid = false;
      embryoIsImportedRef?.current?.focus();
      return;
    }
    else {
      setErrorEmbryoIsImported(false);
    }

    if (
      (
        //formModel.isStallionRegistered === "Unregistered" ||
        formModel.stallionOwnerSignedPermit)
      && (stallionOwnerSignedPermitAttachmentFile.length === 0 && formModel.documentBase64StallionOwnerSignedPermit === "")
    ) {
      setErrorStallionOwnerSignedPermit(true);
      valid = false;

      stallionOwnerSignedPermitRef?.current?.focus();
      return;
    }
    else {
      setErrorStallionOwnerSignedPermit(false);
    }

    if (formModel.embryoTransfer) {

      if (formModel.recipientMare === "ArabianRegistered" && formModel.recipientMareId === "") {
        setErrorRecipientMare(true);
        valid = false;
        recipientMareRef?.current?.focus();
        return;
      }
      else {
        setErrorRecipientMare(false);
      }

      if (formModel.recipientMare === "ArabianNotRegistered" || formModel.recipientMare === "NotArabian") {

        if (formModel.recipientMareNameEnUnReg === "") {
          setErrorRecipientMareNameEnUnReg(true);
          valid = false;
          recipientMareNameEnUnRegRef?.current?.focus();
          return;
        }
        else {
          setErrorRecipientMareNameEnUnReg(false);
        }

        if (formModel.recipientMareNameArUnReg === "") {
          setErrorRecipientMareNameArUnReg(true);
          valid = false;
          recipientMareNameArUnRegRef?.current?.focus();
          return;
        }
        else {
          setErrorRecipientMareNameArUnReg(false);
        }

        if (formModel.microchipNo === "") {
          setErrorMicrochipNo(true);
          valid = false;
          microchipNoRef?.current?.focus();
          return;
        }
        else {
          setErrorMicrochipNo(false);
        }
      }

      if (
        formModel.transferDate == null
      ) {
        setErrorTransferDate(true);
        valid = false;
        transferDateRef?.current?.focus();
        return;
      }
      else {
        setErrorTransferDate(false);
      }

      if (
        formModel.veterinarian === ""
      ) {
        setErrorVeterinarian(true);
        valid = false;
        veterinarianRef?.current?.focus();
        return;
      }
      else {
        setErrorVeterinarian(false);
      }

      if (
        formModel.transferAddress === ""
      ) {
        setErrorTransferAddress(true);
        valid = false;
        transferAddressRef?.current?.focus();
        return;
      }
      else {
        setErrorTransferAddress(false);
      }

      if (
        veterinarianReportAttachmentFile.length === 0 && formModel.documentBase64VeterinarianReport === ""
      ) {
        setErrorVeterinarianReport(true);
        valid = false;
        veterinarianReportRef?.current?.focus();
        return;
      }
      else {
        setErrorVeterinarianReport(false);
      }
    }

    //terms
    if (
      !checkedItems.option1
      || !checkedItems.option3
      || (!checkedItems.option2 && (
        (formModel.isStallionRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionReg), 2))
        || (formModel.isStallionRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionUnReg), 2))
        || (formModel.isMareRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareReg), 3))
        || (formModel.isMareRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareUnReg), 3))
      ))
      //|| (!checkedItems.option3 && (formModel.horseMareRegLessThanOneWeek == true || checkLastChildrenOfHorseMareRegLessThanOneWeekData == true))
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );

      valid = false;
    }

    if (
      formModel.horseMareRegLessThanOneWeek
      ||
      (
        formModel.isMareRegistered === "Registered"
        && Object.keys(checkLastChildrenOfHorseMareRegLessThanOneWeekData).length > 0
        && checkLastChildrenOfHorseMareRegLessThanOneWeekData.lastChildren === true
      )
    ) {
      toast.error(
        translate("web.coveringCertificateNote3", "Covering Certificate Note 2")
      );

      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    _isOnlinePayment.current = isOnlinePayment;
    _status.current = status;

    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName === "PendingPayment") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=11&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        // let semenDoseOwnershipReferenceNumber = "";

        // if (formModel.typeOfCovering === "ArtificialInseminationLocal") {
        //   semenDoseOwnershipReferenceNumber = formModel.semenDoseOwnershipReferenceNumber;
        // }

        let isViolated = false;

        if (
          (formModel.isStallionRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionReg), 2))
          || (formModel.isStallionRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionUnReg), 2))
          || (formModel.isMareRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareReg), 3))
          || (formModel.isMareRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareUnReg), 3))
        ) {
          isViolated = true;
        }

        let params = {
          //id: id,
          id: id == null ? '' : id,
          serviceDefinitionId: serviceId,
          status: status,
          isViolated: isViolated,

          eventName: formModel.eventName,
          isStallionRegistered: formModel.isStallionRegistered,
          notStallionOwner: formModel.notStallionOwner,
          horseStallion: formModel.notStallionOwner ? formModel.horseNotStallion : formModel.horseStallionReg,
          horseNameEnStallionUnReg: formModel.horseNameEnStallionUnReg == null ? "" : formModel.horseNameEnStallionUnReg,
          horseNameArStallionUnReg: formModel.horseNameArStallionUnReg == null ? "" : formModel.horseNameArStallionUnReg,
          damEnStallionUnReg: formModel.damEnStallionUnReg == null ? "" : formModel.damEnStallionUnReg,
          damArStallionUnReg: formModel.damArStallionUnReg == null ? "" : formModel.damArStallionUnReg,
          sireEnStallionUnReg: formModel.sireEnStallionUnReg == null ? "" : formModel.sireEnStallionUnReg,
          sireArStallionUnReg: formModel.sireArStallionUnReg == null ? "" : formModel.sireArStallionUnReg,

          ownerNameEnStallionUnReg: formModel.ownerNameEnStallionUnReg == null ? "" : formModel.ownerNameEnStallionUnReg,
          ownerNameArStallionUnReg: formModel.ownerNameArStallionUnReg == null ? "" : formModel.ownerNameArStallionUnReg,
          breederNameEnStallionUnReg: formModel.breederNameEnStallionUnReg == null ? "" : formModel.breederNameEnStallionUnReg,
          breederNameArStallionUnReg: formModel.breederNameArStallionUnReg == null ? "" : formModel.breederNameArStallionUnReg,

          horseAddressStallionUnReg: formModel.horseAddressStallionUnReg == null ? "" : formModel.horseAddressStallionUnReg,
          currentRegNoStallionUnReg: formModel.currentRegNoStallionUnReg == null ? "" : formModel.currentRegNoStallionUnReg,
          uelnStallionUnReg: formModel.uelnStallionUnReg == null ? "" : formModel.uelnStallionUnReg,

          colorStallionUnReg: formModel.colorStallionUnReg != null ? formModel.colorStallionUnReg : "",
          originStallionUnReg: formModel.originStallionUnReg != null ? formModel.originStallionUnReg : "",
          horseCountryStallionUnReg: formModel.horseCountryStallionUnReg != null ? formModel.horseCountryStallionUnReg : "",
          birthDateStallionUnReg: formModel.birthDateStallionUnReg != null ? dayjs(formModel.birthDateStallionUnReg).format("DD/MM/YYYY") : '',

          isMareRegistered: formModel.isMareRegistered,
          horseMareReg: formModel.horseMareReg,
          horseNameEnMareUnReg: formModel.horseNameEnMareUnReg == null ? "" : formModel.horseNameEnMareUnReg,
          horseNameArMareUnReg: formModel.horseNameArMareUnReg == null ? "" : formModel.horseNameArMareUnReg,


          damEnMareUnReg: formModel.damEnMareUnReg == null ? "" : formModel.damEnMareUnReg,
          damArMareUnReg: formModel.damArMareUnReg == null ? "" : formModel.damArMareUnReg,
          sireEnMareUnReg: formModel.sireEnMareUnReg == null ? "" : formModel.sireEnMareUnReg,
          sireArMareUnReg: formModel.sireArMareUnReg == null ? "" : formModel.sireArMareUnReg,
          breederNameEnMareUnReg: formModel.breederNameEnMareUnReg == null ? "" : formModel.breederNameEnMareUnReg,
          breederNameArMareUnReg: formModel.breederNameArMareUnReg == null ? "" : formModel.breederNameArMareUnReg,
          horseAddressMareUnReg: formModel.horseAddressMareUnReg == null ? "" : formModel.horseAddressMareUnReg,
          currentRegNoMareUnReg: formModel.currentRegNoMareUnReg == null ? "" : formModel.currentRegNoMareUnReg,
          uelnMareUnReg: formModel.uelnMareUnReg == null ? "" : formModel.uelnMareUnReg,

          colorMareUnReg: formModel.colorMareUnReg != null ? formModel.colorMareUnReg : "",
          originMareUnReg: formModel.originMareUnReg != null ? formModel.originMareUnReg : "",
          horseCountryMareUnReg: formModel.horseCountryMareUnReg != null ? formModel.horseCountryMareUnReg : "",
          birthDateMareUnReg: formModel.birthDateMareUnReg != null ? dayjs(formModel.birthDateMareUnReg).format("DD/MM/YYYY") : '',

          typeOfCovering: formModel.typeOfCovering,
          coveringDate: dayjs(formModel.coveringDate).format("DD/MM/YYYY"),

          breedingHappendAbroad: formModel.breedingHappendAbroad,
          embryoIsImported: formModel.embryoIsImported,
          stallionOwnerSignedPermit: formModel.stallionOwnerSignedPermit,

          breedingHappendAbroadAttachmentFile: formModel.breedingHappendAbroadAttachmentFile,
          embryoIsImportedAttachmentFile: formModel.embryoIsImportedAttachmentFile,
          stallionOwnerSignedPermitAttachmentFile: formModel.stallionOwnerSignedPermitAttachmentFile,
          importedSemenCertificateAttachmentFile: formModel.importedSemenCertificateAttachmentFile,

          ownerId: getUserID(),
          ownerIdNotStallionReg: formModel.ownerIdNotStallionReg != null ? formModel.ownerIdNotStallionReg : "",

          coveringCertificateId: formModel.coveringCertificateId,
          referenceNumberSemenCollectionCertificate: formModel.semenDoseOwnershipReferenceNumber == null ? "" : formModel.semenDoseOwnershipReferenceNumber,

          inseminationImportedType: formModel.inseminationImportedType,
          importedReferenceNumberSemen: formModel.semenDoseImportedReferenceNumber == null ? "" : formModel.semenDoseImportedReferenceNumber,
          embryoTransfer: formModel.embryoTransfer,
          recipientMare: formModel.recipientMare,
          recipientMareId: formModel.recipientMareId != null ? formModel.recipientMareId : '',
          recipientMareNameEnNotRegistered: formModel.recipientMareNameEnUnReg == null ? "" : formModel.recipientMareNameEnUnReg,
          recipientMareNameArNotRegistered: formModel.recipientMareNameArUnReg == null ? "" : formModel.recipientMareNameArUnReg,
          microchipNo: formModel.microchipNo == null ? "" : formModel.microchipNo,

          transferDateString: formModel.transferDate != null ? dayjs(formModel.transferDate).format("DD/MM/YYYY") : '',

          veterinarian: formModel.veterinarian == null ? "" : formModel.veterinarian,
          transferAddress: formModel.transferAddress == null ? "" : formModel.transferAddress,

          veterinarianReportAttachmentFile: formModel.veterinarianReportAttachmentFile,

          createdBy: localStorage.getItem("EAHSUserID"),
          date: new Date().toLocaleDateString(),
        };

        //if (action == null) {
        dispatch(FetchCreateCoveringCertificateServiceRequestthunk(params));
        //} else if (action === "edit" || formModel.eventName === "SentBack") {
        //dispatch(FetchUpdateCoveringCertificateServiceRequestthunk(params));
        //}
      }
      else {
        toast.error(
          translate("web.completeTheRequiredData", "Complete The Required Data")
        );
      }
    }
  };

  const handleUploadBreedingHappendAbroadChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          breedingHappendAbroadAttachmentFile: e.target.files[0],
        });

        setBreedingHappendAbroadAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadStallionOwnerSignedPermitChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          stallionOwnerSignedPermitAttachmentFile: e.target.files[0],
        });

        setStallionOwnerSignedPermitAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadEmbryoIsImportedChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          embryoIsImportedAttachmentFile: e.target.files[0],
        });

        setEmbryoIsImportedAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadImportedSemenCertificateChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          importedSemenCertificateAttachmentFile: e.target.files[0],
        });

        setImportedSemenCertificateAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadVeterinarianReportChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          veterinarianReportAttachmentFile: e.target.files[0],
        });

        setVeterinarianReportAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horseStallionReg: "",
      horseStallionRegDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },
      regNoStallionReg: "",
      horseNameStallionReg: "",
      originStallionReg: "",
      breederStallionReg: "",
      genderStallionReg: "",
      colorStallionReg: "",
      birthDateStallionReg: "",
      sireStallionReg: "",
      damStallionReg: "",

      horseMareReg: "",
      horseMareRegDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },
      originMareReg: "",
      regNoMareReg: "",
      horseNameMareReg: "",
      genderMareReg: "",
      colorMareReg: "",
      birthDateMareReg: "",
      sireMareReg: "",
      damMareReg: "",
      breederMareReg: "",

    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  const closeModal = () => setOpenPopup(false);

  const closeModalCancel = () => setOpenPopupCancel(false);

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName == "CoveringCertificates" || x.permissionName == "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const handelApproveRejectRequest = (status) => {
    _status.current = status;

    let params = {
      id: id,
      status: status,
      comment: formModel.stallionComment,
    };

    dispatch(FetchApproveRejectRequestCoveringCertificatethunk(params));
  };

  const handelCancelRequest = () => {
    let params = {
      serviceId: id,
      ownerId: localStorage.getItem("EAHSUserID"),
    };

    dispatch(FetchCancelRequestthunk(params));
  };

  // async search
  const promiseOptions = (searchKeyword) =>
    new Promise((resolve) => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(async () => {
        const response = await axiosInstance.post(
          "/ServicesRequests/GetRegisteredHorses",
          {
            lang: localStorage.getItem("eahsLang") ?? "en",
            searchValue: searchKeyword,
          }
        );

        const data = await response.data.horsesList.filter(item => item.gender === "Stallion");

        const options = data.map((item) => ({
          label: item.registerNumber + " - " + item.name,
          value: item.id,
          details: item,
        }));
        resolve(options);
      }, 1000);

      setTimeoutId(id);
    });

  const dismissAll = () => toast.dismiss();

  const handelDisabledButton = () => {
    if (
      action === "view"
      || (action === "approve" && formModel.eventName !== "SentBack")
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelDisabledBreedingHappendAbroadUpload = () => {
    if (
      (action === "approve" && formModel.eventName !== "SentBack")
      || action === "view"
    ) {
      return true;
    }
    else {
      if (formModel.breedingHappendAbroad) {
        return false;
      }
      else {
        return true;
      }
    }
  };

  const handelDisabledEmbryoIsImportedUpload = () => {
    if (
      (action === "approve" && formModel.eventName !== "SentBack")
      || action === "view"
    ) {
      return true;
    }
    else {
      if (formModel.embryoIsImported) {
        return false;
      }
      else {
        return true;
      }
    }
  };

  const handelDisabledStallionOwnerSignedPermitUpload = () => {
    if (
      (action === "approve" && formModel.eventName !== "SentBack")
      || action === "view"
    ) {
      return true;
    }
    else {
      if (formModel.stallionOwnerSignedPermit) {
        return false;
      }
      else {
        return true;
      }
    }
  };

  const handelShowGeneralComments = () => {
    if (
      !!formModel.comment
      || !!formModel.stallionComment
      || action === "approve"
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowStallionComment = () => {
    if (
      (action === "approve" && ((formModel.eventName === "SentBack" && !!formModel.stallionComment) || (formModel.eventName !== "SentBack")))
      || (action === "edit" && formModel.eventName === "StallionRejected")
      || (action === "view" && !!formModel.stallionComment)
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  const handelDisabledStallionComment = () => {
    if (
      action === "approve"
      && formModel.eventName !== "SentBack"
      && formModel.requestsType === "Portal"
    ) {
      return false;
    }
    else {
      return true;
    }
  };

  const handelShowCoveringCertificateNote1 = () => {
    if (
      (formModel.isStallionRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionReg), 2))
      || (formModel.isStallionRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionUnReg), 2))
      || (formModel.isMareRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareReg), 3))
      || (formModel.isMareRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareUnReg), 3))
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelDisabledDraftButton = () => {
    if (action == null || action === "edit") {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowSubmitButton = () => {
    if (
      (
        (action == null || action === "edit")
        && getServiceDefinitionByIdData.serviceFees === 0
        && (formModel.coveringDate == null || (formModel.coveringDate != null && CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration)))
      )

      || formModel.eventName === "SentBack"
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowGeneralPayButtons = () => {
    if (
      (
        action == null
        || action === "edit"
        || (action === "approve" && formModel.eventName === "PendingPayment")
      )
      &&
      (
        getServiceDefinitionByIdData.serviceFees > 0
        || (formModel.coveringDate != null && !CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration))
      )
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowPayButton = () => {
    if (
      formModel.violationPayment === ""
      || formModel.violationPayment === null
      || (
        getServiceDefinitionByIdData.serviceFees > 0
        && (formModel.paymentStatus === "" || formModel.paymentStatus === "Pendding")
      )
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowPayLaterButton = () => {
    if (
      userProfile?.participateWithoutPayment === true
      && (
        formModel.violationPayment === ""
        || formModel.violationPayment === null
        || (
          getServiceDefinitionByIdData.serviceFees > 0
          && (formModel.paymentStatus === "" || formModel.paymentStatus === "Pendding")
        )
      )
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  // const handelShowSubmitButton = () => {
  //   if (
  //     (formModel.eventName === "SentBack")
  //     || (formModel.eventName === "SentBack"
  //       &&
  //       (
  //         (formModel.coveringDate != null && CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration) && !!formModel.violationPayment)
  //         ||
  //         (formModel.coveringDate != null && !CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration))
  //       )
  //     )
  //     || ((formModel.eventName === "" || formModel.eventName === "Draft") &&
  //       (
  //         (getServiceDefinitionByIdData.serviceFees > 0)
  //         ||
  //         (formModel.coveringDate == null || (formModel.coveringDate != null && CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration)))
  //       ))
  //   ) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // };

  const handelShowCancelButton = () => {
    if (action === "edit" && formModel.eventName !== "Draft") {
      return true;
    }
    else {
      return false;
    }
  };

  const handelShowRejectApproveButtons = () => {
    if (
      action === "approve"
      && formModel.eventName !== "SentBack"
      && formModel.eventName !== "PendingPayment"
    ) {
      return true;
    }
    else {
      return false;
    }
  };

  const handelDisabledRejectButton = () => {
    if (
      formModel.requestsType === "Portal"
      && !!formModel.stallionComment
    ) {
      return false;
    }
    else {
      return true;
    }
  };

  const handelDisabledEmbryoTransferButton = () => {
    if (
      action === "view"
      || (action === "approve" && formModel.eventName !== "SentBack")
    ) {
      return true;
    }
    else {
      if (
        formModel.isMareRegistered === "Unregistered"
        ||
        (
          formModel.isMareRegistered === "Registered"
          && Object.keys(checkLastChildrenOfHorseMareRegLessThanOneWeekData).length > 0
          && checkLastChildrenOfHorseMareRegLessThanOneWeekData.isEmbryoTransfer === true
        )
        || formModel.embryoTransfer
      ) {
        return false;
      }
      else {
        return true;
      }
    }
  };

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, [id]);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    dispatch(FetchCountriesthunk({ langauge: currentLanguage }));
    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));

  }, [currentLanguage]);

  useEffect(() => {
    if (action != null) {
      loadd.current = true;
    }

    if (getServicesRequestByIdData != null &&
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
      //&&checkAnotherOwner.current
    ) {
      setFormModel({
        ...formModel,
        isStallionRegistered: getServicesRequestByIdData.coveringCertificates.isStallionRegistered,
        notStallionOwner: getServicesRequestByIdData.coveringCertificates.notStallionOwner,
        stallionOwnerSignedPermitPart: getServicesRequestByIdData.coveringCertificates.notStallionOwner === true || getServicesRequestByIdData.coveringCertificates.isStallionRegistered === "Unregistered",
        isMareRegistered: getServicesRequestByIdData.coveringCertificates.isMareRegistered,
        typeOfCovering: getServicesRequestByIdData.coveringCertificates.typeOfCovering,
        coveringDate: getServicesRequestByIdData.coveringCertificates.coveringDate != null ? dayjs(getServicesRequestByIdData.coveringCertificates.coveringDate).format("YYYY-MM-DD") : null,
        breedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.breedingHappendAbroad,
        embryoIsImported: getServicesRequestByIdData.coveringCertificates.embryoIsImported,
        stallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.stallionOwnerSignedPermit,
        filePathBreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.filePathBreedingHappendAbroad,
        fileTypeBreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.fileTypeBreedingHappendAbroad,
        fileNameBreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.fileNameBreedingHappendAbroad,
        documentBase64BreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.documentBase64BreedingHappendAbroad,

        filePathEmbryoIsImported: getServicesRequestByIdData.coveringCertificates.filePathEmbryoIsImported,
        fileTypeEmbryoIsImported: getServicesRequestByIdData.coveringCertificates.fileTypeEmbryoIsImported,
        fileNameEmbryoIsImported: getServicesRequestByIdData.coveringCertificates.fileNameEmbryoIsImported,
        documentBase64EmbryoIsImported: getServicesRequestByIdData.coveringCertificates.documentBase64EmbryoIsImported,

        filePathStallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.filePathStallionOwnerSignedPermit,
        fileTypeStallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.fileTypeStallionOwnerSignedPermit,
        fileNameStallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.fileNameStallionOwnerSignedPermit,
        documentBase64StallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.documentBase64StallionOwnerSignedPermit,

        filePathImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.filePathImportedSemenCertificate,
        fileTypeImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.fileTypeImportedSemenCertificate,
        fileNameImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.fileNameImportedSemenCertificate,
        documentBase64ImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.documentBase64ImportedSemenCertificate,

        stallionComment: getServicesRequestByIdData.coveringCertificates.stallionComment != null ? getServicesRequestByIdData.coveringCertificates.stallionComment : "",
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        coveringCertificateId: getServicesRequestByIdData.coveringCertificates.coveringCertificateId,
        horseMareRegLessThanOneWeek: getServicesRequestByIdData.coveringCertificates.horseMareRegLessThanOneWeek,

        horseStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id ?? "",
        horseStallionRegDrop: {
          label:
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.regNo +
            " - " +
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.name,
          value: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id,
        },

        horseNotStallion: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id,
        horseNotStallionDrop: {
          label:
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.regNo +
            " - " +
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.name,
          value: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id,
        },

        ownerIdNotStallionReg: getServicesRequestByIdData.coveringCertificates.ownerIdNotStallionReg,
        ownerNameStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.ownerName,
        ownerEmailStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.ownerEmail,
        ownerMobileStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.ownerMobile,
        originStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.origin,
        breederStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.breeder,
        genderStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.gender,
        colorStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.color,
        birthDateStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.birthDate,
        sireStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.sire,
        damStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.dam,
        horseNameEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseNameEnStallionUnReg,
        horseNameArStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseNameArStallionUnReg,
        damEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.damEnStallionUnReg,
        damArStallionUnReg: getServicesRequestByIdData.coveringCertificates.damArStallionUnReg,
        sireEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.sireEnStallionUnReg,
        sireArStallionUnReg: getServicesRequestByIdData.coveringCertificates.sireArStallionUnReg,
        ownerNameEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameEnStallionUnReg,
        ownerNameArStallionUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameArStallionUnReg,
        breederNameEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.breederNameEnStallionUnReg,
        breederNameArStallionUnReg: getServicesRequestByIdData.coveringCertificates.breederNameArStallionUnReg,
        horseAddressStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseAddressStallionUnReg,
        currentRegNoStallionUnReg: getServicesRequestByIdData.coveringCertificates.currentRegNoStallionUnReg,
        uelnStallionUnReg: getServicesRequestByIdData.coveringCertificates.uelnStallionUnReg,
        colorStallionUnReg: getServicesRequestByIdData.coveringCertificates.colorStallionUnReg,
        colorStallionUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.colorNameStallionUnReg,
          value: getServicesRequestByIdData.coveringCertificates.colorStallionUnReg,
        },

        originStallionUnReg: getServicesRequestByIdData.coveringCertificates.originStallionUnReg,
        originStallionUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.originNameStallionUnReg,
          value: getServicesRequestByIdData.coveringCertificates.originStallionUnReg,
        },

        horseCountryStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseCountryStallionUnReg,
        horseCountryStallionUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.horseCountryNameStallionUnReg,
          value: getServicesRequestByIdData.coveringCertificates.horseCountryStallionUnReg,
        },

        birthDateStallionUnReg: getServicesRequestByIdData.coveringCertificates.birthDateStallionUnReg != null ? dayjs(getServicesRequestByIdData.coveringCertificates.birthDateStallionUnReg).format("YYYY-MM-DD") : null,

        horseMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.id ?? "",
        horseMareRegDrop: {
          label:
            getServicesRequestByIdData.coveringCertificates.horseMareVM?.regNo +
            " - " +
            getServicesRequestByIdData.coveringCertificates.horseMareVM?.name,
          value: getServicesRequestByIdData.coveringCertificates.horseMareVM?.id,
        },

        originMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.origin,
        breederMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.breeder,
        genderMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.gender,
        colorMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.color,
        birthDateMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.birthDate,
        sireMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.sire,
        damMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.dam,
        horseNameEnMareUnReg: getServicesRequestByIdData.coveringCertificates.horseNameEnMareUnReg,
        horseNameArMareUnReg: getServicesRequestByIdData.coveringCertificates.horseNameArMareUnReg,
        damEnMareUnReg: getServicesRequestByIdData.coveringCertificates.damEnMareUnReg,
        damArMareUnReg: getServicesRequestByIdData.coveringCertificates.damArMareUnReg,
        sireEnMareUnReg: getServicesRequestByIdData.coveringCertificates.sireEnMareUnReg,
        sireArMareUnReg: getServicesRequestByIdData.coveringCertificates.sireArMareUnReg,
        ownerNameEnMareUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameEnMareUnReg,
        ownerNameArMareUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameArMareUnReg,
        breederNameEnMareUnReg: getServicesRequestByIdData.coveringCertificates.breederNameEnMareUnReg,
        breederNameArMareUnReg: getServicesRequestByIdData.coveringCertificates.breederNameArMareUnReg,
        horseAddressMareUnReg: getServicesRequestByIdData.coveringCertificates.horseAddressMareUnReg,
        currentRegNoMareUnReg: getServicesRequestByIdData.coveringCertificates.currentRegNoMareUnReg,
        uelnMareUnReg: getServicesRequestByIdData.coveringCertificates.uelnMareUnReg,

        colorMareUnReg: getServicesRequestByIdData.coveringCertificates.colorMareUnReg,
        colorMareUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.colorNameMareUnReg,
          value: getServicesRequestByIdData.coveringCertificates.colorMareUnReg,
        },

        originMareUnReg: getServicesRequestByIdData.coveringCertificates.originMareUnReg,
        originMareUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.originNameMareUnReg,
          value: getServicesRequestByIdData.coveringCertificates.originMareUnReg,
        },

        horseCountryMareUnReg: getServicesRequestByIdData.coveringCertificates.horseCountryMareUnReg,
        horseCountryMareUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.horseCountryNameMareUnReg,
          value: getServicesRequestByIdData.coveringCertificates.horseCountryMareUnReg,
        },

        birthDateMareUnReg: getServicesRequestByIdData.coveringCertificates.birthDateMareUnReg != null ? dayjs(getServicesRequestByIdData.coveringCertificates.birthDateMareUnReg).format("YYYY-MM-DD") : null,
        semenDoseOwnershipReferenceNumber: getServicesRequestByIdData.coveringCertificates.referenceNumberSemenCollectionCertificate,
        violationPayment: getServicesRequestByIdData.coveringCertificates.violationPayment,

        inseminationImportedType: getServicesRequestByIdData.coveringCertificates.inseminationImportedType,
        semenDoseImportedReferenceNumber: getServicesRequestByIdData.coveringCertificates.importedReferenceNumberSemen,
        embryoTransfer: getServicesRequestByIdData.coveringCertificates.embryoTransfer,
        recipientMare: getServicesRequestByIdData.coveringCertificates.recipientMare,
        recipientMareId: getServicesRequestByIdData.coveringCertificates.recipientMareId,
        recipientMareIdDrop: {
          label: getServicesRequestByIdData.coveringCertificates.recipientMareName,
          value: getServicesRequestByIdData.coveringCertificates.recipientMareId,
        },

        recipientMareNameEnUnReg: getServicesRequestByIdData.coveringCertificates.recipientMareNameEnNotRegistered,
        recipientMareNameArUnReg: getServicesRequestByIdData.coveringCertificates.recipientMareNameArNotRegistered,
        microchipNo: getServicesRequestByIdData.coveringCertificates.microchipNo,
        transferDate: getServicesRequestByIdData.coveringCertificates.transferDate != null ? dayjs(getServicesRequestByIdData.coveringCertificates.transferDate).format("YYYY-MM-DD") : null,
        veterinarian: getServicesRequestByIdData.coveringCertificates.veterinarian,
        transferAddress: getServicesRequestByIdData.coveringCertificates.transferAddress,

        filePathVeterinarianReport: getServicesRequestByIdData.coveringCertificates.filePathVeterinarianReport,
        fileTypeVeterinarianReport: getServicesRequestByIdData.coveringCertificates.fileTypeVeterinarianReport,
        fileNameVeterinarianReport: getServicesRequestByIdData.coveringCertificates.fileNameVeterinarianReport,
        documentBase64VeterinarianReport: getServicesRequestByIdData.coveringCertificates.documentBase64VeterinarianReport,
      });

      debugger
      loadd.current = false;

      //another owner
      if (
        getServicesRequestByIdData.coveringCertificates.ownerId !==
        getServicesRequestByIdData.coveringCertificates.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.coveringCertificates.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //checkAnotherOwner.current = false;
        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.coveringCertificates.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.coveringCertificates.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.coveringCertificates.ownerId
          )[0].value,
        });
      }

      //checkAnotherOwner.current = false;

      //options
      setCheckedItems({
        option1: true,
        option2: true,
        option3: true,
      });
    }
  }, [
    getServicesRequestByIdData,
    //subOwnerProfilesByIdData,
  ]);

  useEffect(() => {
    //save
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "" || _status.current === "Approve") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "" && _status.current === "Draft") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id == null ? createCoveringCertificateServiceRequestData.id : id}&paymentType=11&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      if (toasterMessageServicesRequests !== "errorServiceDetails") {

        toast.error(
          translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
        );

        dispatch(resetToasterMessageServicesRequests());
      }

      else if (toasterMessageServicesRequests === "errorServiceDetails") {
        toast.error(
          translate("web.createServiceRequestFailed", "Service Request Failed")
        );

        dispatch(resetToasterMessageServicesRequests());

        setTimeout(() => {
          navigate(`/profile?activeTab=${"e_services"}`);
        }, 1000);
      }
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div key={id} className="container-fluid container-fluid-padding">

      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading || loadd.current
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl ">

          {/* convering certificate title */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>

          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName().registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          {/* stallion details */}
          <EahsForm title={translate("web.stallionDetails", "Stallion Details")}>
            {/* stallion radioServiceRequest buttons */}
            <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"stallionType"}
                  type={"radioServiceRequest"}
                  value={"Registered"}
                  label={`${translate("web.registered", "Registered")}-`}
                  onChange={(e) => handleFormChange(e, "stallionType")}
                  checked={
                    formModel.isStallionRegistered === "Registered"
                  }
                  disabled={handelDisabledButton()}
                />
              </div>

              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"stallionType"}
                  type={"radioServiceRequest"}
                  value={"Unregistered"}
                  label={`${translate("web.unregistered", "Unregistered")}-`}
                  onChange={(e) => handleFormChange(e, "stallionType")}
                  checked={
                    formModel.isStallionRegistered === "Unregistered"
                  }
                  disabled={handelDisabledButton()}
                />
              </div>
            </div>

            {//stallion content registered
              formModel.isStallionRegistered === "Registered" && (
                <div className="row mx-0 col-md-12 col-sm-12 mt-5 rtl">
                  <div className="col-md-12 col-sm-12 rtl p0">
                    <NetaqInput
                      type={"checkbox"}
                      label={translate(
                        "web.notStallionOwner",
                        "i'm not the stallion's owner"
                      )}
                      name="notStallionOwner"
                      checked={formModel.notStallionOwner}
                      onChange={(e) => handleFormChange(e, "notStallionOwner")}
                      disabled={handelDisabledButton()}
                    />
                  </div>

                  {!formModel.notStallionOwner && (
                    <div className="col-md-4 col-sm-12 mt-3">
                      <label>
                        {translate("web.horse", "Horse")}
                        <i className="colorred"> *</i>
                      </label>
                      <Select
                        options={[
                          {
                            label: translate("web.horse", "Horse"),
                            value: null,
                          },
                          ...(!!ownerHorsesDropDownData &&
                            ownerHorsesDropDownData.filter(q => q.gender === "Stallion").map((a) => ({
                              label: a.registerNumber + " - " + a.name,
                              value: a.id,
                            }))),
                        ]}
                        value={formModel.horseStallionRegDrop}
                        onChange={(e) => handleFormChange(e, "horseStallionReg")}
                        placeholder={translate("web.horse", "Horse")}
                        name={"horseStallionReg"}
                        isSearchable
                        noOptionsMessage={() =>
                          translate("web.noOptions", "No Options")
                        }
                        autoFocus={true}
                        key={"horseStallionReg"}
                        isDisabled={handelDisabledButton()}
                        ref={horseStallionRegRef}
                      />
                      {errorHorseStallionReg && (
                        <span style={{ color: "red" }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      )}
                    </div>
                  )}
                  {formModel.notStallionOwner && (
                    <div className="col-md-4 col-sm-12 mt-3">
                      <label>
                        {translate("web.horse", "Horse")}
                        <i className="colorred"> *</i>
                      </label>
                      <AsyncSelect
                        isClearable
                        loadOptions={promiseOptions}
                        defaultOptions={false} // Set to true to load initial options
                        onInputChange={(newValue) => setInputValue(newValue)}
                        inputValue={inputValue}
                        value={formModel.horseNotStallionDrop}
                        loadingMessage={() => translate("web.loading", "Loading")}
                        onChange={(e) => handleFormChange(e, "horseNotStallion")}
                        placeholder={
                          translate("web.typeToSearch", "Type To Search") + "..."
                        }
                        noOptionsMessage={() =>
                          translate("web.noOptions", "No Options")
                        }
                        key={"horseNotStallion"}
                        isDisabled={handelDisabledButton()}
                        ref={horseStallionRegRef}
                      />

                      {errorHorseStallionReg && (
                        <span style={{ color: "red" }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.origin", "Origin")}
                      name={"origin"}
                      type={"text"}
                      disabled={true}
                      value={formModel.originStallionReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.breeder", "Breeder")}
                      name={"breeder"}
                      type={"text"}
                      disabled={true}
                      value={formModel.breederStallionReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sex", "Sex")}
                      name={"gender"}
                      type={"text"}
                      disabled={true}
                      value={translate(
                        `web.${formModel.genderStallionReg?.toLowerCase()}`,
                        formModel.genderStallionReg
                      )}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.color", "Color")}
                      name={"color"}
                      type={"text"}
                      disabled={true}
                      value={formModel.colorStallionReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.birthDate", "Birth Date")}
                      name={"birthDate"}
                      type={"text"}
                      disabled={true}
                      value={
                        !!formModel.birthDateStallionReg
                          ? formModel.birthDateStallionReg &&
                          formatDate(formModel.birthDateStallionReg)
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sire", "Sire")}
                      name={"sire"}
                      type={"text"}
                      disabled={true}
                      value={formModel.sireStallionReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.dam", "Dam")}
                      name={"dam"}
                      type={"text"}
                      disabled={true}
                      value={formModel.damStallionReg}
                    />
                  </div>

                  {formModel.notStallionOwner && (
                    <>
                      <div
                        className="col-md-4 col-sm-12 mt-3">
                        <NetaqInput
                          label={translate("web.ownerName", "Owner Name")}
                          name={"ownerName"}
                          type={"text"}
                          disabled={true}
                          value={formModel.ownerNameStallionReg}
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 mt-3">
                        <NetaqInput
                          label={translate("web.email", "Email")}
                          name={"ownerEmail"}
                          type={"text"}
                          disabled={true}
                          value={formModel.ownerEmailStallionReg}
                        />
                      </div>
                      <div className="col-md-4 col-sm-12 mt-3">
                        <NetaqInput
                          label={translate("web.smsPhoneNo", "Mobile No")}
                          name={"ownerMobile"}
                          type={"text"}
                          disabled={true}
                          value={formModel.ownerMobileStallionReg}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}

            {//stallion content unregistered
              formModel.isStallionRegistered === "Unregistered" && (
                <div className="row mx-0 col-md-12 col-sm-12 mt-3 rtl">
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      ref={horseNameEnStallionUnRegRef}
                      label={translate("web.name", "Name") + " (EN)"}
                      name={"horseNameEnStallionUnReg"}
                      type={"text"}
                      onChange={(e) => handleFormChange(e, "horseNameEnStallionUnReg")}
                      value={formModel.horseNameEnStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                    />
                    {errorHorseNameEnStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      ref={horseNameArStallionUnRegRef}
                      label={translate("web.name", "Name") + " (AR)"}
                      name={"horseNameArStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.horseNameArStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                    />
                    {errorHorseNameArStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sire", "Sire") + " (EN)"}
                      name={"sireEnStallionUnReg"}
                      type={"text"}
                      onChange={(e) => handleFormChange(e, "sireEnStallionUnReg")}
                      value={formModel.sireEnStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={sireEnStallionUnRegRef}
                    />
                    {errorSireEnStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sire", "Sire") + " (AR)"}
                      name={"sireArStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.sireArStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={sireArStallionUnRegRef}
                    />
                    {errorSireArStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.dam", "Dam") + " (EN)"}
                      name={"damEnStallionUnReg"}
                      type={"text"}
                      onChange={(e) => handleFormChange(e, "damEnStallionUnReg")}
                      value={formModel.damEnStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={damEnStallionUnRegRef}
                    />
                    {errorDamEnStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.dam", "Dam") + ' (AR)'}
                      name={"damArStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.damArStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={damArStallionUnRegRef}
                    />
                    {errorDamArStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.ownerName", "Owner Name") + " (EN)"}
                      name={"ownerNameEnStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.ownerNameEnStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={ownerNameEnStallionUnRegRef}
                    />
                    {errorOwnerNameEnStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.ownerName", "Owner Name") + " (AR)"}
                      name={"ownerNameArStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.ownerNameArStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={ownerNameArStallionUnRegRef}
                    />
                    {errorOwnerNameArStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.breederName", "breederName") + " (EN)"}
                      name={"breederNameEnStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.breederNameEnStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={breederNameEnStallionUnRegRef}
                    />
                    {errorBreederNameEnStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.breederName", "breederName") + " (AR)"}
                      name={"breederNameArStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.breederNameArStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={breederNameArStallionUnRegRef}
                    />
                    {errorBreederNameArStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.horseAddress", "Horse Address")}
                      name={"horseAddressStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.horseAddressStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={horseAddressStallionUnRegRef}
                    />
                    {errorHorseAddressStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.regno", "Reg.No")}
                      name={"currentRegNoStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.currentRegNoStallionUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={currentRegNoStallionUnRegRef}
                    />
                    {errorCurrentRegNoStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.ueln", "UELN")}
                      name={"uelnStallionUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.uelnStallionUnReg}
                      disabled={handelDisabledButton()}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <label>
                      {translate("web.color", "Color")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.color", "Color"),
                          value: null,
                        },
                        ...(!!horseColorsData &&
                          horseColorsData.map((a) => ({
                            label: a.nameEn,
                            value: a.id,
                          }))),
                      ]}
                      value={formModel.colorStallionUnRegDrop}
                      onChange={(e) => handleFormChange(e, "colorStallionUnReg")}
                      placeholder={translate("web.color", "Color")}
                      name={"colorStallionUnReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      key={"colorStallionUnReg"}
                      isDisabled={handelDisabledButton()}
                      ref={colorStallionUnRegRef}
                    />
                    {errorColorStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <label>
                      {translate("web.origin", "Origin")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.chooseOrigin", "Choose Origin"),
                          value: null,
                        },
                        ...(!!countriesData &&
                          countriesData.map((a) => ({
                            label: a.text,
                            value: a.value,
                          }))),
                      ]}
                      value={formModel.originStallionUnRegDrop}
                      onChange={(e) => handleFormChange(e, "originStallionUnReg")}
                      placeholder={translate("web.chooseOrigin", "Choose Origin")}
                      name={"originStallionUnReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      key={"originStallionUnReg"}
                      isDisabled={handelDisabledButton()}
                      ref={originStallionUnRegRef}
                    />
                    {errorOriginStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <label>
                      {translate("web.horseCountry", "Horse Country")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.horseCountry", "Horse Country"),
                          value: null,
                        },
                        ...(!!countriesData &&
                          countriesData.map((a) => ({
                            label: a.text,
                            value: a.value,
                          }))),
                      ]}
                      value={formModel.horseCountryStallionUnRegDrop}
                      onChange={(e) => handleFormChange(e, "horseCountryStallionUnReg")}
                      placeholder={translate("web.horseCountry", "Horse Country")}
                      name={"horseCountryStallionUnReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      key={"horseCountryStallionUnReg"}
                      isDisabled={handelDisabledButton()}
                      ref={horseCountryStallionUnRegRef}
                    />
                    {errorHorseCountryStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3 service">
                    <NetaqInput
                      htmlTags={
                        formModel.birthDateStallionUnReg ?
                          <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                            {
                              dayjs(formModel.birthDateStallionUnReg).format("DD/MM/YYYY")
                            }
                          </p> :
                          <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                            DD/MM/YYYY
                          </p>
                      }
                      name={"birthDateStallionUnReg"}
                      type={"normalDateService"}
                      //onKeydown={(e) => e.preventDefault()}
                      label={translate("web.birthDate", "Birth Date")}
                      onChange={(e) => handleFormChange(e, "birthDateStallionUnReg")}
                      value={formModel.birthDateStallionUnReg}
                      required={true}
                      maxDate={dayjs().format("YYYY-MM-DD")}
                      disabled={handelDisabledButton()}
                      ref={birthDateStallionUnRegRef}
                    />
                    {errorBirthDateStallionUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>

                </div>
              )}
          </EahsForm>

          {/* mare details*/}
          <EahsForm title={translate("web.mareDetails", "Mare Details")}>
            {/* mare radioServiceRequest buttons */}
            <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"mareType"}
                  type={"radioServiceRequest"}
                  value={"Registered"}
                  label={translate("web.registered", "Registered")}
                  onChange={(e) => handleFormChange(e, "mareType")}
                  checked={formModel.isMareRegistered === "Registered"}
                  disabled={handelDisabledButton()}
                />
              </div>
              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"mareType"}
                  type={"radioServiceRequest"}
                  value={"Unregistered"}
                  label={translate("web.unregistered", "Unregistered")}
                  onChange={(e) => handleFormChange(e, "mareType")}
                  checked={formModel.isMareRegistered === "Unregistered"}
                  disabled={handelDisabledButton()}
                />
              </div>
            </div>
            {//mare content registered
              formModel.isMareRegistered === "Registered" && (
                <div className="row mx-0 col-md-12 col-sm-12 mt-5 rtl">
                  <div className="col-md-4 col-sm-12">
                    <label>
                      {translate("web.horse", "Horse")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.horse", "Horse"),
                          value: "",
                        },
                        ...(!!ownerHorsesDropDownData &&
                          ownerHorsesDropDownData.filter(q => q.gender === "Mare").map((a) => ({
                            label: a.registerNumber + " - " + a.name,
                            value: a.id,
                          }))),
                      ]}
                      value={formModel.horseMareRegDrop}
                      onChange={(e) => handleFormChange(e, "horseMareReg")}
                      placeholder={translate("web.horse", "Horse")}
                      name={"horseMareReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      autoFocus={true}
                      key={"horseMareReg"}
                      isDisabled={handelDisabledButton()}
                      ref={horseMareRegRef}
                    />
                    {errorHorseMareReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 ">
                    <NetaqInput
                      label={translate("web.origin", "Origin")}
                      name={"origin"}
                      type={"text"}
                      disabled={true}
                      value={formModel.originMareReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 ">
                    <NetaqInput
                      label={translate("web.breeder", "Breeder")}
                      name={"breeder"}
                      type={"text"}
                      disabled={true}
                      value={formModel.breederMareReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sex", "Sex")}
                      name={"gender"}
                      type={"text"}
                      disabled={true}
                      value={translate(
                        `web.${formModel.genderMareReg?.toLowerCase()}`,
                        formModel.genderMareReg
                      )}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.color", "Color")}
                      name={"color"}
                      type={"text"}
                      disabled={true}
                      value={formModel.colorMareReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.birthDate", "Birth Date")}
                      name={"birthDate"}
                      type={"text"}
                      disabled={true}
                      value={
                        !!formModel.birthDateMareReg
                          ? formModel.birthDateMareReg &&
                          formatDate(formModel.birthDateMareReg)
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sire", "Sire")}
                      name={"sire"}
                      type={"text"}
                      disabled={true}
                      value={formModel.sireMareReg}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.dam", "Dam")}
                      name={"dam"}
                      type={"text"}
                      disabled={true}
                      value={formModel.damMareReg}
                    />
                  </div>
                </div>
              )}

            {//mare content unregistered 
              formModel.isMareRegistered === "Unregistered" && (
                <div className="row mx-0 col-md-12 col-sm-12 mt-3 rtl">
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.name", "Name") + " (EN)"}
                      name={"horseNameEnMareUnReg"}
                      type={"text"}
                      onChange={(e) => handleFormChange(e, "horseNameEnMareUnReg")}
                      value={formModel.horseNameEnMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={horseNameEnMareUnRegRef}
                    />
                    {errorHorseNameEnMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.name", "Name") + " (AR)"}
                      name={"horseNameArMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.horseNameArMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={horseNameArMareUnRegRef}
                    />
                    {errorHorseNameArMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sire", "Sire") + " (EN)"}
                      name={"sireEnMareUnReg"}
                      type={"text"}
                      onChange={(e) => handleFormChange(e, "sireEnMareUnReg")}
                      value={formModel.sireEnMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={sireEnMareUnRegRef}
                    />
                    {errorSireEnMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.sire", "Sire") + " (AR)"}
                      name={"sireArMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.sireArMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={sireArMareUnRegRef}
                    />
                    {errorSireArMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.dam", "Dam") + " (EN)"}
                      name={"damEnMareUnReg"}
                      type={"text"}
                      onChange={(e) => handleFormChange(e, "damEnMareUnReg")}
                      value={formModel.damEnMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={damEnMareUnRegRef}
                    />
                    {errorDamEnMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.dam", "Dam") + " (AR)"}
                      name={"damArMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.damArMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={damArMareUnRegRef}
                    />
                    {errorDamArMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.breederName", "Breeder Name") + " (EN)"}
                      name={"breederNameEnMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.breederNameEnMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={breederNameEnMareUnRegRef}
                    />
                    {errorBreederNameEnMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.breederName", "Breeder Name") + " (AR)"}
                      name={"breederNameArMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.breederNameArMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={breederNameArMareUnRegRef}
                    />
                    {errorBreederNameArMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.horseAddress", "Horse Address")}
                      name={"horseAddressMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.horseAddressMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={horseAddressMareUnRegRef}
                    />
                    {errorHorseAddressMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.regno", "Reg.No")}
                      name={"currentRegNoMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.currentRegNoMareUnReg}
                      required={true}
                      disabled={handelDisabledButton()}
                      ref={currentRegNoMareUnRegRef}
                    />
                    {errorCurrentRegNoMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.ueln", "UELN")}
                      name={"uelnMareUnReg"}
                      type={"text"}
                      onChange={handleFormChange}
                      value={formModel.uelnMareUnReg}
                      disabled={handelDisabledButton()}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <label>
                      {translate("web.color", "Color")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.color", "Color"),
                          value: null,
                        },
                        ...(!!horseColorsData &&
                          horseColorsData.map((a) => ({
                            label: a.nameEn,
                            value: a.id,
                          }))),
                      ]}
                      value={formModel.colorMareUnRegDrop}
                      onChange={(e) => handleFormChange(e, "colorMareUnReg")}
                      placeholder={translate("web.color", "Color")}
                      name={"colorMareUnReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      key={"colorMareUnReg"}
                      isDisabled={handelDisabledButton()}
                      ref={colorMareUnRegRef}
                    />
                    {errorColorMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <label>
                      {translate("web.origin", "Origin")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.chooseOrigin", "Choose Origin"),
                          value: null,
                        },
                        ...(!!countriesData &&
                          countriesData.map((a) => ({
                            label: a.text,
                            value: a.value,
                          }))),
                      ]}
                      value={formModel.originMareUnRegDrop}
                      onChange={(e) => handleFormChange(e, "originMareUnReg")}
                      placeholder={translate("web.chooseOrigin", "Choose Origin")}
                      name={"originMareUnReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      key={"originMareUnReg"}
                      isDisabled={handelDisabledButton()}
                      ref={originMareUnRegRef}
                    />
                    {errorOriginMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3">
                    <label>
                      {translate("web.horseCountry", "Horse Country")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.horseCountry", "Horse Country"),
                          value: null,
                        },
                        ...(!!countriesData &&
                          countriesData.map((a) => ({
                            label: a.text,
                            value: a.value,
                          }))),
                      ]}
                      value={formModel.horseCountryMareUnRegDrop}
                      onChange={(e) => handleFormChange(e, "horseCountryMareUnReg")}
                      placeholder={translate("web.horseCountry", "Horse Country")}
                      name={"horseCountryMareUnReg"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      key={"horseCountryMareUnReg"}
                      isDisabled={handelDisabledButton()}
                      ref={horseCountryMareUnRegRef}
                    />
                    {errorHorseCountryMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-12 mt-3 service">
                    <NetaqInput
                      htmlTags={
                        formModel.birthDateMareUnReg ?
                          <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                            {dayjs(formModel.birthDateMareUnReg).format("DD/MM/YYYY")}
                          </p> :
                          <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>DD/MM/YYYY</p>
                      }
                      name={"birthDateMareUnReg"}
                      type={"normalDateService"}
                      label={translate("web.birthDate", "birth Date")}
                      onChange={(e) => handleFormChange(e, "birthDateMareUnReg")}
                      value={formModel.birthDateMareUnReg}
                      required={true}
                      maxDate={dayjs().format("YYYY-MM-DD")}
                      disabled={handelDisabledButton()}
                      ref={birthDateMareUnRegRef}
                    />
                    {errorBirthDateMareUnReg && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                </div>
              )}
          </EahsForm>

          {/* covering details */}
          <EahsForm title={translate("web.coveringDetails", "Covering Details")}>
            <div className="col-md-6 col-sm-12 rtl">
              <label>
                {translate("web.typeOfCovering", "Type Of Covering")}
              </label>
              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  type={"radioServiceRequest"}
                  value={"NaturalBreeding"}
                  label={translate(
                    "web.naturalBreeding",
                    "Natural (In-Hand) Breeding"
                  )}
                  onChange={handleFormChange}
                  checked={formModel.typeOfCovering === "NaturalBreeding"}
                  disabled={handelDisabledButton()}
                />
              </div>

              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  type={"radioServiceRequest"}
                  value={"ArtificialInseminationLocal"}
                  label={translate(
                    "web.artificialInseminationLocal",
                    "Artificial Insemination (Locally Collected Semen)"
                  )}
                  onChange={handleFormChange}
                  checked={formModel.typeOfCovering === "ArtificialInseminationLocal"}
                  disabled={handelDisabledButton()}
                />
              </div>

              <div className="col-md-12 col-sm-12 rtl p0 mt-3 row">
                <div className="col-5">
                  <NetaqInput
                    name={"typeOfCovering"}
                    type={"radioServiceRequest"}
                    value={"ArtificialInseminationImported"}
                    label={translate(
                      "web.artificialInseminationImported",
                      "Artificial Insemination (Imported Semen)"
                    )}
                    onChange={handleFormChange}
                    checked={formModel.typeOfCovering === "ArtificialInseminationImported"}
                    disabled={handelDisabledButton()}
                  />
                </div>

                {// imported Semen Certificate                       
                  formModel.typeOfCovering === "ArtificialInseminationImported" && (
                    <>
                      <div className="AuctionFilterradioServiceRequestContainer mt-3 mb-3 row col-md-12 col-sm-12" style={{ margin: 0 }}>
                        <div style={{ maxWidth: 500 }}>
                          <NetaqInput
                            name={"inseminationImportedType"}
                            type={"radioServiceRequest"}
                            value={"SemenDoseImported"}
                            label={`${translate("web.semenDoseImported", "Semen Dose Imported")}-`}
                            onChange={handleFormChange}
                            checked={
                              formModel.inseminationImportedType === "SemenDoseImported"
                            }
                            disabled={handelDisabledButton()}
                          />
                        </div>

                        <div style={{ maxWidth: 200 }}>
                          <NetaqInput
                            label={`${translate("web.certificate", "Certificate")}-`}
                            name={"inseminationImportedType"}
                            type={"radioServiceRequest"}
                            value={"Certificate"}
                            onChange={handleFormChange}
                            checked={
                              formModel.inseminationImportedType === "Certificate"
                            }
                            disabled={handelDisabledButton()}
                          />
                        </div>
                      </div>

                      {formModel.inseminationImportedType === "SemenDoseImported" && (
                        <div className="col-md-8 col-sm-12 rtl row">
                          <NetaqInput
                            label={translate("web.semenDoseImported", "Semen Dose Imported Reference Number")}
                            name={"semenDoseImportedReferenceNumber"}
                            type={"text"}
                            onChange={handleFormChange}
                            value={formModel.semenDoseImportedReferenceNumber}
                            required={true}
                            disabled={handelDisabledButton()}
                            ref={semenDoseImportedReferenceNumberRef}
                          />
                          {errorSemenDoseImportedReferenceNumber && (
                            <span style={{ color: "red" }}>
                              {translate("web.requiredField", "Required Field")}
                            </span>
                          )}
                        </div>
                      )}

                      {formModel.inseminationImportedType === "Certificate" && (
                        <div className="col-7">
                          <SimpleFileUpload
                            contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                            isMulti={false}
                            initialFiles={
                              !!formModel.filePathImportedSemenCertificate &&
                                !!formModel.documentBase64ImportedSemenCertificate &&
                                importedSemenCertificateAttachmentFile.length == 0
                                ? [
                                  {
                                    file: base64ToBlob(
                                      formModel.documentBase64ImportedSemenCertificate,
                                      formModel.fileTypeImportedSemenCertificate ===
                                        ".pdf"
                                        ? "application/pdf"
                                        : "image/png"
                                    ),
                                    name: formModel.fileNameImportedSemenCertificate,
                                  },
                                ]
                                : importedSemenCertificateAttachmentFile
                            }
                            isServer={
                              !!formModel.filePathImportedSemenCertificate &&
                                !!formModel.documentBase64ImportedSemenCertificate &&
                                importedSemenCertificateAttachmentFile.length == 0
                                ? true
                                : false
                            }
                            onChange={handleUploadImportedSemenCertificateChange}
                            accept={"image/*,application/pdf"}
                            disabled={handelDisabledButton()}
                            ref={importedSemenCertificateRef}
                          />
                          {errorImportedSemenCertificate && (
                            <span style={{ color: "red" }}
                            >
                              {translate("web.requiredField", "Required Field")}
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  )}
              </div>
            </div>

            <div className="col-md-4 col-sm-12 rtl service">
              <NetaqInput
                htmlTags={
                  formModel.coveringDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {dayjs(formModel.coveringDate).format("DD/MM/YYYY")}
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"coveringDate"}
                type={"normalDateService"}
                label={translate("web.coveringDate", "Covering Date")}
                onChange={(e) => handleFormChange(e, "coveringDate")}
                value={formModel.coveringDate}
                required={true}
                minDate={dayjs(oneYearAgo).format("YYYY-MM-DD")}
                maxDate={dayjs().format("YYYY-MM-DD")}
                disabled={handelDisabledButton()}
                ref={coveringDateRef}
              />
              {errorCoveringDate && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
              {
                formModel.typeOfCovering === "ArtificialInseminationLocal" &&
                <div className="mt-3">
                  <NetaqInput
                    label={translate("web.semenDoseOwnershipReferenceNumber", "Semen Dose Ownership Reference Number")}
                    name={"semenDoseOwnershipReferenceNumber"}
                    type={"text"}
                    onChange={handleFormChange}
                    value={formModel.semenDoseOwnershipReferenceNumber}
                    required={true}
                    disabled={handelDisabledButton()}
                    ref={semenDoseOwnershipReferenceNumberRef}
                  />
                  {errorSemenDoseOwnershipReferenceNumber && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
                </div>
              }
            </div>
          </EahsForm>

          {/* upload attachments */}
          <EahsForm title={translate("web.uploadDocument", "Upload Document")}>
            {/* breeding happend abroad */}
            <div className="col-md-2 col-sm-6  col-12 rtl p0 mt-1">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.breedingHappendAbroad",
                  "The Breeding Happend Abroad"
                )}
                name="breedingHappendAbroad"
                checked={formModel.breedingHappendAbroad}
                onChange={(e) => handleFormChange(e, "breedingHappendAbroad")}
                disabled={handelDisabledButton()}
                ref={breedingHappendAbroadCheckRef}
              />
              {errorBreedingHappendAbroadCheck && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            <div className="col-md-10 col-sm-6 row rtl mt-1 me-auto">
              {/* document file button breeding happend abroad */}
              <div style={{ maxWidth: 500 }} className="col-md-12 col-sm-12">
                <SimpleFileUpload
                  contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathBreedingHappendAbroad &&
                      !!formModel.documentBase64BreedingHappendAbroad &&
                      breedingHappendAbroadAttachmentFile.length == 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64BreedingHappendAbroad,
                            formModel.fileTypeBreedingHappendAbroad === ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameBreedingHappendAbroad,
                        },
                      ]
                      : breedingHappendAbroadAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathBreedingHappendAbroad &&
                      !!formModel.documentBase64BreedingHappendAbroad &&
                      breedingHappendAbroadAttachmentFile.length == 0
                      ? true
                      : false
                  }
                  onChange={handleUploadBreedingHappendAbroadChange}
                  accept={"image/*,application/pdf"}
                  disabled={handelDisabledBreedingHappendAbroadUpload()}
                  ref={breedingHappendAbroadRef}
                />
                {errorBreedingHappendAbroad && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
              </div>
            </div>

            {/* embryo is imported checkbox */}
            <div className="col-md-2 col-sm-6  col-12  rtl p0 mt-1">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoIsImported",
                  "The embryo is imported in utero"
                )}
                name="embryoIsImported"
                checked={formModel.embryoIsImported}
                onChange={(e) => handleFormChange(e, "embryoIsImported")}
                disabled={handelDisabledButton()}
                ref={embryoIsImportedCheckRef}
              />
              {errorEmbryoIsImportedCheck && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            {/* embryo is imported */}
            <div className="col-md-10 col-sm-6 row rtl mt-1  me-auto">
              <div style={{ maxWidth: 500 }} className="col-md-12 col-sm-12">
                {/* document file button embryo is imported */}
                <SimpleFileUpload
                  contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathEmbryoIsImported &&
                      !!formModel.documentBase64EmbryoIsImported &&
                      embryoIsImportedAttachmentFile.length == 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64EmbryoIsImported,
                            formModel.fileTypeEmbryoIsImported === ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameEmbryoIsImported,
                        },
                      ]
                      : embryoIsImportedAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathEmbryoIsImported &&
                      !!formModel.documentBase64EmbryoIsImported &&
                      embryoIsImportedAttachmentFile.length == 0
                      ? true
                      : false
                  }
                  onChange={handleUploadEmbryoIsImportedChange}
                  accept={"image/*,application/pdf"}
                  disabled={handelDisabledEmbryoIsImportedUpload()}
                  ref={embryoIsImportedRef}
                />
                {errorEmbryoIsImported && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
              </div>
            </div>

            {/* stallion owner signed permit checkbox */}
            <div className="col-md-2 col-sm-6  col-12  rtl p0 mt-1">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.stallionOwnerSignedPermit",
                  "I Have Stallion Owner Signed Permit"
                )}
                name="stallionOwnerSignedPermit"
                checked={formModel.stallionOwnerSignedPermit}
                onChange={(e) => handleFormChange(e, "stallionOwnerSignedPermit")}
                disabled={handelDisabledButton()}
                ref={stallionOwnerSignedPermitCheckRef}
              />
              {errorStallionOwnerSignedPermitCheck && (
                <span style={{ color: "red" }}>
                  {translate("web.requiredField", "Required Field")}
                </span>
              )}
            </div>

            {/* stallion owner signed permit */}
            {//formModel.stallionOwnerSignedPermitPart && (
              <div className="col-md-10 col-sm-6 row rtl mt-1  me-auto">
                <div style={{ maxWidth: 500 }} className="col-md-12 col-sm-12">
                  {/* document file button stallion owner signed permit */}
                  <div className="col-md-12 col-sm-12">
                    <SimpleFileUpload
                      contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                      isMulti={false}
                      initialFiles={
                        !!formModel.filePathStallionOwnerSignedPermit &&
                          !!formModel.documentBase64StallionOwnerSignedPermit &&
                          stallionOwnerSignedPermitAttachmentFile.length == 0
                          ? [
                            {
                              file: base64ToBlob(
                                formModel.documentBase64StallionOwnerSignedPermit,
                                formModel.fileTypeStallionOwnerSignedPermit ===
                                  ".pdf"
                                  ? "application/pdf"
                                  : "image/png"
                              ),
                              name: formModel.fileNameStallionOwnerSignedPermit,
                            },
                          ]
                          : stallionOwnerSignedPermitAttachmentFile
                      }
                      isServer={
                        !!formModel.filePathStallionOwnerSignedPermit &&
                          !!formModel.documentBase64StallionOwnerSignedPermit &&
                          stallionOwnerSignedPermitAttachmentFile.length == 0
                          ? true
                          : false
                      }
                      onChange={handleUploadStallionOwnerSignedPermitChange}
                      accept={"image/*,application/pdf"}
                      disabled={handelDisabledStallionOwnerSignedPermitUpload()}
                      ref={stallionOwnerSignedPermitRef}
                    />
                    {errorStallionOwnerSignedPermit && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              //)
            }
          </EahsForm>

          <EahsForm title={translate("web.embryoTransfer", "Embryo Transfer")}>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoTransfer",
                  "Embryo Transfer"
                )}
                name="embryoTransfer"
                checked={formModel.embryoTransfer}
                onChange={(e) => handleFormChange(e, "embryoTransfer")}
                disabled={handelDisabledEmbryoTransferButton()}
              />
            </div>
            {formModel.embryoTransfer &&
              <>
                <div className="col-md-12 col-sm-12 mt-3">
                  <label>
                    {translate("web.recipientMare", "Recipient Mare")}
                    <i className="colorred"> *</i>
                  </label>
                  <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                    <div style={{ maxWidth: 200 }}>
                      <NetaqInput
                        name={"recipientMare"}
                        type={"radioServiceRequest"}
                        value={"ArabianRegistered"}
                        label={`${translate("web.arabianRegistered", "Arabian Registered")}-`}
                        onChange={(e) => handleFormChange(e, "recipientMare")}
                        checked={formModel.recipientMare === "ArabianRegistered"}
                        disabled={handelDisabledButton()}
                      />
                    </div>
                    <div style={{ maxWidth: 220 }}>
                      <NetaqInput
                        name={"recipientMare"}
                        type={"radioServiceRequest"}
                        value={"ArabianNotRegistered"}
                        label={`${translate("web.arabianNotRegistered", "Arabian Not Registered")}-`}
                        onChange={(e) => handleFormChange(e, "recipientMare")}
                        checked={formModel.recipientMare === "ArabianNotRegistered"}
                        disabled={handelDisabledButton()}
                      />
                    </div>
                    <div style={{ maxWidth: 150 }}>
                      <NetaqInput
                        name={"recipientMare"}
                        type={"radioServiceRequest"}
                        value={"NotArabian"}
                        label={`${translate("web.notArabian", "Not Arabian")}-`}
                        onChange={(e) => handleFormChange(e, "recipientMare")}
                        checked={formModel.recipientMare === "NotArabian"}
                        disabled={handelDisabledButton()}
                      />
                    </div>
                  </div>
                </div>
                {/* //recipient Mare name */}
                {formModel.recipientMare === "ArabianRegistered" &&
                  (<div className="col-md-6 col-sm-12 mt-3">
                    <label>
                      {translate("web.recipientMareName", "Recipient Mare Name")}
                      <i className="colorred"> *</i>
                    </label>
                    <Select
                      options={[
                        {
                          label: translate("web.recipientMareName", "Recipient Mare Name"),
                          value: "",
                        },
                        ...(!!ownerHorsesDropDownData &&
                          ownerHorsesDropDownData.filter(q => q.gender === "Mare").map((a) => ({
                            label: a.registerNumber + " - " + a.name,
                            value: a.id,
                          }))),
                      ]}
                      value={formModel.recipientMareIdDrop}
                      onChange={(e) => handleFormChange(e, "recipientMareId")}
                      placeholder={translate("web.recipientMareName", "Recipient Mare Name")}
                      name={"recipientMareId"}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      autoFocus={true}
                      key={"recipientMareId"}
                      isDisabled={handelDisabledButton()}
                      ref={recipientMareRef}
                    />
                    {errorRecipientMare && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>)
                }

                {(formModel.recipientMare === "ArabianNotRegistered" || formModel.recipientMare === "NotArabian") &&
                  <>
                    <div className="col-md-6 col-sm-12 mt-3">
                      <NetaqInput
                        label={translate("web.name", "Name") + " (EN)"}
                        name={"recipientMareNameEnUnReg"}
                        type={"text"}
                        onChange={(e) => handleFormChange(e, "recipientMareNameEnUnReg")}
                        value={formModel.recipientMareNameEnUnReg}
                        required={true}
                        disabled={handelDisabledButton()}
                        ref={recipientMareNameEnUnRegRef}
                      />
                      {errorRecipientMareNameEnUnReg && (
                        <span style={{ color: "red" }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 col-sm-12 mt-3">
                      <NetaqInput
                        label={translate("web.name", "Name") + " (AR)"}
                        name={"recipientMareNameArUnReg"}
                        type={"text"}
                        onChange={(e) => handleFormChange(e, "recipientMareNameArUnReg")}
                        value={formModel.recipientMareNameArUnReg}
                        required={true}
                        disabled={handelDisabledButton()}
                        ref={recipientMareNameArUnRegRef}
                      />
                      {errorRecipientMareNameArUnReg && (
                        <span style={{ color: "red" }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 col-sm-12 mt-3">
                      <NetaqInput
                        label={translate("web.microchipNo", "Microchip No")}
                        name={"microchipNo"}
                        type={"text"}
                        onChange={handleFormChange}
                        value={formModel.microchipNo}
                        required={true}
                        disabled={handelDisabledButton()}
                        ref={microchipNoRef}
                      />
                      {errorMicrochipNo && (
                        <span style={{ color: "red" }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      )}
                    </div>
                  </>
                }

                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    htmlTags={
                      formModel.transferDate ?
                        <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                          {
                            dayjs(formModel.transferDate).format("DD/MM/YYYY")
                          }
                        </p> :
                        <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                          DD/MM/YYYY
                        </p>
                    }
                    name={"transferDate"}
                    type={"normalDateService"}
                    label={translate("web.transferDate", "Transfer Date")}
                    onChange={handleFormChange}
                    value={formModel.transferDate}
                    required={true}
                    maxDate={dayjs().format("YYYY-MM-DD")}
                    disabled={handelDisabledButton()}
                    ref={transferDateRef}
                  />
                  {errorTransferDate && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    label={translate("web.veterinarian", "Veterinarian")}
                    name={"veterinarian"}
                    type={"text"}
                    onChange={handleFormChange}
                    value={formModel.veterinarian}
                    required={true}
                    disabled={handelDisabledButton()}
                    ref={veterinarianRef}
                  />
                  {errorVeterinarian && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    label={translate("web.transferAddress", "Transfer Address")}
                    name={"transferAddress"}
                    type={"text"}
                    onChange={handleFormChange}
                    value={formModel.transferAddress}
                    required={true}
                    disabled={handelDisabledButton()}
                    ref={transferAddressRef}
                  />
                  {errorTransferAddress && (
                    <span style={{ color: "red" }}>
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mt-3">
                  <div className="col-md-12 font-15 Gray_color mt-1">
                    {translate(
                      "web.veterinarianReport",
                      "Veterinarian Report"
                    )} {" (pdf,png)"}
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <SimpleFileUpload
                      isMulti={false}
                      initialFiles={
                        !!formModel.filePathVeterinarianReport &&
                          !!formModel.documentBase64VeterinarianReport &&
                          veterinarianReportAttachmentFile.length == 0
                          ? [
                            {
                              file: base64ToBlob(
                                formModel.documentBase64VeterinarianReport,
                                formModel.fileTypeVeterinarianReport === ".pdf"
                                  ? "application/pdf"
                                  : "image/png"
                              ),
                              name: formModel.fileNameVeterinarianReport,
                            },
                          ]
                          : veterinarianReportAttachmentFile
                      }
                      isServer={
                        !!formModel.filePathVeterinarianReport &&
                          !!formModel.documentBase64VeterinarianReport &&
                          veterinarianReportAttachmentFile.length == 0
                          ? true
                          : false
                      }
                      onChange={handleUploadVeterinarianReportChange}
                      accept={"image/*,application/pdf"}
                      disabled={handelDisabledButton()}
                      ref={veterinarianReportRef}
                    />
                    {errorVeterinarianReport && (
                      <span style={{ color: "red" }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                  </div>
                </div>
              </>
            }
          </EahsForm>

          {/* Comments */}
          {handelShowGeneralComments() && (
            <EahsForm title={translate("web.comments", "Comments")}>
              {/* eahs Comment */}
              {!!formModel.comment && (
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              )}

              {/* Stallion Comment */}
              {handelShowStallionComment() && (
                <div className="col-md-6 col-sm-12 ">
                  <NetaqInput
                    label={translate("web.stallionComment", "Stallion Owner Comment")}
                    name={"stallionComment"}
                    type={"textarea"}
                    placeholder={translate(
                      "web.stallionComment",
                      "Stallion Owner Comment"
                    )}
                    maxchars={1150}
                    onChange={handleFormChange}
                    value={formModel.stallionComment}
                    disabled={handelDisabledStallionComment()}
                  />
                </div>
              )}
            </EahsForm>
          )}

          {/* terms */}
          <EahsForm title={translate("web.terms", "Terms")}>
            {handelShowCoveringCertificateNote1() && (
              <div className="col-md-12 col-sm-12 rtl p0">
                <NetaqInput
                  type={"checkbox"}
                  label={translate(
                    "web.coveringCertificateNote1",
                    "Covering Certificate Note 1"
                  )}
                  name="option2"
                  checked={checkedItems.option2}
                  onChange={handleCheckboxChange}
                  disabled={formModel.eventName === "" ? false : true}
                />
              </div>
            )}

            {/* display: formModel.horseMareRegLessThanOneWeek == true || checkLastChildrenOfHorseMareRegLessThanOneWeekData == true ? "" : "none", */}

            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.coveringCertificateNote2",
                  "Covering Certificate Note 2"
                )}
                name="option3"
                checked={checkedItems.option3}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>

            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons */}
          <div className="col-md-12 col-sm-12 mt-15 text-end row">
            {handelDisabledDraftButton() && (
              <ActionButton
                type={"requests"}
                className="btn buttonformshowentrycard height46 linkbtn ms-auto"
                label={translate("web.draft", "Draft")}
                text={
                  <>
                    <i className="fa fa-save fa-lg marginrightleft-10"></i>
                    {translate("web.draft", "Draft")}
                  </>
                }
                margin={"auto 10px"}
                width={"unset"}

                onClick={() => handelFormSubmit("Draft", "")}
              />
            )}

            {handelShowSubmitButton() && (
              <ActionButton
                type={"requests"}
                className="btn-success text-white requestBtn "
                label={translate("web.submit", "Submit")}
                text={
                  <>
                    <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                    {translate("web.submit", "Submit")}
                  </>
                }
                margin={"auto 25px"}
                width={"unset"}
                onClick={() => handelFormSubmit("Submit", "")}
              />
            )}

            {handelShowGeneralPayButtons() && (
              <>
                {handelShowPayLaterButton() && (
                  <div className="col-md-3">
                    <ActionButton
                      className="btn-info text-white w-100"
                      type={"bootstrapbtn"}
                      text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                                       ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                                       `}
                      margin={"0"}
                      width={"100%"}
                      onClick={() =>
                        handelFormSubmit("Draft", "no")
                      }
                    />
                  </div>
                )}

                {handelShowPayButton() &&
                  <div className="col-md-3">
                    <ActionButton
                      className={"btn-success requestBtn mx-0 w-100"}
                      type={"requests"}
                      label={translate("web.submitPay", "Submit And Pay")}
                      text={
                        <>
                          <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                          {translate("web.submitPay", "Submit And Pay")}
                        </>
                      }
                      margin={"0"}
                      width={"100%"}
                      onClick={() =>
                        handelFormSubmit("Draft", "yes")
                      }
                    />
                  </div>
                }
              </>
            )}

            {handelShowCancelButton() && (
              <ActionButton
                className={"btn-danger requestBtn"}
                type={"requests"}
                label={translate("web.cancel", "Cancel")}
                text={
                  <>
                    <i className="fa-solid fa-circle-xmark fa-lg marginrightleft-10"></i>
                    {translate("web.cancel", "Cancel")}
                  </>
                }
                margin={"auto 10px"}
                width={"250px"}
                onClick={() => { setOpenPopupCancel((o) => !o); }}
              />
            )}

            {handelShowRejectApproveButtons() && (
              <div className="row">
                <div className="col-md-2 col-sm-12 d-flex flex-column justify-content-center ms-auto">
                  <ActionButton
                    type={"submit"}
                    label={translate("web.reject", "Reject")}
                    text={
                      <>
                        <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                        {translate("web.reject", "Reject")}
                      </>
                    }
                    additionalText={`
                                ${translate(
                      "web.rejectStallionComment",
                      "*To Reject The Request Please Enter The Stallion Owner Comment*"
                    )}
                              `}
                    margin={"auto 10px"}
                    width={"100%"}
                    onClick={() => {
                      setOpenPopup((o) => !o);
                    }}
                    disabled={handelDisabledRejectButton()}
                  />
                </div>

                <div className="col-md-2 col-sm-12" style={{ minWidth: 246, marginLeft: "-46px" }}>
                  <ActionButton
                    className={"btn-success requestBtn"}
                    type={"requests"}
                    label={translate("web.approve", "Approve")}
                    text={
                      <>
                        <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                        {translate("web.approve", "Approve")}
                      </>
                    }
                    margin={"auto 10px"}
                    width={"250px"}
                    onClick={() => handelApproveRejectRequest("Approve")}
                  />
                </div>
              </div>
            )}
          </div>

          {/* pop up for reject */}
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <>
                <div className="">
                  {translate(
                    "web.confirmMsgRejectrequest",
                    "Are you sure to reject request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl ">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModal}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopup((o) => !o);
                        handelApproveRejectRequest("Reject");
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.reject", "Reject")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </Popup>

          {/* pop up for cancel */}
          <Popup
            modal
            lockScroll
            open={openPopupCancel}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <>
                <div className="">
                  {translate(
                    "web.confirmMsgCancelRequest",
                    "Are you sure to cancel request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModalCancel}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      style={{ height: 53 }}
                      onClick={() => {
                        setOpenPopupCancel((o) => !o);
                        handelCancelRequest();
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10" style={{ marginLeft: 0 }}></i>
                      {translate("web.cancel", "Cancel")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}
