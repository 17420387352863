import React, { forwardRef, useEffect, useRef } from 'react'

const EahsForm = forwardRef(({ children, title = "", style = {}, className = "" }, ref) => {
    const followUpRef = useRef(null);

    useEffect(() => {
        if (followUpRef.current) {

            followUpRef.current.scrollTop = followUpRef.current.scrollHeight + 20;
        }
    }, [])

    return (
        <>
            <h4 className="col-lg-12 col-md-12 col-sm-12 fw-bold mt-3 ps-0">
                {title}
            </h4>
            <div
                className={`row RegisterFormRow pt-1 pb-3 rtl px-1 ${className}`}
                style={style}
                ref={followUpRef}
            >
                {children}
            </div>
        </>
    )
})

export default EahsForm