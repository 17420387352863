import React, { useState, useEffect } from "react";
import { useTranslate } from "./TranslationText";

const CronometerCounter = ({ endDate, countUp = false, className = '', isMinutes = false, setTimerIsFinished, isBookingPage = false, auctionTimer }) => {
  const calculateTimeUntilFutureDate = (futureDate) => {
    // Convert the future date to milliseconds since Jan 1, 1970

    const futureTime = futureDate.getTime();

    // Get the current date and time
    const currentTime = new Date().getTime();
    let timeDifference = Math.abs(futureTime - currentTime);
    if (countUp == false) {
      timeDifference = futureTime - currentTime;
      if (timeDifference < 0 && !!setTimerIsFinished) {
        setTimerIsFinished(true)
      }
    }

    // Calculate the difference in milliseconds

    // Convert milliseconds to days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    
    return { days, hours, minutes, seconds };
  };

  const [timer, setTimer] = useState(calculateTimeUntilFutureDate(endDate));
  const { translate } = useTranslate();

  useEffect(() => {
    if (Math.abs(endDate.getTime() - new Date().getTime()) > 0) {
      const intervalId = setInterval(() => {
        const newTimerValues = calculateTimeUntilFutureDate(endDate);
        setTimer(newTimerValues);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [endDate, countUp]);


  if (isBookingPage) {
    return (
      <div className={`text-center color-white-bold ${className} `} style={{ direction: "ltr" }}>
        <div className="d-flex justify-content-center">
          <div className="bg-dark text-white me-1 px-2">
            {timer.minutes >= 10 ? timer.minutes : "0" + timer.minutes} <br />
            <span style={{ fontSize: 10 }}>
              {translate('web.minutes', 'Minutes')}
            </span>
          </div>
          <div className="bg-dark text-white mx-1 px-2">
            :
          </div>
          <div className="bg-dark text-white ms-1 px-2">
            {timer.seconds >= 10 ? timer.seconds : "0" + timer.seconds} <br />
            <span style={{ fontSize: 10 }}>
              {translate('web.seconds', 'Seconds')}
            </span>
          </div>
        </div>


      </div>
    )
  }

  return (
    <div className={`text-center color-white-bold ${className} `} style={{ direction: "ltr" }}>
      {isMinutes ?
        <>
          {timer.minutes >= 10 ? timer.minutes : "0" + timer.minutes} :{" "}
          {timer.seconds >= 10 ? timer.seconds : "0" + timer.seconds}
        </>
        :
        <>
          {timer.days > 0 && timer.days >= 10
            ? timer.days + " :"
            : "0" + timer.days + " :"}{" "}
          {timer.hours >= 10 ? timer.hours : "0" + timer.hours} :{" "}
          {timer.minutes >= 10 ? timer.minutes : "0" + timer.minutes} :{" "}
          {timer.seconds >= 10 ? timer.seconds : "0" + timer.seconds}
        </>
      }

    </div>
  );
};

export default CronometerCounter;
