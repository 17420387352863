// import React, { useState, useCallback, useEffect } from 'react';
// import { GoogleMap, useJsApiLoader, Autocomplete, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '400px',
//   height: '300px'
// };

// const libraries = ["places"];

// const PickAPlace = () => {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: 'AIzaSyB9wJFg7Giloe0DxBQrIscj7V0mTqyzSmk', // Replace with your actual API key
//     libraries: libraries
//   });

//   const [map, setMap] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [place, setPlace] = useState(null);
//   const [center, setCenter] = useState({ lat: 25.2048, lng: 55.2708 }); // Default to Dubai
//   const [markerPosition, setMarkerPosition] = useState(null);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setCenter({
//             lat: position.coords.latitude,
//             lng: position.coords.longitude
//           });
//         },
//         () => {
//           console.log('Error getting location');
//         }
//       );
//     }
//   }, []);

//   const onLoad = useCallback(function callback(map) {
//     setMap(map);
//   }, []);

//   const onUnmount = useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   const onAutocompleteLoad = useCallback(function callback(autocomplete) {
//     setAutocomplete(autocomplete)
//   }, [])

//   const onAutocompleteUnmount = useCallback(function callback() {
//     setAutocomplete(null)
//   }, [])

//   const onPlaceChanged = () => {
//     if (autocomplete) {
//       const p = autocomplete.getPlace();
//       setPlace(p);
//       console.log("Place details:", p); // Log the place details

//       if (p.geometry && p.geometry.location) {
//         const location = p.geometry.location;
//         map.panTo(location); // Center the map on the selected place
//         map.setZoom(14); // Adjust zoom as needed
//         setMarkerPosition({ lat: location.lat(), lng: location.lng() }); // Set marker position
//       }

//     } else {
//       console.log('Autocomplete is not loaded yet!');
//     }
//   };

//   return isLoaded ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={10}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//         options={Marker}
//       >
//         <Autocomplete
//           onLoad={onAutocompleteLoad}
//           onUnmount={onAutocompleteUnmount}
//           fields={['address_components', 'geometry', 'icon', 'name']} // Specify the fields you need
//           onPlaceChanged={onPlaceChanged}
//         >
//           <input
//             type="text"
//             placeholder="Search a place"
//             style={{
//               boxSizing: `border-box`,
//               border: `1px solid transparent`,
//               width: `240px`,
//               height: `32px`,
//               padding: `0 12px`,
//               borderRadius: `3px`,
//               boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//               fontSize: `14px`,
//               outline: `none`,
//               textOverflow: `ellipsis`,
//               position: 'absolute', // or relative, depending on your layout
//               top: '10px',        // Adjust as needed
//               left: '10px'       // Adjust as needed
//             }}
//           />
//         </Autocomplete>
//         {/* Add Marker component */}
//         {markerPosition && (
//           <Marker position={markerPosition} />
//         )}
//         {place && (
//           <div>
//             <h3>Selected Place:</h3>
//             <pre>{JSON.stringify(place.name, null, 2)}</pre> {/* Display place details */}
//           </div>
//         )}
//       </GoogleMap>
//   ) : <></>;
// }

// export default PickAPlace;
import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, useLoadScript, Autocomplete } from '@react-google-maps/api';
import NetaqInput from './NetaqInput';
import { useTranslate } from './TranslationText';

const MapWithSearchAndLocate = ({ setSelectedLocation, selectedLocation, setFullAddress, fullAddress, isView }) => {
  const { translate } = useTranslate();
  const [searchQuery, setSearchQuery] = useState('');
  const autocompleteRef = useRef(null);
  //const [fullAddress, setFullAddress] = useState('');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB9wJFg7Giloe0DxBQrIscj7V0mTqyzSmk', // Replace with your API key
    libraries: ['places'], // Load the Places library
  });

  const mapContainerStyle = {
    width: '100%',
    height: '500px',
    position: 'absolute',
    top: 0,
    left: 0
  };

  // Get the user's current location
  const locateUser = () => {
    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const userLocation = {
            ...selectedLocation,
            lat: latitude,
            lng: longitude,
          };

          setSelectedLocation(userLocation);

          getFullAddress(longitude, longitude)

          setSearchQuery(translate("web.yourCurrentLocation", 'Your current location'));
        },
        (error) => {
          console.error('Error getting current location:', error);

          alert(translate("web.unableRetrieveYourLocation", 'Unable to retrieve your location. Please ensure location services are enabled.'));
        }
      );
    } else {
      alert(translate("web.geolocationNotSupportedBrowser", 'Geolocation is not supported by your browser.'));
    }
  };

  // Handle map click to set the marker position
  const handleMapClick = (event) => {
    if (!isView) {

      setSelectedLocation({
        ...selectedLocation,
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });

      getFullAddress(event.latLng.lat(), event.latLng.lng())

      setSearchQuery(''); // Clear search query when clicking on the map
    }
  };

  // Handle place selection from Autocomplete
  const onPlaceChanged = () => {
    if (autocompleteRef.current) {

      const place = autocompleteRef.current.getPlace();

      if (place.geometry) {

        const location = {
          ...selectedLocation,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        setSelectedLocation(location);

        getFullAddress(place.geometry.location.lat(), place.geometry.location.lng());

        setSearchQuery(place.formatted_address || '');
      }
    }
  };

  const getFullAddress = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          //return results[0].formatted_address;

          setFullAddress(results[0].formatted_address);
        } else {
          //return translate("web.noAddressFound", 'No address found');
          setFullAddress(translate("web.noAddressFound", 'No address found'));
        }
      } else {
        //return translate("web.geocoderFailedDueTo", "Geocoder failed due to") + ": " + status;
        setFullAddress(translate("web.geocoderFailedDueTo", "Geocoder failed due to") + ": " + status);
      }
    });
  };

  if (loadError) return <div>{translate("web.errorLoadingMaps", "Error loading maps")}</div>;
  if (!isLoaded) return <div>{translate("web.loadingMaps", "Loading Maps...")}</div>;

  return (
    <div style={{ position: 'relative', height: '500px' }}>
      {/* Search Box and Locate Me Button */}
      <div style={{ marginBottom: '16px', display: 'flex', gap: '8px', position: 'absolute', zIndex: '1', marginLeft: "200px", marginRight: "200px", marginTop: "10px", }}>
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder={translate("web.searchForLocation", "Search for a location")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ flex: 1, padding: '8px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '4px', }}
            disabled={isView}
          />

        </Autocomplete>

        <button
          onClick={locateUser}
          style={{
            padding: '8px 16px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          disabled={isView}
        >
          {translate("web.locateMe", "Locate Me")}
        </button>
      </div>

      {/* Google Map */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={selectedLocation}
        onClick={handleMapClick}
      >

        <Marker position={selectedLocation} />

      </GoogleMap>

      <NetaqInput type='text' />
    </div>
  );
};

export default MapWithSearchAndLocate;