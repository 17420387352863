import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { base64ToBlob } from "../helper/base64ToBlob";
import dayjs from "dayjs";
import { FetchOwnerHorsesDropDownthunk } from "../Redux/OwnerProfile/OwnerProfileAction";
import { FetchHorseColorsthunk } from "../Redux/LookUp/LookUpAction";
import EahsForm from "../components/EahsForm";

export default function RequestToUpdateArabianHorseData() {
  //env 
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    createOrEditUpdateArabianHorseDataServiceRequestData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const { horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );

  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    updateArabianHorseDataId: "",
    eventName: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",

    horse: "",
    horseDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },

    horseName: "",
    birthDate: "",
    birthDateMin: "",

    fromColor: "",
    toColor: "",
    toColorDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },

    fromSex: "",
    toSex: "Gelding",
    changeSex: false,

    castredDate: null,

    ownerComment: "",

    filePathVeterinarianReport: "",
    fileTypeVeterinarianReport: "",
    fileNameVeterinarianReport: "",
    documentBase64VeterinarianReport: "",
    veterinarianReportAttachmentFile: null,
  });

  const [errorHorse, setErrorHorse] = useState(false);
  const [errorToColor, setErrorToColor] = useState(false);
  const [errorCastredDate, setErrorCastredDate] = useState(false);
  const [errorVeterinarianReport, setErrorVeterinarianReport] = useState(false);

  //use ref
  const horseRef = useRef(null);
  const toColorRef = useRef(null);
  const castredDateRef = useRef(null);
  const veterinarianReportRef = useRef(null);

  const [
    veterinarianReportAttachmentFile,
    setVeterinarianReportAttachmentFile,
  ] = useState([]);

  const _isOnlinePayment = useRef("");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function

  const handleFormChange = (e, input) => {
    if (input === "horse") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      if (horse.length > 0) {
        setFormModel({
          ...formModel,
          horse: e.value,
          horseDrop: e,
          horseName: (horse[0]?.registerNumber != null ? (horse[0]?.registerNumber + " - ") : "") + horse[0]?.name ?? "",
          birthDate: dayjs(horse[0]?.birthDate ?? "").format('DD/MM/YYYY'),
          birthDateMin: horse[0]?.birthDate ?? "",
          fromColor: horse[0]?.color ?? "",
          fromSex: horse[0]?.gender ?? "",
          changeSex: false,
          castredDate: null,
          veterinarianReportAttachmentFile: null
        });
      }
      else {
        setFormModel({
          ...formModel,
          horse: "",
          horseDrop: {
            label: translate("web.horse", "Horse"),
            value: null,
          },
          horseName: "",
          birthDate: "",
          birthDateMin: "",
          fromColor: "",
          fromSex: "",
          changeSex: false,
          castredDate: null
        });
      }

      setVeterinarianReportAttachmentFile([]);
    }
    else if (!!input && input === "toColor") {
      setFormModel({
        ...formModel,
        toColor: e.value,
        toColorDrop: e,
      });
    }
    else if (input === "changeSex") {
      setFormModel({
        ...formModel,
        changeSex: e.target.checked,
        castredDate: null,
        veterinarianReportAttachmentFile: null
      });

      setVeterinarianReportAttachmentFile([]);
    }
    else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUploadVeterinarianReportChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          veterinarianReportAttachmentFile: e.target.files[0],
        });

        setVeterinarianReportAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.horse === ""
    ) {
      setErrorHorse(true);
      valid = false;

      horseRef?.current?.focus();
      return;
    }
    else {
      setErrorHorse(false);
    }

    if (
      formModel.changeSex === false && formModel.toColor === ""
    ) {
      setErrorToColor(true);
      valid = false;

      toColorRef?.current?.focus();
      return;
    }
    else {
      setErrorToColor(false);
    }

    if (
      formModel.changeSex === true && formModel.castredDate == null
    ) {
      setErrorCastredDate(true);
      valid = false;

      castredDateRef?.current?.focus();
      return;
    }
    else {
      setErrorCastredDate(false);
    }

    if (
      formModel.changeSex === true
      && veterinarianReportAttachmentFile.length === 0
      && formModel.documentBase64VeterinarianReport === ""
    ) {
      setErrorVeterinarianReport(true);
      valid = false;

      veterinarianReportRef?.current?.focus();
      return;
    }
    else {
      setErrorVeterinarianReport(false);
    }

    //term
    if (
      !checkedItems.option1
    ) {
      toast.error(
        translate("web.pleaseCheckTerm", "Please Check Term")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    if (getServiceDefinitionByIdData.serviceFees > 0 && formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=15&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        _isOnlinePayment.current = isOnlinePayment;
        let params = {
          id: id == null ? '' : id,
          updateArabianHorseDataId: formModel.updateArabianHorseDataId,
          ownerId: getUserID(),
          horse: formModel.horse,
          fromColor: formModel.fromColor,
          toColor: formModel.toColor,
          changeSex: formModel.changeSex,
          fromSex: formModel.fromSex,
          toSex: formModel.toSex,
          castredDate: formModel.castredDate != null ? dayjs(formModel.castredDate).format("DD/MM/YYYY") : '',
          veterinarianReportAttachmentFile: formModel.veterinarianReportAttachmentFile,
          status: status,
          ownerComment: formModel.ownerComment,
        };

        dispatch(FetchCreateOrEditUpdateArabianHorseDataServiceRequestthunk(params));
      }
      else {
        toast.error(
          translate("web.completeTheRequiredData", "Complete The Required Data")
        );
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,

      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },

      horseName: "",
      birthDate: "",
      birthDateMin: "",
      fromColor: "",
      toColor: "",
      toColorDrop: {
        label: translate("web.color", "Color"),
        value: null,
      },

      fromSex: "",
      toSex: "GELDING",
      castredDate: null,
      veterinarianReportAttachmentFile: null,
    });

    setVeterinarianReportAttachmentFile([]);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "UpdateArabianHorseData" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      setTimeout(() => {
        navigate("/profile");
      }, 3000);
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {

    if (getServicesRequestByIdData != null &&
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,

        updateArabianHorseDataId: getServicesRequestByIdData.updateArabianHorseDataDto.updateArabianHorseDataId,

        horse: getServicesRequestByIdData.updateArabianHorseDataDto.horse,
        horseDrop: {
          label: getServicesRequestByIdData.updateArabianHorseDataDto.horseName,
          value: getServicesRequestByIdData.updateArabianHorseDataDto.horse,
        },

        horseName: getServicesRequestByIdData.updateArabianHorseDataDto.horseName,

        birthDate: getServicesRequestByIdData.updateArabianHorseDataDto.birthDate,
        birthDateMin: getServicesRequestByIdData.updateArabianHorseDataDto.birthDateMin,
        fromColor: getServicesRequestByIdData.updateArabianHorseDataDto.fromColor,
        toColor: getServicesRequestByIdData.updateArabianHorseDataDto.toColor ?? "",
        toColorDrop: {
          label: getServicesRequestByIdData.updateArabianHorseDataDto.toColorName ?? translate("web.color", "Color"),
          value: getServicesRequestByIdData.updateArabianHorseDataDto.toColor,
        },
        changeSex: getServicesRequestByIdData.updateArabianHorseDataDto.changeSex,
        fromSex: getServicesRequestByIdData.updateArabianHorseDataDto.fromSex,
        //toSex: getServicesRequestByIdData.updateArabianHorseDataDto.toSex,
        castredDate: getServicesRequestByIdData.updateArabianHorseDataDto.castredDateTime != null ? dayjs(getServicesRequestByIdData.updateArabianHorseDataDto.castredDateTime).format("YYYY-MM-DD") : null,
        ownerComment: getServicesRequestByIdData.updateArabianHorseDataDto.ownerComment != null ? getServicesRequestByIdData.updateArabianHorseDataDto.ownerComment : "",
        filePathVeterinarianReport: getServicesRequestByIdData.updateArabianHorseDataDto.veterinarianReportAttachmentFileModel.filePath,
        fileTypeVeterinarianReport: getServicesRequestByIdData.updateArabianHorseDataDto.veterinarianReportAttachmentFileModel.fileType,
        fileNameVeterinarianReport: getServicesRequestByIdData.updateArabianHorseDataDto.veterinarianReportAttachmentFileModel.fileName,
        documentBase64VeterinarianReport: getServicesRequestByIdData.updateArabianHorseDataDto.veterinarianReportAttachmentFileModel.fileBase64,
      });

      //another owner
      if (
        getServicesRequestByIdData.updateArabianHorseDataDto.ownerId !==
        getServicesRequestByIdData.updateArabianHorseDataDto.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.updateArabianHorseDataDto.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.updateArabianHorseDataDto.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.updateArabianHorseDataDto.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.updateArabianHorseDataDto.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSubmittedSuccessfully",
            "Your Request Has Submitted Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${createOrEditUpdateArabianHorseDataServiceRequestData.id}&paymentType=15&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      if (toasterMessageServicesRequests !== "errorServiceDetails") {

        toast.error(
          translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
        );

        dispatch(resetToasterMessageServicesRequests());
      }

      else if (toasterMessageServicesRequests === "errorServiceDetails") {
        toast.error(
          translate("web.createServiceRequestFailed", "Service Request Failed")
        );

        dispatch(resetToasterMessageServicesRequests());

        setTimeout(() => {
          navigate(`/profile?activeTab=${"e_services"}`);
        }, 1000);
      }
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">

      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">

          {/* update Arabian Horse Data */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>

          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          <EahsForm title={translate("web.horseDetails", "Horse Details")}>
            <div className="col-md-6 col-sm-12">
              <label>
                {translate("web.horse", "Horse")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.horse", "Horse"),
                    value: null,
                  },
                  ...(!!ownerHorsesDropDownData &&
                    ownerHorsesDropDownData.map((a) => ({
                      label: a.registerNumber + " - " + a.name,
                      value: a.id,
                    }))),
                ]}
                value={formModel.horseDrop}
                onChange={(e) => handleFormChange(e, "horse")}
                placeholder={translate("web.horse", "Horse")}
                name={"horse"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                autoFocus={true}
                key={"horse"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
                ref={horseRef}
              />
              {
                errorHorse && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
            </div>

            {/* <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.name", "Name")}
                name={"horseName"}
                type={"text"}
                value={formModel.horseName}
                disabled={true}
              />
            </div> */}

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.birthDate", "Birth Date")}
                name={"birthDate"}
                type={"text"}
                value={
                  formModel.birthDate
                }
                disabled={true}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.fromColor", "From Color")}
                name={"fromColor"}
                type={"text"}
                value={
                  formModel.fromColor
                }
                disabled={true}
              />
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.toColor", "To Color")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.toColor", "To Color"),
                    value: null,
                  },
                  ...(!!horseColorsData &&
                    horseColorsData.map((a) => ({
                      label: a.nameEn,
                      value: a.id,
                    }))),
                ]}
                value={formModel.toColorDrop}
                onChange={(e) => handleFormChange(e, "toColor")}
                placeholder={translate("web.toColor", "To Color")}
                name={"toColor"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                key={"toColor"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
                ref={toColorRef}
              />
              {
                errorToColor && (
                  <span style={{ color: "red" }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                )}
            </div>

            {
              formModel.fromSex === "Stallion" ? (
                <>
                  <div className="col-md-1 col-sm-12 mt-3">
                    <NetaqInput
                      type={"checkbox"}
                      label={translate(
                        "web.changeSex",
                        "Change Sex"
                      )}
                      name="changeSex"
                      checked={formModel.changeSex}
                      onChange={(e) => handleFormChange(e, "changeSex")}
                      disabled={
                        ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                          true
                          : false
                      }
                    />
                  </div>

                  <div className="col-md-3 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.fromSex", "From Sex")}
                      name={"fromSex"}
                      type={"text"}
                      value={translate(
                        `web._${formModel.fromSex.toLowerCase()}`,
                        formModel.fromSex
                      )}
                      disabled={true}
                    />
                  </div>

                  <div className="col-md-3 col-sm-12 mt-3">
                    <NetaqInput
                      label={translate("web.toSex", "To Sex")}
                      name={"toSex"}
                      type={"text"}
                      value={
                        translate("web.gelding", "GELDING")
                      }
                      disabled={true}
                    />
                  </div>

                  {
                    formModel.changeSex && (
                      <div className="col-md-5 col-sm-12 mt-3 service">
                        <NetaqInput
                          htmlTags={
                            formModel.castredDate ?
                              <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                {
                                  dayjs(formModel.castredDate).format("DD/MM/YYYY")
                                }
                              </p> :
                              <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                DD/MM/YYYY
                              </p>
                          }
                          name={"castredDate"}
                          type={"normalDateService"}
                          label={translate("web.castredDate", "Castred Date")}
                          onChange={(e) => handleFormChange(e, "castredDate")}
                          value={formModel.castredDate}
                          required={true}
                          minDate={dayjs(formModel.birthDateMin).format("YYYY-MM-DD")}
                          maxDate={dayjs().format("YYYY-MM-DD")}
                          disabled={
                            ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                              true
                              : false
                          }
                          ref={castredDateRef}
                        />
                        {
                          errorCastredDate && (
                            <span style={{ color: "red" }}>
                              {translate("web.requiredField", "Required Field")}
                            </span>
                          )}
                      </div>
                    )}
                </>
              ) : (
                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    label={translate("web.sex", "Sex")}
                    name={"fromSex"}
                    type={"text"}
                    value={translate(
                      `web._${formModel.fromSex.toLowerCase()}`,
                      formModel.fromSex
                    )}
                    disabled={true}
                  />
                </div>
              )}
          </EahsForm>

          {formModel.changeSex && (
            <EahsForm title={translate("web.uploadDocument", "Upload Document")}>
              <div className="col-md-12 col-sm-12 row mb-25 row align-items-center">
                <h3>
                  {translate("web.veterinaryCertificate", "Veterinary Certificate")}
                  <i className="colorred"> *</i>
                </h3>
                <div className="col-7">
                  <SimpleFileUpload
                    contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                    isMulti={false}
                    initialFiles={
                      !!formModel.filePathVeterinarianReport &&
                        !!formModel.documentBase64VeterinarianReport &&
                        veterinarianReportAttachmentFile.length === 0
                        ? [
                          {
                            file: base64ToBlob(
                              formModel.documentBase64VeterinarianReport,
                              formModel.fileTypeVeterinarianReport ===
                                ".pdf"
                                ? "application/pdf"
                                : "image/png"
                            ),
                            name: formModel.fileNameVeterinarianReport,
                          },
                        ]
                        : veterinarianReportAttachmentFile
                    }
                    isServer={
                      !!formModel.filePathVeterinarianReport &&
                        !!formModel.documentBase64VeterinarianReport &&
                        veterinarianReportAttachmentFile.length === 0
                        ? true
                        : false
                    }
                    onChange={handleUploadVeterinarianReportChange}
                    accept={"image/*,application/pdf"}
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                    ref={veterinarianReportRef}
                  />
                  {
                    errorVeterinarianReport && (
                      <span style={{ color: "red", display: errorVeterinarianReport }}>
                        {translate("web.requiredField", "Required Field")}
                      </span>
                    )}
                </div>
              </div>
            </EahsForm>
          )}

          {/* Comments */}
          {(!!formModel.comment || !!formModel.ownerComment) && (
            <EahsForm title={translate("web.comments", "Comments")}>
              {!!formModel.comment && (
                // eahs Comment
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              )}

              {(!!formModel.ownerComment || formModel.eventName === "SentBack") && (
                // owner Comment 
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.ownerComment", "Owner Comment")}
                    name={"ownerComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.ownerComment}
                    onChange={handleFormChange}
                    disabled={formModel.eventName !== "SentBack" ? true : false}
                  />
                </div>
              )}
            </EahsForm>
          )}

          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons*/}
          <div className="col-md-12 col-sm-12 mt-5 text-end">
            {/* draft/pay/submit btn */}
            {(
              (action == null || action === "edit")
              ||
              (
                action === "approve"
                &&
                (
                  formModel.eventName === "SentBack"
                  ||
                  (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
                )
              )
            ) && (
                <div className="row mx-0 justify-content-lg-end justify-content-sm-start">
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-lg-2 my-1">
                      <ActionButton
                        type={"bootstrapbtn"}
                        className="btn btn-outline-danger mx-auto w-100"
                        label={translate("web.draft", "Draft")}
                        text={`<i class="fa fa-save fa-lg marginrightleft-10"></i>                   
                          ${translate("web.draft", "Draft")}
                          `}
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={
                          formModel.eventName === "" ||
                            formModel.eventName === "Draft"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }

                  {userProfile?.participateWithoutPayment === true && formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className="btn-info text-white mx-auto"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-lg-3 my-1">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 mx-auto w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {(getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack") && (
                    <div className="col-lg-2 my-sm-1">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100 mx-auto"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                        }
                        disabled={
                          getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
