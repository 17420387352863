import React from 'react'

export default function FollowUpData({ data = [] }) {
    return (
        <>
            {data?.length > 0 &&
                data.map((item, index) => {

                    return <div className={`comment ${item.type === "Portal" ? "text-end" : "text-start"}`} key={index + item.createdDate}>
                        <div className="comment-content">
                            <div className="comment-header">
                                {
                                    item.type === "Portal" ? (
                                        <>
                                            <span className="comment-date">{item.createdDate}</span>
                                            <span style={{ width: '70%', minWidth: 200 }}>{item.name}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{ width: '70%', minWidth: 200 }}>{item.name}</span>
                                            <span className="comment-date">{item.createdDate}</span>
                                        </>
                                    )
                                }

                            </div>
                            <div className="comment-text">
                                {item.comment}
                            </div>
                        </div>
                    </div>
                })}
        </>
    )
}
