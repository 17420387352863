import React, { useEffect, useState } from "react";
import CollapseLabel from "../components/CollapseLabel";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchStudBookBreederOwnerDetailsthunk,
  FetchStudBookHorseCompetitionthunk,
  FetchStudBookHorseDetailsthunk,
  FetchStudBookHorsePedigreeByIdthunk,
  FetchStudBookHorseProgenythunk,
  FetchStudBookLanguagethunk,
  FetchStudBookOwnerDetailsthunk,
  FetchStudBookSiblingsDamthunk,
  FetchStudBookSiblingsSireAndDamthunk,
  FetchStudBookSiblingsSirethunk,
} from "../Redux/StudBook/StudBookAction";
import {
  incrementPageHorseCompetition,
  incrementPageProgeny,
  incrementPageSiblingsDam,
  incrementPageSiblingsSire,
  incrementPageSiblingsSireAndDam,
} from "../Redux/StudBook/StudBookSlice";
import HorseDetailsPedigreeChart from "../components/HorseDetailsPedigreeChart";

import { useTranslate } from "../components/TranslationText";

import Loader from "../components/Loader";
import HorseCard from "../components/HorseCard";
import StudBookTab from "../components/StudBookTab";
import axiosInstance from "../axios/axios";
import { formatDate } from "../helper/formatDate";
import CompetitionsTab from "../components/CompetitionsTab";
import StudBookCard from "../components/StudBookCard";

export default function HorseDetailsPage() {
  // get hourse data from backend
  const location = useLocation();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const currentHorseId = searchParams.has("horseId")
    ? searchParams.get("horseId")
    : null;

  const {
    studBookLoading,
    studBookData,
    studBookProgenyData,
    studBookSiblingsSireAndDamData,
    studBookSiblingsSireData,
    studBookSiblingsDamData,
    studBookHorseCompetitionData,
    studBookPedigreeData,
    studBookHorseDetailsData,
    ownerDetailsData,
    breederOwnerDetailsData,
    takeProgeny,
    pageProgeny,
    totalCountProgeny,

    takeSiblingsSireAndDam,
    pageSiblingsSireAndDam,
    totalCountSiblingsSireAndDam,

    takeSiblingsSire,
    pageSiblingsSire,
    totalCountSiblingsSire,

    takeSiblingsDam,
    pageSiblingsDam,
    totalCountSiblingsDam,

    takeHorseCompetition,
    pageHorseCompetition,
    totalCountHorseCompetition,
    currentLanguage,
  } = useSelector((state) => state.studBookSlice);

  const dispatch = useDispatch();

  // console.log(studBookHorseDetailsData);
  // console.log(studBookPedigreeData);
  //const pedigreeArray = Object.entries(studBookPedigreeData);

  const TabStyle = {
    display: "block",
    marginRight: 10,
    padding: "10px 20px",
    textAlign: "center",
    background: "rgba(0,0,0,0.08)",
    cursor: "pointer",
    color: "#000",
    transition: "all 300ms",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  };

  const paginiateStyle = {
    width: 36,
    height: 36,
    background: "#E44D52",
    display: "inline-block",
    lineHeight: "37px",
    color: "#fff",
    cursor: "pointer",
    margin: 5,
    borderRadius: 8,
  };
  const pagenumberStyle = {
    ...paginiateStyle,
    width: 36,
    height: 36,
    background: "#ffffff",
    display: "inline-block",
    lineHeight: "37px",
    color: "black",
    cursor: "pointer",
    margin: 5,
    borderRadius: 8,
    borderColor: "#c0cedd",
    borderStyle: "solid",
  };

  const handlePageChange = (page) => {
    dispatch(incrementPageProgeny(page));
  };

  const handlePageChangeSiblingsSireAndDam = (page) => {
    dispatch(incrementPageSiblingsSireAndDam(page));
  };
  const handlePageChangeSiblingsSire = (page) => {
    dispatch(incrementPageSiblingsSire(page));
  };
  const handlePageChangeSiblingsDam = (page) => {
    dispatch(incrementPageSiblingsDam(page));
  };
  const handlePageChangeHorseCompetition = (page) => {
    dispatch(incrementPageHorseCompetition(page));
  };

  // Function to render pagination buttons
  const renderPagination = () => {
    const pages = [];

    const numPages = Math.ceil(totalCountProgeny / takeProgeny);

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <React.Fragment>
          <span
            key={i}
            style={{
              ...pagenumberStyle,
              background:
                i === pageProgeny
                  ? "rgba(0, 0, 0)"
                  : pagenumberStyle.background,
              color: i === pageProgeny ? "white" : pagenumberStyle.color,
            }}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        </React.Fragment>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageProgeny === 1) {
      _pages = pages
        .slice(pageProgeny - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageProgeny > 1) {
      _pages = pages.slice(pageProgeny);
    }
    if (pageProgeny > 1 && numPages - pageProgeny > 3) {
      _pages = pages
        .slice(pageProgeny - 1, pageProgeny)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageProgeny === 1 && numPages - pageProgeny <= 3) {
      _pages = pages;
      if (studBookProgenyData.length === 0) {
        _pages = [];
      }
    }

    if (pageProgeny > 1 && numPages - pageProgeny <= 3) {
      _pages = pages
        .slice(pageProgeny - 1, pageProgeny)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const renderPaginationSiblingsSireAndDam = () => {
    const pages = [];

    const numPages = Math.ceil(
      totalCountSiblingsSireAndDam / takeSiblingsSireAndDam
    );

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <React.Fragment>
          <span
            key={i}
            style={{
              ...pagenumberStyle,
              background:
                i === pageSiblingsSireAndDam
                  ? "rgba(0, 0, 0)"
                  : pagenumberStyle.background,
              color:
                i === pageSiblingsSireAndDam ? "white" : pagenumberStyle.color,
            }}
            onClick={() => handlePageChangeSiblingsSireAndDam(i)}
          >
            {i}
          </span>
        </React.Fragment>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageSiblingsSireAndDam === 1) {
      _pages = pages
        .slice(pageSiblingsSireAndDam - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageSiblingsSireAndDam > 1) {
      _pages = pages.slice(pageSiblingsSireAndDam);
    }
    if (pageSiblingsSireAndDam > 1 && numPages - pageSiblingsSireAndDam > 3) {
      _pages = pages
        .slice(pageSiblingsSireAndDam - 1, pageSiblingsSireAndDam)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (
      pageSiblingsSireAndDam === 1 &&
      numPages - pageSiblingsSireAndDam <= 3
    ) {
      _pages = pages;
      if (studBookSiblingsSireAndDamData.length === 0) {
        _pages = [];
      }
    }

    if (pageSiblingsSireAndDam > 1 && numPages - pageSiblingsSireAndDam <= 3) {
      _pages = pages
        .slice(pageSiblingsSireAndDam - 1, pageSiblingsSireAndDam)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const renderPaginationSiblingsSire = () => {
    const pages = [];

    const numPages = Math.ceil(totalCountSiblingsSire / takeSiblingsSire);

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <span
          key={i}
          style={{
            ...pagenumberStyle,
            background:
              i === pageSiblingsSire
                ? "rgba(0, 0, 0)"
                : pagenumberStyle.background,
            color: i === pageSiblingsSire ? "white" : pagenumberStyle.color,
          }}
          onClick={() => handlePageChangeSiblingsSire(i)}
        >
          {i}
        </span>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageSiblingsSire === 1) {
      _pages = pages
        .slice(pageSiblingsSire - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageSiblingsSire > 1) {
      _pages = pages.slice(pageSiblingsSire);
    }
    if (pageSiblingsSire > 1 && numPages - pageSiblingsSire > 3) {
      _pages = pages
        .slice(pageSiblingsSire - 1, pageSiblingsSire)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageSiblingsSire === 1 && numPages - pageSiblingsSire <= 3) {
      _pages = pages;
      if (studBookSiblingsSireData.length === 0) {
        _pages = [];
      }
    }

    if (pageSiblingsSire > 1 && numPages - pageSiblingsSire <= 3) {
      _pages = pages
        .slice(pageSiblingsSire - 1, pageSiblingsSire)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const renderPaginationSiblingsDam = () => {
    const pages = [];

    const numPages = Math.ceil(totalCountSiblingsDam / takeSiblingsDam);

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <React.Fragment>
          <span
            key={i}
            style={{
              ...pagenumberStyle,
              background:
                i === pageSiblingsDam
                  ? "rgba(0, 0, 0)"
                  : pagenumberStyle.background,
              color: i === pageSiblingsDam ? "white" : pagenumberStyle.color,
            }}
            onClick={() => handlePageChangeSiblingsDam(i)}
          >
            {i}
          </span>
        </React.Fragment>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageSiblingsDam === 1) {
      _pages = pages
        .slice(pageSiblingsDam - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageSiblingsDam > 1) {
      _pages = pages.slice(pageSiblingsDam);
    }
    if (pageSiblingsDam > 1 && numPages - pageSiblingsDam > 3) {
      _pages = pages
        .slice(pageSiblingsDam - 1, pageSiblingsDam)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageSiblingsDam === 1 && numPages - pageSiblingsDam <= 3) {
      _pages = pages;
      if (studBookSiblingsDamData.length === 0) {
        _pages = [];
      }
    }

    if (pageSiblingsDam > 1 && numPages - pageSiblingsDam <= 3) {
      _pages = pages
        .slice(pageSiblingsDam - 1, pageSiblingsDam)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const renderPaginationHorseCompetition = () => {
    const pages = [];

    const numPages = Math.ceil(
      totalCountHorseCompetition / takeHorseCompetition
    );

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <React.Fragment>
          <span
            key={i}
            style={{
              ...pagenumberStyle,
              background:
                i === pageHorseCompetition
                  ? "rgba(0, 0, 0)"
                  : pagenumberStyle.background,
              color:
                i === pageHorseCompetition ? "white" : pagenumberStyle.color,
            }}
            onClick={() => handlePageChangeHorseCompetition(i)}
          >
            {i}
          </span>
        </React.Fragment>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageHorseCompetition === 1) {
      _pages = pages
        .slice(pageHorseCompetition - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseCompetition > 1) {
      _pages = pages.slice(pageHorseCompetition);
    }
    if (pageHorseCompetition > 1 && numPages - pageHorseCompetition > 3) {
      _pages = pages
        .slice(pageHorseCompetition - 1, pageHorseCompetition)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseCompetition === 1 && numPages - pageHorseCompetition <= 3) {
      _pages = pages;
      if (studBookHorseCompetitionData.length === 0) {
        _pages = [];
      }
    }

    if (pageHorseCompetition > 1 && numPages - pageHorseCompetition <= 3) {
      _pages = pages
        .slice(pageHorseCompetition - 1, pageHorseCompetition)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const getKeys = (key) => {
    if (key === "birthDate") {
      return translate("web.birthDate", "Birthdate");
    } else if (key === "age") {
      return translate("web.age", "Age");
    } else if (key === "countryName") {
      return translate("web.countryName", "Country Name");
    } else if (key === "registerNumber") {
      return translate("web.regno", "Reg No");
    } else if (key === "owner") {
      return translate("web.owner", "Owner");
    } else if (key === "ownerId") {
      return translate("web.ownerId", "Owner Id");
    } else if (key === "breeder") {
      return translate("web.breeder", "Breeder");
    } else if (key === "color") {
      return translate("web.color", "Color");
    } else if (key === "origin") {
      return translate("web.origin", "Origin");
    } else if (key === "gender") {
      return translate("web.gender", "Gender");
    } else if (key === "sire") {
      return translate("web.sire", "Sire");
    } else if (key === "dam") {
      return translate("web.dam", "dam");
    } else if (key === "horseUeln") {
      return translate("web.horseUeln", "UELN");
    } else if (key === "importCountry" && !!studBookHorseDetailsData?.isImported) {
      return translate("web.importCountry", "Import Country");
    } else if (key === "importDate" && !!studBookHorseDetailsData?.isImported) {
      return translate("web.importDate", "Import Date");
    } else if (key === "exportCountry" && !!studBookHorseDetailsData?.isExported) {
      return translate("web.exportCountry", "Export Country");
    } else if (key === "exportDate" && !!studBookHorseDetailsData?.isExported) {
      return translate("web.exportDate", "Export Date");
    }
  };


  const [siblingsOfsireDamHovered, setSiblingsOfsireDamHovered] =
    useState(false);
  const [siblingsOfsireDamActive, setSiblingsOfsireDamActive] = useState(true);

  const [siblingsDamHovered, setSiblingsDamHovered] = useState(false);
  const [siblingsDamActive, setSiblingsDamActive] = useState(false);

  const [siblingsSireHovered, setSiblingsSireHovered] = useState(false);
  const [siblingsSireActive, setSiblingsSireActive] = useState(false);

  const [selectedSearchType, setSelectedSearchType] = useState(1);

  const aboutArray = Object.entries(studBookHorseDetailsData);
  console.debug("studBookHorseDetailsData", studBookHorseDetailsData);
  console.debug("aboutArray", aboutArray);
  const handleSelectedSearchType = (e) => {
    setSelectedSearchType(e);
    //dispatch(resetSearch());
  };

  useEffect(() => {
    let params = {
      id: currentHorseId,
      langauge: currentLanguage,
    };

    dispatch(FetchStudBookHorseDetailsthunk(params));
  }, [currentLanguage, currentHorseId, dispatch]);

  // console.log(ownerDetailsData);
  useEffect(() => {
    let paramsProgeny = {
      id: currentHorseId,
      skip: (pageProgeny - 1) * takeProgeny,
      take: takeProgeny,
      langauge: currentLanguage,
    };
    dispatch(FetchStudBookHorseProgenythunk(paramsProgeny));
  }, [pageProgeny, currentLanguage, currentHorseId]);

  useEffect(() => {
    if (selectedSearchType === 1) {
      let paramsSiblingsSireAndDam = {
        id: currentHorseId,
        skip: (pageSiblingsSireAndDam - 1) * takeSiblingsSireAndDam,
        take: takeSiblingsSireAndDam,
        langauge: currentLanguage,
      };
      dispatch(FetchStudBookSiblingsSireAndDamthunk(paramsSiblingsSireAndDam));
    } else if (selectedSearchType === 2) {
      let paramsSiblingsSire = {
        id: currentHorseId,
        skip: (pageSiblingsSire - 1) * takeSiblingsSire,
        take: takeSiblingsSire,
        langauge: currentLanguage,
      };
      dispatch(FetchStudBookSiblingsSirethunk(paramsSiblingsSire));
    } else if (selectedSearchType === 3) {
      let paramsSiblingsDam = {
        id: currentHorseId,
        skip: (pageSiblingsDam - 1) * takeSiblingsDam,
        take: takeSiblingsDam,
        langauge: currentLanguage,
      };
      dispatch(FetchStudBookSiblingsDamthunk(paramsSiblingsDam));
    }
  }, [
    pageSiblingsSireAndDam,
    pageSiblingsSire,
    pageSiblingsDam,
    selectedSearchType,
    currentLanguage,
    currentHorseId,
  ]);

  useEffect(() => {
    let paramsHorseCompetition = {
      id: currentHorseId,
      skip: (pageHorseCompetition - 1) * takeHorseCompetition,
      take: takeHorseCompetition,
      langauge: currentLanguage,
    };
    dispatch(FetchStudBookHorseCompetitionthunk(paramsHorseCompetition));
  }, [pageHorseCompetition, currentLanguage, currentHorseId]);

  useEffect(() => {
    let paramsHorsePedigree = {
      id: currentHorseId,
      langauge: currentLanguage,
    };
    dispatch(FetchStudBookHorsePedigreeByIdthunk(paramsHorsePedigree));
  }, [currentLanguage, currentHorseId]);

  useEffect(() => {
    dispatch(FetchStudBookLanguagethunk(currentLanguage === "en" ? "1" : "2"));
  }, [currentLanguage]);

  useEffect(() => {
    if (Object.keys(studBookHorseDetailsData).length !== 0) {
      if (studBookHorseDetailsData.ownerIdGuid != null) {
        dispatch(
          FetchStudBookOwnerDetailsthunk({
            ownerId: studBookHorseDetailsData.ownerIdGuid,
            langauge: currentLanguage,
          })
        );
      }
      if (studBookHorseDetailsData.breederOwnerIdGuid != null) {
        dispatch(
          FetchStudBookBreederOwnerDetailsthunk({
            ownerId: studBookHorseDetailsData.breederOwnerIdGuid,
            langauge: currentLanguage,
          })
        );
      }
    }
  }, [currentLanguage, studBookHorseDetailsData]);

  // check if the hourse has WahoTrophy
  const [wahoTrophy, setWahoTrophy] = useState([]);
  async function getWahoList() {
    try {
      const response = await axiosInstance.get(`/wahotrophy/gethorsewahotrophies?id=${currentHorseId}&lang=${currentLanguage}`)
      if (response.data.responseCode == "200") {
        setWahoTrophy(response.data.result);
      } else {
        console.log('error in getting wahoList')
      }
    } catch (error) {
      console.log('error in getting wahoList')
    }
  }

  useEffect(() => {

    getWahoList();
  }, [currentHorseId, currentLanguage])

  return (
    <div className="container-fluid p-0-50px">
      <div className="row m0">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <p className="font25-b right-to-left">
            {studBookLoading && <Loader />}
            {!!aboutArray &&
              aboutArray.length > 0 &&
              !!aboutArray[3] &&
              aboutArray[3][1]}
            {wahoTrophy.length > 0 &&
              wahoTrophy.map((item, index) => (
                <div className="">
                  <i class="fa fa-trophy " style={{ margin: "0px 5px;", color: '#c89c66' }}></i> {item.year} {index + 1 < wahoTrophy.length ? " - " : ''}
                </div>
              ))
            }
            {!!!studBookHorseDetailsData?.isAlive &&
              <span className="text-danger rtl" style={{ margin: "auto 10px" }}>
                ({translate('web.dead', 'Dead')}{!!studBookHorseDetailsData?.deathDate && ("  " + studBookHorseDetailsData?.deathDate)})
              </span>
            }
          </p>

          <CollapseLabel name={translate("web.aboutHorse", "About Horse")}>
            {
              <div className="row m0 right-to-left">
                <StudBookCard title={translate("web.birthDate", "Birthdate")} text={studBookHorseDetailsData?.birthDate} />
                <StudBookCard title={translate("web.regno", "Reg No")} text={'# ' + studBookHorseDetailsData?.registerNumber} />
                <StudBookCard title={translate("web.owner", "Owner")} text={studBookHorseDetailsData?.owner} isOwner={true} ownerDetailsData={ownerDetailsData} navigate={navigate} />
                <StudBookCard title={translate("web.breeder", "Breeder")} text={studBookHorseDetailsData?.breeder}   />
                <StudBookCard title={translate("web.color", "color")} text={studBookHorseDetailsData?.color} />
                <StudBookCard title={translate("web.origin", "Origin")} text={studBookHorseDetailsData?.origin} />
                <StudBookCard title={translate("web.gender", "Gender")} text={translate('web.' + studBookHorseDetailsData?.gender, studBookHorseDetailsData?.gender)} />
                <StudBookCard title={translate("web.sire", "Sire")} text={studBookHorseDetailsData?.sire} isSire={true} sireId={studBookPedigreeData?.sireId} />
                <StudBookCard title={translate("web.dam", "Dam")} text={studBookHorseDetailsData?.dam} isDam={true} damId={studBookPedigreeData?.damId} />
                <StudBookCard title={translate("web.horseUeln", "UELN")} text={studBookHorseDetailsData?.horseUeln} />
                {studBookHorseDetailsData?.isExported &&
                  <>
                    <StudBookCard title={translate("web.exportCountry", "Export Country")} text={studBookHorseDetailsData?.exportCountry} />
                    <StudBookCard title={translate("web.exportDate", "Export Date")} text={studBookHorseDetailsData?.exportDate} />
                  </>
                }
                {studBookHorseDetailsData?.isImported &&
                  <>
                    <StudBookCard title={translate("web.importCountry", "Import Country")} text={studBookHorseDetailsData?.importCountry} />
                    <StudBookCard title={translate("web.importDate", "Import Date")} text={studBookHorseDetailsData?.importDate} />
                  </>
                }
              </div>
            }

          </CollapseLabel>
          <CollapseLabel name={translate("web.pedigree", "Pedigree")}>
            {!!studBookPedigreeData && (
              <HorseDetailsPedigreeChart hierarchy={studBookPedigreeData} />
            )}
          </CollapseLabel>
          <CollapseLabel name={translate("web.progeny", "Progeny")}>
            <div className="row rtl p0" style={{ width: studBookProgenyData.length === 0 ? '100%' : 'unset' }}>
              <StudBookTab
                Data={studBookProgenyData}
                handlePageChange={handlePageChange}
                page={pageProgeny}
                renderPagination={renderPagination}
                take={takeProgeny}
                totalCount={totalCountProgeny}
                noBackGround={true}
              />
            </div>
          </CollapseLabel>
          <CollapseLabel name={translate("web.siblings", "Siblings")}>
            <div className="row p0 studbookTabsrow rtl">
              <div className="col-lg-2 width100Arabic768 floatRight">
                <span className="studbookTabSpan"
                  style={{
                    ...TabStyle,
                    background:
                      siblingsOfsireDamHovered || siblingsOfsireDamActive
                        ? "#E44D52"
                        : TabStyle.background,
                    color:
                      siblingsOfsireDamHovered || siblingsOfsireDamActive
                        ? "#fff"
                        : TabStyle.color,
                  }}
                  onMouseOver={() => setSiblingsOfsireDamHovered(true)}
                  onMouseOut={() => setSiblingsOfsireDamHovered(false)}
                  onClick={() => {
                    setSiblingsOfsireDamActive(true);
                    setSiblingsSireActive(false);
                    setSiblingsDamActive(false);
                    handleSelectedSearchType(1);
                  }}
                >
                  {translate("web.siblingsOfSireDam", "Siblings Of Sire Dam")}
                </span>
              </div>
              <div className="col-lg-2 width100Arabic768 floatRight">
                <span className="studbookTabSpan"
                  style={{
                    ...TabStyle,
                    background:
                      siblingsDamHovered || siblingsDamActive
                        ? "#E44D52"
                        : TabStyle.background,
                    color:
                      siblingsDamHovered || siblingsDamActive
                        ? "#fff"
                        : TabStyle.color,
                  }}
                  onMouseOver={() => setSiblingsDamHovered(true)}
                  onMouseOut={() => setSiblingsDamHovered(false)}
                  onClick={() => {
                    setSiblingsOfsireDamActive(false);
                    setSiblingsSireActive(false);
                    setSiblingsDamActive(true);
                    handleSelectedSearchType(3);
                  }}
                >
                  {translate("web.siblingsDam", "Siblings Dam")}
                </span>
              </div>
              <div className="col-lg-2 width100Arabic768 floatRight">
                <span className="studbookTabSpan"
                  style={{
                    ...TabStyle,
                    background:
                      siblingsSireHovered || siblingsSireActive
                        ? "#E44D52"
                        : TabStyle.background,
                    color:
                      siblingsSireHovered || siblingsSireActive
                        ? "#fff"
                        : TabStyle.color,
                  }}
                  onMouseOver={() => setSiblingsSireHovered(true)}
                  onMouseOut={() => setSiblingsSireHovered(false)}
                  onClick={() => {
                    setSiblingsOfsireDamActive(false);
                    setSiblingsSireActive(true);
                    setSiblingsDamActive(false);
                    handleSelectedSearchType(2);
                  }}
                >
                  {translate("web.siblingsSire", "Siblings Sire")}
                </span>
              </div>
              {selectedSearchType === 1 && (
                <StudBookTab
                  Data={studBookSiblingsSireAndDamData}
                  handlePageChange={handlePageChangeSiblingsSireAndDam}
                  page={pageSiblingsSireAndDam}
                  renderPagination={renderPaginationSiblingsSireAndDam}
                  take={takeSiblingsSireAndDam}
                  totalCount={totalCountSiblingsSireAndDam}
                />

              )}

              {selectedSearchType === 2 && (
                <StudBookTab Data={studBookSiblingsSireData} handlePageChange={handlePageChangeSiblingsSire} page={pageSiblingsSire} renderPagination={renderPaginationSiblingsSire} take={takeSiblingsSire} totalCount={totalCountSiblingsSire} />
              )}

              {selectedSearchType === 3 && (
                <StudBookTab
                  Data={studBookSiblingsDamData}
                  handlePageChange={handlePageChangeSiblingsDam}
                  page={pageSiblingsDam}
                  renderPagination={renderPaginationSiblingsDam}
                  take={takeSiblingsDam}
                  totalCount={totalCountSiblingsDam}
                />
              )}
            </div>
          </CollapseLabel>
          <CollapseLabel name={translate("web.competitions", "Competitions")}>
            <CompetitionsTab
              studBookHorseCompetitionData={studBookHorseCompetitionData}
              translate={translate}
              pageHorseCompetition={pageHorseCompetition}
              handlePageChangeHorseCompetition={handlePageChangeHorseCompetition}
              renderPaginationHorseCompetition={renderPaginationHorseCompetition}
              totalCountHorseCompetition={totalCountHorseCompetition}
              takeHorseCompetition={takeHorseCompetition}
            />
          </CollapseLabel>
        </div>
      </div>
    </div>
  );
}


