import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';



export default function TranslationText({ defaultText, textkey }) {
    const [text, setText] = useState(defaultText)
    const { studBookLanguageData } = useSelector((state) => state.studBookSlice);
    
    useEffect(() => {
        setText((studBookLanguageData[textkey?.toLowerCase()]?.trim() ?? "" ) || defaultText);
    }, [studBookLanguageData]);

    
    return (<>{studBookLanguageData[textkey?.toLowerCase()] || defaultText}</>)
}

 
export function useTranslate() {
    const { studBookLanguageData } = useSelector((state) => state.studBookSlice);
    const translate = (keyLabel, defaultLabel) => {
        return studBookLanguageData[keyLabel?.toLowerCase()] || defaultLabel;
    }
    return { translate }
}