import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { useTranslate } from '../components/TranslationText';
import EahsForm from '../components/EahsForm';
import NetaqInput from '../components/NetaqInput';
import axiosInstance from '../axios/axios';
import { create, forEach, take } from 'lodash';
import AsyncSelect from 'react-select/async';
import Loader from '../components/Loader';
import ActionButton from '../components/ActionButton';
import { ToastContainer, toast } from "react-toastify";
import { formatDate } from '../helper/formatDate';
import { convertToCamelCase } from '../helper/convertToCamelCase';


export default function AddFoalHorse() {
    const { translate } = useTranslate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const [damsList, setDamsList] = useState([]);
    const [isSireRegistered, setIsSireRegisterd] = useState(false);
    const currentLanguage = localStorage.getItem("eahsLang") ?? "en";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showNameAr = searchParams.has("showNameAr")
        ? searchParams.get("showNameAr")
        : "";

    const showNameEn = searchParams.has("showNameEn")
        ? searchParams.get("showNameEn")
        : "";
    const showId = searchParams.has("showId") ? searchParams.get("showId") : "";
    const horseId = searchParams.has("horseId") ? searchParams.get("horseId") : "";
    const isEdit = searchParams.has("isEdit") ? searchParams.get("isEdit") == "true" : false;

    const todaysDate = new Date();
    const minAllowedAgentDateOfBirth = `${todaysDate.getFullYear()}-${String(todaysDate.getMonth() + 1).padStart(2, '0')}-${String(todaysDate.getDate()).padStart(2, '0')}`;

    async function getshowentryownerdamhorses() {
        try {
            const res = await axiosInstance.post('/showentry/getshowentryownerdamhorses', {
                ownerId: localStorage.getItem("currentSubAccountId") ?? "",
                showId: showId ?? "",
                skip: 0,
                take: 1000,
                createdBy: localStorage.getItem("EAHSUserID") ?? "",
            });
            if (res.status === 200) {
                const horsesList = res?.data?.horsesList?.map((horse) => ({
                    label: `${horse.registerNumber} - ${horse.name}`,
                    value: horse.id,
                    text: `${horse.registerNumber} - ${horse.name}`,
                    ...horse
                })) ?? [];
                setDamsList(horsesList);
            }


        } catch (error) {
            console.log(error);
            setDamsList([]);

        }

    }

    const [horseColors, setHorseColors] = useState([]);

    async function getHorseColors() {
        try {
            const res = await axiosInstance.get('/lookup/gethorsecolors');
            if (res.status === 200) {
                const colorsList = res?.data?.result?.map((color) => ({
                    label: color.nameEn,
                    value: color.id,
                    text: color.nameEn,
                    ...color
                })) ?? [];
                setHorseColors(colorsList);
            }

        } catch (error) {
            console.log(error);
        }
    }


    // /api/showentry/addeditearlyyearlinghorse
    const [foalHorseDetails, setFoalHorseDetails] = useState({
        Id: isEdit ? horseId : "",
        ShowId: showId ?? "",
        NameAr: "",
        NameEn: "",
        Gender: "",
        BirthDate: "",
        HorseColorId: "",
        DamId: "",
        SireId: "",
        SireNameAr: "",
        SireNameEn: "",
    });

    const [selectedSire, setSelectedSire] = useState({});
    const [selectedDam, setSelectedDam] = useState({});
    const handleChange = (e) => {
        setFoalHorseDetails({
            ...foalHorseDetails,
            [e.target.name]: e.target.value
        })
    };


    async function GetEarlyYearlingHorseById() {
        try {
            const res = await axiosInstance.get(`/showentry/GetEarlyYearlingHorseById?id=${horseId}`);
            if (res.status === 200) {
                const horseDetails = res?.data.result ?? {};
                const birthDate = new Date(horseDetails?.birthDate)
                const minAllowedAgentDateOfBirth = `${birthDate.getFullYear()}-${String(birthDate.getMonth() + 1).padStart(2, '0')}-${String(birthDate.getDate()).padStart(2, '0')}`;

                setFoalHorseDetails({
                    Id: horseDetails?.id ?? '',
                    ShowId: showId ?? "",
                    DamId: horseDetails?.damId ?? "",
                    NameAr: horseDetails?.nameAr ?? "",
                    NameEn: horseDetails?.nameEn ?? "",
                    Gender: horseDetails?.gender ?? "",
                    BirthDate: minAllowedAgentDateOfBirth ?? "",
                    HorseColorId: horseDetails?.horseColorId ?? "",
                    SireId: horseDetails?.isSireRegistered ? horseDetails?.sireId ?? "" : '',
                    SireNameAr: horseDetails?.sireNameAr ?? "",
                    SireNameEn: horseDetails?.sireNameEn ?? "",

                });
                setIsSireRegisterd(horseDetails?.isSireRegistered);
                setSelectedDam({
                    label: horseDetails.damRegNo + " - " + (currentLanguage == 'en' ? horseDetails.damNameEn : horseDetails.damNameAr),
                    value: horseDetails.damId,
                })
                if (horseDetails?.isSireRegistered) {
                    setSelectedSire({
                        label: horseDetails.sireRegNo + " - " + (currentLanguage == 'en' ? horseDetails.sireNameEn : horseDetails.sireNameAr),
                        value: horseDetails.sireId,
                    })
                }
                debugger;

            }
        } catch (error) {
            console.log(error);
        }

    }


    useEffect(() => {
        getHorseColors();
        getshowentryownerdamhorses();
        if (isEdit) {
            setTimeout(() => {
                GetEarlyYearlingHorseById();
            }, 200);
        }
    }, []);


    const [inputValue, setInputValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(0);
    const promiseOptions = (searchKeyword) =>
        new Promise((resolve) => {
            if (timeoutId > 0) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(async () => {
                const response = await axiosInstance.post('/StudBook/FilterHorses', { langauge: localStorage.getItem('eahsLang') ?? 'en', registrationNumber: searchKeyword, genderType: [2], skip: 0, take: 10000 })
                const data = await response.data.horsesList;
                const options = data.map((item) => ({
                    label: item.ownerId + " - " + item.name,
                    value: item.id,
                    ...item
                }));
                resolve(options);
            }, 1000);

            setTimeoutId(id);
        });


    const submitFoalHorse = async () => {

        // validate all required fields
        for (const [key, value] of Object.entries(foalHorseDetails)) {
            if (key === "Id" || key === "SireId") {
                continue;
            }
            if (!value) {
                toast.error(`${translate(`web.${key?.toLowerCase()}`, key)} ${translate(`web.isRequired`, 'Is Required')} `);
                return;
            }
        }
        setIsSubmitting(true);
        try {
            const formData = new FormData();
            for (const key in foalHorseDetails) {
                formData.append(key, foalHorseDetails[key]);
            }


            const response = await axiosInstance.post('/showentry/addeditearlyyearlinghorse', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                setIsSubmitting(false);
                if (response?.data?.responseCode == "200") {
                    toast.success(translate('web.horseAddedSuccessfuly', 'Horse Added Successfuly'));
                    setTimeout(() => {
                        navigate(
                            `/showentrycard${window.location.search}`
                        );
                    }, 1000);
                }
                else {
                    toast.error(translate(response?.data?.responseText, 'Horse Not Qulified'));
                    setTimeout(() => {
                        navigate(
                            `/showentrycard${window.location.search}`
                        );
                    }, 1000);
                }
            } else {
                setIsSubmitting(false);

                toast.error(translate('web.failedtosubmitFoalHorsedetails', 'Failed to submit Foal Horse details'));
            }
        } catch (error) {
            console.error(error);
            setIsSubmitting(false);

            toast.error('An error occurred while submitting Foal Horse details');
        }



    }

    const dismissAll = () => toast.dismiss();
    const genderList = [
        { label: translate('web.mare', "Mare"), value: 1 },
        { label: translate('web.stallion', "Stallion"), value: 2 },
        { label: translate('web.gelding', "Gelding"), value: 3 },
    ];
    const handleInputChange = (newValue, { action }) => {
        debugger;
        setInputValue(newValue);

        if (action === 'clear') {
            console.log("Cleared!");
            setSelectedSire({});
            setIsSireRegisterd(false);

        }
    };
    return (
        <div className="container-fluid container-fluid-padding">
            <ToastContainer
                position="top-right"
                onClick={dismissAll}
                theme="colored"
                autoClose={5000}
            />
            <div className="row rtl">
                <div className="col-md-12 col-sm-12">
                    <h3 className="fw-bold">
                        {currentLanguage === "en" ? showNameEn : showNameAr}
                    </h3>
                </div>
                <EahsForm title={translate(
                    "web.addFoalHorse",
                    "Add Foal Horse"
                )}>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"horseName"}
                            label={translate("web.horseNameEn", "Horse NameEn")}
                            placeholder={translate("web.horseNameEn", "Horse NameEn")}
                            name={"NameEn"}
                            value={foalHorseDetails?.NameEn ?? ""}
                            onChange={handleChange}
                            required={true} />
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"horseName"}
                            label={translate("web.horseNameAr", "Horse NameAr")}
                            placeholder={translate("web.horseNameAr", "Horse NameAr")}
                            name={"NameAr"}
                            onChange={handleChange}
                            value={foalHorseDetails?.NameAr ?? ""}
                            required={true} />
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"normalDate"}
                            label={translate('web.birthDate', "Birth Date")}
                            name={"BirthDate"}
                            required={true}
                            value={foalHorseDetails?.BirthDate ?? ""}
                            maxDate={minAllowedAgentDateOfBirth}
                            onChange={handleChange} />
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"select"}
                            label={translate('web.gender', "Gender")}
                            name={"Gender"}
                            required={true}
                            placeholder={translate('web.select', "Select")}
                            value={!!foalHorseDetails?.Gender ? genderList.find((item) => item.value === foalHorseDetails?.Gender) : ""}
                            listSelect={genderList}
                            onChange={(selectedItem) => {
                                setFoalHorseDetails({ ...foalHorseDetails, Gender: selectedItem?.value })
                            }}
                        />
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"select"}
                            label={translate('web.color', "Color")}
                            name={"HorseColorId"}
                            required={true}
                            value={!!foalHorseDetails?.HorseColorId ? horseColors.find((item) => item.value === foalHorseDetails?.HorseColorId) : ""}
                            listSelect={horseColors}
                            placeholder={translate('web.select', "Select")}
                            onChange={(selectedItem) => {
                                setFoalHorseDetails({ ...foalHorseDetails, HorseColorId: selectedItem?.value })
                            }}

                        />
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"select"}
                            label={translate('web.dam', "Dam")}
                            name={"dam"}
                            required={true}
                            listSelect={damsList}
                            value={selectedDam}
                            placeholder={translate('web.select', "Select")}
                            onChange={(selectedItem) => {
                                setSelectedDam(selectedItem);
                                setFoalHorseDetails({ ...foalHorseDetails, DamId: selectedItem?.value })
                            }}

                        />
                    </div>

                    <div className="col-lg-12 mt-3 col-md-12 col-sm-12  rtl ">


                        <NetaqInput type='checkbox' checked={isSireRegistered} value={isSireRegistered} onChange={(e) => { setIsSireRegisterd(e.target.checked); setSelectedSire(null); setFoalHorseDetails({ ...foalHorseDetails, SireNameAr: "", SireNameEn: "", SireId: "" }); }} name='isSireRegisterd' label={translate("web.isRegisterdSire", "Is Registerd Sire")} />
                    </div>
                    <div className="col-lg-6  col-md-6 col-sm-12  rtl ">
                        <label className="capatalize_text">
                            {translate('web.sire', "Sire")}
                        </label>
                        <AsyncSelect
                            isClearable
                            loadOptions={promiseOptions}
                            defaultOptions={false} // Set to true to load initial options
                            onInputChange={handleInputChange}
                            inputValue={inputValue}
                            value={selectedSire}
                            loadingMessage={() => translate("web.loading", "Loading")}
                            onChange={(selectedItem) => {
                                setSelectedSire(selectedItem);
                                if (selectedItem == null) {
                                    setIsSireRegisterd(false);
                                } else {
                                    setIsSireRegisterd(true);
                                }
                                setFoalHorseDetails({ ...foalHorseDetails, SireNameAr: selectedItem?.horseNameAr ?? "", SireNameEn: selectedItem?.horseNameEn ?? "", SireId: selectedItem?.value ?? "" });
                            }}
                            placeholder={translate("web.typeToSearch", "Type To Search") + "..."}
                            noOptionsMessage={() => translate("web.noOptions", "No Options")}
                        />
                    </div>
                    <div className="col-lg-6  col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"horseName"}
                            label={translate("web.sireNameEn", "sire NameEn")}
                            placeholder={translate("web.sireNameEn", "sire NameEn")}
                            name={"SireNameEn"}
                            onChange={handleChange}
                            value={foalHorseDetails?.SireNameEn ?? ""}
                            disabled={isSireRegistered}
                            required={true} />
                    </div>
                    <div className="col-lg-6 mt-2 col-md-6 col-sm-12  rtl ">
                        <NetaqInput
                            type={"horseName"}
                            label={translate("web.sireNameAr", "sire NameAr")}
                            placeholder={translate("web.sireNameAr", "sire NameAr")}
                            name={"SireNameAr"}
                            value={foalHorseDetails?.SireNameAr ?? ""}
                            onChange={handleChange}
                            disabled={isSireRegistered}
                            required={true} />
                    </div>
                    <div className={"col-sm-12 text-end"}>
                        {isSubmitting ? <Loader /> : <ActionButton
                            onClick={submitFoalHorse}
                            margin={25}
                            width={100}
                            key={"Add"}
                            text={translate("web.submit", "Submit")}
                            type={"submit"}
                        />
                        }
                    </div>
                </EahsForm>



            </div>
        </div>
    )
}
