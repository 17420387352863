import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
    FetchCreateOrEditImportHorseFrozenSemenServiceRequestthunk,
    FetchGetServiceDefinitionByIdthunk,
    FetchGetServicesRequestByIdthunk,
    FetchGetShippingAgentsthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
    resetGetServicesRequestByIdData,
    resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import dayjs from "dayjs";
import EahsForm from "../components/EahsForm";
import { FetchStudBookCountriesthunk } from "../Redux/StudBook/StudBookAction";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { base64ToBlob } from "../helper/base64ToBlob";
import { FetchHorseColorsthunk } from "../Redux/LookUp/LookUpAction";
import { FetchCreateOrEditTemporaryHorseImportAPI } from "../Redux/ServicesRequests/ServicesRequestsApi";

export default function ImportHorseTemporary() {
    //env
    //const UMBRACOURL = process.env.REACT_APP_UMBRACO;
    const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
    const BaseURL = process.env.REACT_APP_BASE_URL;

    //selector
    const { studBookCountries, currentLanguage } = useSelector((state) => state.studBookSlice);

    const { horseColorsData } = useSelector(
        (state) => state.lookUpSlice
    );

    const {
        toasterMessageServicesRequests,
        servicesRequestsLoading,
        getServicesRequestByIdData,
        getServiceDefinitionByIdData,
        importHorseTemporaryData,
        getShippingAgentsData,
    } = useSelector((state) => state.servicesRequestsSlice);

    const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
    const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

    const dispatch = useDispatch();

    const { translate } = useTranslate();

    const navigate = useNavigate();

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const serviceId = searchParams.has("serviceId")
        ? searchParams.get("serviceId")
        : null;

    const id = searchParams.has("id") ? searchParams.get("id") : null;

    const action = searchParams.has("action") ? searchParams.get("action") : null;

    //state
    const [profile, setProfile] = useState({
        label:
            !!userProfile &&
            userProfile?.ownerRegisterNo +
            " - " +
            userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
        value: localStorage.getItem("EAHSUserID"),
    });

    const [formModel, setFormModel] = useState({
        id: "",
        temporaryHorseImportsId: "",
        eventName: "",
        comment: "",
        referenceNumber: "",
        requestsType: "",
        paymentStatus: "",

        horseImportType: "TEMPORARY",
        importDate: null,
        outDate: null,

        importCountry: "",
        importCountryDrop: {
            label: translate("web.chooseCountry", "Choose Country"),
            value: null,
        },

        uaePlace: "",
        uaeQuarantine: "",
        importPurpose: "",
        evName: "",

        horses: [],
        horseName: "",
        horseNameAr: "",
        regNo: 0,
        sire: "",
        dam: "",
        microchipNo: "",
        dateFoaled: "",
        color: "",
        colorDrop: {
            label: translate("web.color", "Color"),
            value: null,
        },
        sex: "Stallion",

        otherOwner: false,

        ownerName: profile?.label,
        email: userProfile?.email,
        mobile: "",
        agentMobile: "",

        shippingAgent: "",
        shippingAgentDrop: {
            label: translate("web.shippingAgent", "Shipping Agent"),
            value: null,
        },

        ownerComment: "",

        filePathHorsePassport: "",
        fileTypeHorsePassport: "",
        fileNameHorsePassport: "",
        documentBase64HorsePassport: "",
        horsePassportAttachmentFile: null,

        filePathResidencePermit: "",
        fileTypeResidencePermit: "",
        fileNameResidencePermit: "",
        documentBase64ResidencePermit: "",
        residencePermitAttachmentFile: null,
    });

    const [errorImportDate, setErrorImportDate] = useState(false);
    const [errorOutDate, setErrorOutDate] = useState(false);
    const [errorImportCountry, setErrorImportCountry] = useState(false);
    const [errorUaePlace, setErrorUaePlace] = useState(false);
    const [errorUaeQuarantine, setErrorUaeQuarantine] = useState(false);
    const [errorImportPurpose, setErrorImportPurpose] = useState(false);
    const [errorEvName, setErrorEvName] = useState(false);

    const [errorHorseName, setErrorHorseName] = useState(false);
    const [errorHorseNameAr, setErrorHorseNameAr] = useState(false);
    const [errorRegNo, setErrorRegNo] = useState(false);
    const [errorSire, setErrorSire] = useState(false);
    const [errorDam, setErrorDam] = useState(false);
    const [errorMicrochipNo, setErrorMicrochipNo] = useState(false);
    const [errorDateFoaled, setErrorDateFoaled] = useState(false);
    const [errorColor, setErrorColor] = useState(false);

    const [errorOwnerName, setErrorOwnerName] = useState(false);
    const [errorMobile, setErrorMobile] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorAgentMobile, setErrorAgentMobile] = useState(false);

    const [errorShippingAgent, setErrorShippingAgent] = useState(false);

    const [errorHorsePassport, setErrorHorsePassport] = useState(false);
    const [errorResidencePermit, setErrorResidencePermit] = useState(false);

    //use ref
    const importDateRef = useRef(null);
    const outDateRef = useRef(null);
    const importCountryRef = useRef(null);
    const uaePlaceRef = useRef(null);
    const uaeQuarantineRef = useRef(null);
    const importPurposeRef = useRef(null);
    const evNameRef = useRef(null);
    const horseNameRef = useRef(null);
    const horseNameArRef = useRef(null);
    const regNoRef = useRef(null);
    const sireRef = useRef(null);
    const damRef = useRef(null);
    const microchipNoRef = useRef(null);
    const dateFoaledRef = useRef(null);
    const colorRef = useRef(null);
    const horsePassportRef = useRef(null);

    const ownerNameRef = useRef(null);
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const agentMobileRef = useRef(null);
    const shippingAgentRef = useRef(null);
    const residencePermitRef = useRef(null);

    const [
        horsePassportAttachmentFile,
        setHorsePassportAttachmentFile,
    ] = useState([]);

    const [
        residencePermitAttachmentFile,
        setResidencePermitAttachmentFile,
    ] = useState([]);

    const _isOnlinePayment = useRef("");

    const [checkedItems, setCheckedItems] = useState({
        option1: false,
        option2: false,
    });

    const subAccountId = localStorage.getItem("currentSubAccountId");

    const customStyles = {
        control: (base) => ({
            ...base,
            height: 50,
            minHeight: 50,
        }),
    };

    //function
    const handleFormChange = (e, input) => {
        if (input === "horseImportType") {
            setFormModel({
                ...formModel,
                horseImportType: e.target.value,
            });
        }
        else if (input === "importCountry") {
            setFormModel({
                ...formModel,
                importCountry: e.value,
                importCountryDrop: e,
            });
        }
        else if (!!input && input === "color") {
            setFormModel({
                ...formModel,
                color: e.value,
                colorDrop: e,
            });
        }
        else if (input === "otherOwner") {
            setFormModel({
                ...formModel,
                otherOwner: e.target.checked,
                ownerName: e.target.checked ? "" : profile?.label,
                email: e.target.checked ? "" : userProfile?.email,
                mobile: "",
                agentMobile: "",
                residencePermitAttachmentFile: null
            });

            setResidencePermitAttachmentFile([]);
        }
        else if (input === "shippingAgent") {
            setFormModel({
                ...formModel,
                shippingAgent: e.value,
                shippingAgentDrop: e,
            });
        }
        else if (!!input && input === "horseName") {
            const regex = /^[a-zA-Z. ]*$/;

            if (regex.test(e.target.value)) {
                setFormModel({
                    ...formModel,
                    horseName: e.target.value.toUpperCase(),
                });
            }

        } else if (!!input && input === "horseNameAr") {
            const regex = /^[\u0621-\u064A\u0660-\u0669. ]+$/;

            if (regex.test(e.target.value)) {
                setFormModel({
                    ...formModel,
                    horseNameAr: e.target.value,
                });
            }
        }
        else if (input === "sire" || input === "dam") {
            setFormModel({
                ...formModel,
                [e.target.name]: e.target.value.toUpperCase(),
            });
        }

        else {
            setFormModel({
                ...formModel,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleUploadHorsePassportChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
            } else {
                setFormModel({
                    ...formModel,
                    horsePassportAttachmentFile: e.target.files[0],
                });

                setHorsePassportAttachmentFile([
                    {
                        file: e.target.files[0],
                        name: e.target.files[0].name,
                    },
                ]);
            }
        }
    };

    const handleUploadResidencePermitChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
            } else {
                setFormModel({
                    ...formModel,
                    residencePermitAttachmentFile: e.target.files[0],
                });

                setResidencePermitAttachmentFile([
                    {
                        file: e.target.files[0],
                        name: e.target.files[0].name,
                    },
                ]);
            }
        }
    };

    const handelAddHorse = () => {
        let valid = true;

        if (formModel.horseName.trim() === "") {
            setErrorHorseName(true);
            valid = false;

            horseNameRef?.current?.focus();
            return;
        }
        else {
            setErrorHorseName(false);
        }

        if (formModel.horseImportType === "PERMANENT" && formModel.horseNameAr.trim() === "") {
            setErrorHorseNameAr(true);
            valid = false;

            horseNameArRef?.current?.focus();
            return;
        }
        else {
            setErrorHorseNameAr(false);
        }

        if (formModel.horseImportType === "PERMANENT" && formModel.regNo.trim() === "") {
            setErrorRegNo(true);
            valid = false;

            regNoRef?.current?.focus();
            return;
        }
        else {
            setErrorRegNo(false);
        }

        if (formModel.microchipNo.trim() === "") {
            setErrorMicrochipNo(true);
            valid = false;

            microchipNoRef?.current?.focus();
            return;
        }
        else {
            setErrorMicrochipNo(false);
        }

        if (formModel.sire.trim() === "") {
            setErrorSire(true);
            valid = false;

            sireRef?.current?.focus();
            return;
        }
        else {
            setErrorSire(false);
        }

        if (formModel.dam.trim() === "") {
            setErrorDam(true);
            valid = false;

            damRef?.current?.focus();
            return;
        }
        else {
            setErrorDam(false);
        }

        if (formModel.dateFoaled == null) {
            setErrorDateFoaled(true);
            valid = false;

            dateFoaledRef?.current?.focus();
            return;
        }
        else {
            setErrorDateFoaled(false);
        }

        if (formModel.color === "") {
            setErrorColor(true);
            valid = false;

            colorRef?.current?.focus();
            return;
        }
        else {
            setErrorColor(false);
        }

        if (formModel.horsePassportAttachmentFile == null) {
            setErrorHorsePassport(true);
            valid = false;

            horsePassportRef?.current?.focus();
            return;
        }
        else {
            setErrorHorsePassport(false);
        }

        if (valid) {

            let newItem = {
                horseName: formModel.horseName,
                horseNameAr: formModel.horseNameAr,
                regNo: formModel.regNo,
                microchipNo: formModel.microchipNo,
                sire: formModel.sire,
                dam: formModel.dam,
                dateFoaled: dayjs(formModel.dateFoaled).format("DD/MM/YYYY"),
                color: formModel.color,
                sex: formModel.sex,
                horsePassport: formModel.horsePassportAttachmentFile
            };

            setFormModel({
                ...formModel,
                horses: [...formModel.horses, newItem],
                horseName: "",
                horseNameAr: "",
                regNo: 1,
                microchipNo: "",
                sire: "",
                dam: "",
                dateFoaled: "",
                color: "",
                colorDrop: {
                    label: translate("web.color", "Color"),
                    value: null,
                },
                sex: "Stallion",
                horsePassportAttachmentFile: null,
            });

            setHorsePassportAttachmentFile([]);
        }
    }

    const removeHorse = (indexToRemove) => {
        setFormModel({
            ...formModel,
            horses: formModel.horses.filter((_, index) => index !== indexToRemove)
        });
    };

    const validInputs = () => {
        let valid = true;

        if (
            formModel.horseImportType === "TEMPORARY" && formModel.evName.trim() === ""
        ) {
            setErrorEvName(true);
            valid = false;

            evNameRef?.current?.focus();
            return;
        }
        else {
            setErrorEvName(false);
        }

        if (
            formModel.importCountry === ""
        ) {
            setErrorImportCountry(true);
            valid = false;

            importCountryRef?.current?.focus();
            return;
        }
        else {
            setErrorImportCountry(false);
        }

        if (
            formModel.importDate === null
        ) {
            setErrorImportDate(true);
            valid = false;

            importDateRef?.current?.focus();
            return;
        }
        else {
            setErrorImportDate(false);
        }

        if (
            formModel.horseImportType === "TEMPORARY" && formModel.outDate === null
        ) {
            setErrorOutDate(true);
            valid = false;

            outDateRef?.current?.focus();
            return;
        }
        else {
            setErrorOutDate(false);
        }

        if (
            formModel.importPurpose.trim() === ""
        ) {
            setErrorImportPurpose(true);
            valid = false;

            importPurposeRef?.current?.focus();
            return;
        }
        else {
            setErrorImportPurpose(false);
        }

        if (
            formModel.horseImportType === "TEMPORARY" && formModel.uaePlace.trim() === ""
        ) {
            setErrorUaePlace(true);
            valid = false;

            uaePlaceRef?.current?.focus();
            return;
        }
        else {
            setErrorUaePlace(false);
        }

        if (
            formModel.horseImportType === "PERMANENT" && formModel.uaeQuarantine.trim() === ""
        ) {
            setErrorUaeQuarantine(true);
            valid = false;

            uaeQuarantineRef?.current?.focus();
            return;
        }
        else {
            setErrorUaeQuarantine(false);
        }

        if (
            formModel.horses.length === 0
        ) {
            toast.error(
                translate("web.pleaseAddHorseToList", "Please Add Horse To List")
            );
            valid = false;
        }

        if (formModel.otherOwner) {
            if (formModel.ownerName === "") {
                setErrorOwnerName(true);
                valid = false;

                ownerNameRef?.current?.focus();
                return;
            }
            else {
                setErrorOwnerName(false);
            }

            if (formModel.email === "") {
                setErrorEmail(true);
                valid = false;

                emailRef?.current?.focus();
                return;
            }
            else {
                setErrorEmail(false);
            }

            if (formModel.mobile === "") {
                setErrorMobile(true);
                valid = false;

                mobileRef?.current?.focus();
                return;
            }
            else {
                setErrorMobile(false);
            }

            if (formModel.agentMobile === "") {
                setErrorAgentMobile(true);
                valid = false;

                agentMobileRef?.current?.focus();
                return;
            }
            else {
                setErrorAgentMobile(false);
            }

            if (
                residencePermitAttachmentFile.length === 0
                && formModel.documentBase64ResidencePermit === ""
            ) {
                setErrorResidencePermit(true);
                valid = false;

                residencePermitRef?.current?.focus();
                return;
            }
            else {
                setErrorResidencePermit(false);
            }
        }

        if (
            formModel.shippingAgent === ""
        ) {
            setErrorShippingAgent(true);
            valid = false;

            shippingAgentRef?.current?.focus();
            return;
        }
        else {
            setErrorShippingAgent(false);
        }

        // if (
        //     horsePassportAttachmentFile.length === 0
        //     && formModel.documentBase64HorsePassport === ""
        // ) {
        //     setErrorHorsePassport(true);
        //     valid = false;
        // }
        // else {
        //     setErrorHorsePassport(false);
        // }

        //term
        if (
            !checkedItems.option1
            || !checkedItems.option2
        ) {
            toast.error(
                translate("web.pleaseCheckAllTerms", "Please Check All Terms")
            );
            valid = false;
        }

        return valid;
    }

    const handelFormSubmit = (status, isOnlinePayment) => {
        if (getServiceDefinitionByIdData.serviceFees > 0 && formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
            const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

            const encodeURI = encodeURIComponent(returnUrl);

            window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=21&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
        }
        else {
            if (
                validInputs()
            ) {
                _isOnlinePayment.current = isOnlinePayment;

                let params = {
                    id: id == null ? '' : id,
                    ownerId: getUserID(),
                    status: status,
                    ownerComment: formModel.ownerComment,
                    temporaryHorseImportsId: formModel.temporaryHorseImportsId,
                    horseImportType: formModel.horseImportType,
                    eventName: formModel.evName,
                    importFrom: formModel.importCountry,
                    purposeOfImport: formModel.importPurpose,
                    dateOfImport: dayjs(formModel.importDate).format("DD/MM/YYYY"),
                    endDateOfImport: formModel.outDate != null ? dayjs(formModel.outDate).format("DD/MM/YYYY") : "",
                    uAEPlaceOfDestination: formModel.uaePlace,
                    uaeQuarantine: formModel.uaeQuarantine,
                    authAgentPhoneNo: formModel.agentMobile,
                    ownerType: formModel.otherOwner ? 2 : 1,
                    shippingAgentId: formModel.shippingAgent,
                    ownerFullName: formModel.ownerName,
                    ownerEmail: formModel.email,
                    ownerPhoneNo: formModel.mobile,
                    ownerPassportOrEmiratesIdCopyFile: formModel.residencePermitAttachmentFile,
                    horses: formModel.horses,
                };

                dispatch(FetchCreateOrEditTemporaryHorseImportAPI(params));
            }
            else {
                toast.error(
                    translate("web.completeTheRequiredData", "Complete The Required Data")
                );
            }
        }
    };

    const handelSelectedAccountChange = async (element) => {
        setProfile(element);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });
    };

    function CheckIfAgentHasPermission() {
        const AgentHasPermission = userProfile.subAccounts
            .filter((x) => x.value == subAccountId)[0]
            .ownersPermissionsList.some(
                (x) =>
                    x.permissionName === "ImportHorseTemporary" || x.permissionName === "All"
            );

        return AgentHasPermission;
    }

    const getUserID = () => {
        const userID =
            !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;

        return userID;
    };

    const getSelectedAccountName = () => {
        const selectAccount = userProfile.subAccounts.filter(
            (x) => x.value == subAccountId
        );

        if (selectAccount.length > 0) {
            return selectAccount[0];
        }
        else {
            return false;
        }
    };

    const dismissAll = () => toast.dismiss();

    const handelDisabledButton = () => {
        if (
            action === "view"
            || (action === "approve" && formModel.eventName !== "SentBack")
        ) {
            return true;
        }
        else {
            return false;
        }
    };

    const handelDisabledOwnerInputs = () => {
        if (
            (!formModel.otherOwner && (formModel.eventName === "" || formModel.eventName === "Draft" || formModel.eventName === "SentBack"))
            || (action === "view" || (action === "approve" && formModel.eventName !== "SentBack"))
        ) {
            return true;
        }
        else {
            return false;
        }
    };

    //Effect
    useEffect(() => {
        if (id != null) {
            let params = {
                language: currentLanguage,
                id: id,
            };

            dispatch(FetchGetServicesRequestByIdthunk(params));
        }

        return () => {
            dispatch(resetGetServicesRequestByIdData());
        };
    }, []);

    useEffect(() => {
        if (!!userProfile && userProfile?.haveProfileSignture != true) {
            toast.error(
                translate(
                    "web.completeOwnerProfileData",
                    "Please complete the personal data of the profile."
                )
            );

            setTimeout(() => {
                navigate("/profile");
            }, 3000);
        }

        if (!!userProfile && userProfile?.accountType === "Agent") {
            if (userProfile.id == subAccountId) {
                navigate("/403-Not-Authrized");
            }

            if (!CheckIfAgentHasPermission()) {
                navigate("/403-Not-Authrized");
            }
        }

        dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

        let subOwnerProfilesParams = {
            id: getUserID(),
            langauge: currentLanguage,
        };
        dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

        dispatch(FetchStudBookCountriesthunk(currentLanguage));

        dispatch(FetchGetShippingAgentsthunk());

        dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
    }, [currentLanguage]);

    useEffect(() => {
        if (getServicesRequestByIdData != null &&
            Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
        ) {
            setFormModel({
                ...formModel,
                eventName: getServicesRequestByIdData.eventName,
                comment: getServicesRequestByIdData.comment,
                requestsType: getServicesRequestByIdData.requestsType,
                paymentStatus: getServicesRequestByIdData.paymentStatus,
                referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,

                temporaryHorseImportsId: getServicesRequestByIdData.temporaryHorseImports.id,
                horseImportType: getServicesRequestByIdData.temporaryHorseImports.horseImportType,
                importCountry: getServicesRequestByIdData.temporaryHorseImports.importFrom,
                importCountryDrop: {
                    label: getServicesRequestByIdData.temporaryHorseImports.importFromName,
                    value: getServicesRequestByIdData.temporaryHorseImports.importFrom,
                },
                importPurpose: getServicesRequestByIdData.temporaryHorseImports.PurposeOfImport,
                importDate: dayjs(getServicesRequestByIdData.temporaryHorseImports.dateOfImport).format("YYYY-MM-DD"),
                outDate: getServicesRequestByIdData.temporaryHorseImports.endDateOfImport != null ? dayjs(getServicesRequestByIdData.temporaryHorseImports.endDateOfImport).format("YYYY-MM-DD") : null,
                uaePlace: getServicesRequestByIdData.temporaryHorseImports.uAEPlaceOfDestination != null ? getServicesRequestByIdData.temporaryHorseImports.uAEPlaceOfDestination : "",
                uaeQuarantine: getServicesRequestByIdData.temporaryHorseImports.uaeQuarantine != null ? getServicesRequestByIdData.temporaryHorseImports.uaeQuarantine : "",
                evName: getServicesRequestByIdData.temporaryHorseImports.eventName != null ? getServicesRequestByIdData.temporaryHorseImports.eventName : "",
                agentMobile: getServicesRequestByIdData.temporaryHorseImports.authAgentPhoneNo != null ? getServicesRequestByIdData.temporaryHorseImports.authAgentPhoneNo : "",
                ownerComment: getServicesRequestByIdData.temporaryHorseImports.ownerComment != null ? getServicesRequestByIdData.temporaryHorseImports.ownerComment : "",
                ownerType: getServicesRequestByIdData.temporaryHorseImports.ownerType === 1 ? false : true,
                shippingAgent: getServicesRequestByIdData.temporaryHorseImports.shippingAgentId,
                shippingAgentDrop: {
                    label: getServicesRequestByIdData.temporaryHorseImports.shippingAgentName,
                    value: getServicesRequestByIdData.temporaryHorseImports.shippingAgentId,
                },
                ownerName: getServicesRequestByIdData.temporaryHorseImports.ownerFullName,
                email: getServicesRequestByIdData.temporaryHorseImports.ownerEmail,
                mobile: getServicesRequestByIdData.temporaryHorseImports.ownerPhoneNo != null ? getServicesRequestByIdData.temporaryHorseImports.ownerPhoneNo : "",
                horses: getServicesRequestByIdData.horseTempImportDetailsDto.map((q) => ({
                    horseName: q.horseName,
                    horseNameAr: q.horseNameAr,
                    regNo: q.registerNumber,
                    sire: q.sire,
                    dam: q.dam,
                    dateFoaled: dayjs(q.birthDateTime).format("DD/MM/YYYY"),
                    microchipNo: q.microchipNo,
                    sex: q.sex === 1 ? "Mare" : q.sex === 2 ? "Stallion" : "Gelding",
                    color: q.color,
                    horsePassport: q.horsePassport.fileBase64
                })),

                filePathResidencePermit: getServicesRequestByIdData.temporaryHorseImports.ownerPassport.filePath != null ? getServicesRequestByIdData.temporaryHorseImports.ownerPassport.filePath : "",
                fileTypeResidencePermit: getServicesRequestByIdData.temporaryHorseImports.ownerPassport.fileType != null ? getServicesRequestByIdData.temporaryHorseImports.ownerPassport.fileType : "",
                fileNameResidencePermit: getServicesRequestByIdData.temporaryHorseImports.ownerPassport.fileName != null ? getServicesRequestByIdData.temporaryHorseImports.ownerPassport.fileName : "",
                documentBase64ResidencePermit: getServicesRequestByIdData.temporaryHorseImports.ownerPassport.fileBase64,
            });

            //another owner
            if (
                getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId !==
                getServicesRequestByIdData.importHorseFrozenSemenDto.createdBy &&
                subOwnerProfilesByIdData.length > 0 &&
                getServicesRequestByIdData.requestsType !== "BackOffice"
            ) {
                //update profile
                setProfile({
                    label:
                        subOwnerProfilesByIdData.filter(
                            (item) =>
                                item.value ==
                                getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId
                        )[0].registerNo +
                        " - " +
                        subOwnerProfilesByIdData.filter(
                            (item) =>
                                item.value ==
                                getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId
                        )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
                    value: subOwnerProfilesByIdData.filter(
                        (item) =>
                            item.value == getServicesRequestByIdData.importHorseFrozenSemenDto.ownerId
                    )[0].value,
                });
            }

            //options
            setCheckedItems({
                option1: true,
                option2: true,
            });
        }
    }, [
        getServicesRequestByIdData,
    ]);

    useEffect(() => {
        if (
            toasterMessageServicesRequests != null &&
            toasterMessageServicesRequests === "save"
        ) {
            if (_isOnlinePayment.current !== "") {
                toast.success(
                    translate(
                        "web.yourRequestHasSubmittedSuccessfully",
                        "Your Request Has Submitted Successfully"
                    )
                );
            } else {
                toast.success(
                    translate("web.dataSavedSuccessfully", "Data Saved Successfully")
                );
            }

            dispatch(resetToasterMessageServicesRequests());

            setTimeout(() => {
                if (_isOnlinePayment.current !== "") {
                    const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

                    const encodeURI = encodeURIComponent(returnUrl);

                    window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${importHorseTemporaryData.id}&paymentType=21&userId=${localStorage.getItem(
                        "EAHSUserID"
                    )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
                } else {
                    navigate(`/profile?activeTab=${"e_services"}`);
                }
            }, 1000);
        }

        //error
        else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
            if (toasterMessageServicesRequests !== "errorServiceDetails") {

                toast.error(
                    translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
                );

                dispatch(resetToasterMessageServicesRequests());
            }

            else if (toasterMessageServicesRequests === "errorServiceDetails") {
                toast.error(
                    translate("web.createServiceRequestFailed", "Service Request Failed")
                );

                dispatch(resetToasterMessageServicesRequests());

                setTimeout(() => {
                    navigate(`/profile?activeTab=${"e_services"}`);
                }, 1000);
            }
        }
    }, [toasterMessageServicesRequests]);

    //consoles
    console.log(formModel)

    return (
        <div className="container-fluid container-fluid-padding">

            {/* loading div */}
            <div
                className={`page-overlay text-center ${servicesRequestsLoading
                    ? "classdisplay"
                    : "classnotdisplay"
                    }`}
            >
                <div className="loaderDiv">
                    <Loader />
                </div>
            </div>

            {/* toast */}
            <ToastContainer
                position="top-right"
                onClick={dismissAll}
                theme="colored"
                autoClose={5000}
            />

            {/* content page */}
            <div className="row rtl">
                <div className="col-md-12 col-sm-12 row rtl">

                    {/* update Arabian Horse Data */}
                    <div className="col-md-12 col-sm-12 mb-4">
                        <h1 className="fontWeight display-inline">
                            {currentLanguage === "en"
                                ? getServiceDefinitionByIdData.nameEn
                                : getServiceDefinitionByIdData.nameAr}
                        </h1>
                        <h3 className="fontWeight display-inline">
                            {formModel.referenceNumber}{" "}
                        </h3>
                    </div>

                    {/* Selected Account */}
                    {subOwnerProfilesByIdData.length > 0 &&
                        (action == null || action === "edit") &&
                        (
                            <div className="col-md-6 col-sm-12 mb-3">
                                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                                    <>
                                        <label className="capatalize_text">
                                            {translate("web.selectedAccount", "Selected Account")}
                                        </label>
                                        <Select
                                            className="mt-10 zindex200"
                                            options={[
                                                {
                                                    label:
                                                        userProfile?.ownerRegisterNo +
                                                        " - " +
                                                        userProfile[
                                                        `fullName${currentLanguage === "en" ? "" : "Ar"}`
                                                        ],
                                                    value: localStorage.getItem("EAHSUserID"),
                                                },
                                                ...(!!subOwnerProfilesByIdData &&
                                                    !!subOwnerProfilesByIdData.length > 0 &&
                                                    subOwnerProfilesByIdData.map((a) => ({
                                                        label:
                                                            a?.registerNo +
                                                            " - " +
                                                            a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                                                        value: a.value,
                                                    }))),
                                            ]}
                                            value={profile}
                                            onChange={(e) => {
                                                handelSelectedAccountChange(e);
                                            }}
                                            placeholder={localStorage.getItem("EAHSUserFullName")}
                                            isSearchable
                                            noOptionsMessage={() =>
                                                translate("web.noOptions", "No Options")
                                            }
                                            styles={customStyles}
                                            key={"EAHSUserFullName"}
                                        />
                                    </>
                                ) : (
                                    <label className="capatalize_text">
                                        {getSelectedAccountName()?.registerNo +
                                            " - " +
                                            getSelectedAccountName()[
                                            `text${currentLanguage === "en" ? "" : "Ar"}`
                                            ]}
                                    </label>
                                )}
                            </div>
                        )}

                    <EahsForm title={translate("web.importDetails", "Import Details")}>
                        <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                            <div style={{ maxWidth: 150 }}>
                                <NetaqInput
                                    name={"horseImportType"}
                                    type={"radioServiceRequest"}
                                    value={"TEMPORARY"}
                                    label={`${translate("web.temporary", "Temporary")}-`}
                                    onChange={(e) => handleFormChange(e, "horseImportType")}
                                    checked={
                                        formModel.horseImportType === "TEMPORARY"
                                    }
                                    disabled={action == null ? false : true}
                                />
                            </div>

                            <div style={{ maxWidth: 150 }}>
                                <NetaqInput
                                    name={"horseImportType"}
                                    type={"radioServiceRequest"}
                                    value={"PERMANENT"}
                                    label={`${translate("web.permanent", "Permanent")}-`}
                                    onChange={(e) => handleFormChange(e, "horseImportType")}
                                    checked={
                                        formModel.horseImportType === "PERMANENT"
                                    }
                                    disabled={action == null ? false : true}
                                />
                            </div>
                        </div>

                        {formModel.horseImportType === "TEMPORARY" && (
                            <div className="col-md-6 col-sm-12 mt-3">
                                <NetaqInput
                                    label={translate("web.evName", "Event Name")}
                                    name={"evName"}
                                    type={"text"}
                                    onChange={handleFormChange}
                                    value={formModel.evName}
                                    required={true}
                                    disabled={handelDisabledButton()}
                                    ref={evNameRef}
                                />
                                {errorEvName && (
                                    <p style={{ color: "red" }}>
                                        {translate("web.requiredField", "Required Field")}
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="col-md-6 col-sm-12 mt-3">
                            <label>
                                {translate("web.importFrom", "Import From")}
                                <i className="colorred"> *</i>
                            </label>
                            <Select
                                options={[
                                    {
                                        label: translate("web.chooseCountry", "Choose Country"),
                                        value: null,
                                    },
                                    ...(!!studBookCountries &&
                                        studBookCountries.map((a) => ({
                                            label: a.text,
                                            value: a.value,
                                        }))),
                                ]}
                                value={formModel.importCountryDrop}
                                onChange={(e) => handleFormChange(e, "importCountry")}
                                placeholder={translate("web.chooseCountry", "Choose Country")}
                                name={"importCountry"}
                                isSearchable
                                noOptionsMessage={() =>
                                    translate("web.noOptions", "No Options")
                                }
                                autoFocus={true}
                                key={"importCountry"}
                                isDisabled={handelDisabledButton()}
                                ref={importCountryRef}
                            />
                            {errorImportCountry && (
                                <span style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </span>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 rtl service mt-3">
                            <NetaqInput
                                htmlTags={
                                    formModel.importDate ?
                                        <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                            {
                                                dayjs(formModel.importDate).format("DD/MM/YYYY")
                                            }
                                        </p> :
                                        <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                            DD/MM/YYYY
                                        </p>
                                }
                                name={"importDate"}
                                type={"normalDateService"}
                                label={translate("web.importDate", "Date Of Import")}
                                onChange={handleFormChange}
                                value={formModel.importDate}
                                required={true}
                                maxDate={dayjs().format("YYYY-MM-DD")}
                                disabled={handelDisabledButton()}
                                ref={importDateRef}
                            />

                            {errorImportDate && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        {formModel.horseImportType === "TEMPORARY" && (
                            <div className="col-md-6 col-sm-12 rtl service mt-3">
                                <NetaqInput
                                    htmlTags={
                                        formModel.outDate ?
                                            <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                                {
                                                    dayjs(formModel.outDate).format("DD/MM/YYYY")
                                                }
                                            </p> :
                                            <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                                DD/MM/YYYY
                                            </p>
                                    }
                                    name={"outDate"}
                                    type={"normalDateService"}
                                    label={translate("web.outDate", "Date Of Out")}
                                    onChange={handleFormChange}
                                    value={formModel.outDate}
                                    required={true}
                                    maxDate={dayjs().format("YYYY-MM-DD")}
                                    disabled={handelDisabledButton()}
                                    ref={outDateRef}
                                />

                                {errorOutDate && (
                                    <p style={{ color: "red" }}>
                                        {translate("web.requiredField", "Required Field")}
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.importPurpose", "Import Purpose")}
                                name={"importPurpose"}
                                type={"text"}
                                onChange={handleFormChange}
                                value={formModel.importPurpose}
                                required={true}
                                disabled={handelDisabledButton()}
                                ref={importPurposeRef}
                            />
                            {errorImportPurpose && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>
                        {formModel.horseImportType === "TEMPORARY" && (
                            <div className="col-md-6 col-sm-12 mt-3">
                                <NetaqInput
                                    label={translate("web.uaePlace", "UAE Place")}
                                    name={"uaePlace"}
                                    type={"text"}
                                    onChange={handleFormChange}
                                    value={formModel.uaePlace}
                                    required={true}
                                    disabled={handelDisabledButton()}
                                    ref={uaePlaceRef}
                                />
                                {errorUaePlace && (
                                    <p style={{ color: "red" }}>
                                        {translate("web.requiredField", "Required Field")}
                                    </p>
                                )}
                            </div>
                        )}

                        {formModel.horseImportType === "PERMANENT" && (
                            <div className="col-md-6 col-sm-12 mt-3">
                                <NetaqInput
                                    label={translate("web.uaeQuarantine", "UAE Quarantine")}
                                    name={"uaeQuarantine"}
                                    type={"text"}
                                    onChange={handleFormChange}
                                    value={formModel.uaeQuarantine}
                                    required={true}
                                    disabled={handelDisabledButton()}
                                    ref={uaeQuarantineRef}
                                />
                                {errorUaeQuarantine && (
                                    <p style={{ color: "red" }}>
                                        {translate("web.requiredField", "Required Field")}
                                    </p>
                                )}
                            </div>
                        )}

                    </EahsForm>

                    <EahsForm title={translate("web.horseDetails", "horse Details")}>
                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.name", "Name") + " (EN)"}
                                name={"horseName"}
                                type={"text"}
                                onChange={(e) => handleFormChange(e, "horseName")}
                                value={formModel.horseName}
                                required={true}
                                disabled={handelDisabledButton()}
                                ref={horseNameRef}
                                maxchars={27}
                            />
                            {errorHorseName && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.name", "Name") + " (AR)"}
                                name={"horseNameAr"}
                                type={"text"}
                                onChange={(e) => handleFormChange(e, "horseNameAr")}
                                value={formModel.horseNameAr}
                                required={formModel.horseImportType === "PERMANENT"}
                                disabled={handelDisabledButton()}
                                maxchars={28}
                                ref={horseNameArRef}
                            />
                            {errorHorseNameAr && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.regno", "Reg.No")}
                                name={"regNo"}
                                key={"regNo"}
                                type={"number"}
                                onChange={handleFormChange}
                                value={formModel.regNo}
                                minValue={0}
                                required={formModel.horseImportType === "PERMANENT"}
                                disabled={handelDisabledButton()}
                                ref={regNoRef}
                            />
                            {errorRegNo && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.microchipNo", "Microchip No")}
                                name={"microchipNo"}
                                type={"text"}
                                onChange={handleFormChange}
                                value={formModel.microchipNo}
                                required={true}
                                disabled={handelDisabledButton()}
                                ref={microchipNoRef}
                            />
                            {errorMicrochipNo && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.sire", "Sire")}
                                name={"sire"}
                                type={"text"}
                                onChange={(e) => handleFormChange(e, "sire")}
                                value={formModel.sire}
                                required={true}
                                disabled={handelDisabledButton()}
                                ref={sireRef}
                            />
                            {errorSire && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.dam", "Dam")}
                                name={"dam"}
                                type={"text"}
                                onChange={(e) => handleFormChange(e, "dam")}
                                value={formModel.dam}
                                required={true}
                                disabled={handelDisabledButton()}
                                ref={damRef}
                            />
                            {errorDam && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 rtl service mt-3">
                            <NetaqInput
                                htmlTags={
                                    formModel.dateFoaled ?
                                        <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                            {
                                                dayjs(formModel.dateFoaled).format("DD/MM/YYYY")
                                            }
                                        </p> :
                                        <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                            DD/MM/YYYY
                                        </p>
                                }
                                name={"dateFoaled"}
                                type={"normalDateService"}
                                label={translate("web.dateFoaled", "Date Foaled")}
                                onChange={handleFormChange}
                                value={formModel.dateFoaled}
                                required={true}
                                maxDate={dayjs().format("YYYY-MM-DD")}
                                disabled={handelDisabledButton()}
                                ref={dateFoaledRef}
                            />

                            {errorDateFoaled && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 rtl service mt-3">
                            <label>
                                {translate("web.color", "Color")}
                                <i className="colorred"> *</i>
                            </label>
                            <Select
                                options={[
                                    {
                                        label: translate("web.color", "Color"),
                                        value: null,
                                    },
                                    ...(!!horseColorsData &&
                                        horseColorsData.map((a) => ({
                                            label: a.nameEn,
                                            value: a.id,
                                        }))),
                                ]}
                                value={formModel.colorDrop}
                                onChange={(e) => handleFormChange(e, "color")}
                                placeholder={translate("web.color", "Color")}
                                name={"color"}
                                isSearchable
                                noOptionsMessage={() =>
                                    translate("web.noOptions", "No Options")
                                }
                                key={"color"}
                                isDisabled={handelDisabledButton()}
                                ref={colorRef}
                            />
                            {errorColor && (
                                <span style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </span>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 rtl service mt-3">
                            <label>
                                {translate("web.sex", "Sex")}
                            </label>
                            <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                                <div style={{ maxWidth: 150 }}>
                                    <NetaqInput
                                        name={"sex"}
                                        type={"radioServiceRequest"}
                                        value={"Stallion"}
                                        label={`${translate("web.stallion", "Stallion")}-`}
                                        onChange={handleFormChange}
                                        checked={formModel.sex === "Stallion"}
                                        disabled={handelDisabledButton()}
                                    />
                                </div>
                                <div style={{ maxWidth: 150 }}>
                                    <NetaqInput
                                        name={"sex"}
                                        type={"radioServiceRequest"}
                                        value={"Mare"}
                                        label={`${translate("web.mare", "Mare")}-`}
                                        onChange={handleFormChange}
                                        checked={
                                            formModel.sex === "Mare"
                                        }
                                        disabled={handelDisabledButton()}
                                    />
                                </div>
                                <div style={{ maxWidth: 150 }}>
                                    <NetaqInput
                                        name={"sex"}
                                        type={"radioServiceRequest"}
                                        value={"Gelding"}
                                        label={`${translate("web.gelding", "Gelding")}-`}
                                        onChange={handleFormChange}
                                        checked={formModel.sex === "Gelding"}
                                        disabled={handelDisabledButton()}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 mb-25 mt-3 align-items-center">
                            <label>
                                {translate("web.horsePassport", "Horse Passport")}
                                <i className="colorred"> *</i>
                            </label>
                            <div>
                                <SimpleFileUpload
                                    isMulti={false}
                                    initialFiles={
                                        !!formModel.filePathHorsePassport &&
                                            !!formModel.documentBase64HorsePassport &&
                                            horsePassportAttachmentFile.length === 0
                                            ? [
                                                {
                                                    file: base64ToBlob(
                                                        formModel.documentBase64HorsePassport,
                                                        formModel.fileTypeHorsePassport ===
                                                            ".pdf"
                                                            ? "application/pdf"
                                                            : "image/png"
                                                    ),
                                                    name: formModel.fileNameHorsePassport,
                                                },
                                            ]
                                            : horsePassportAttachmentFile
                                    }
                                    isServer={
                                        !!formModel.filePathHorsePassport &&
                                            !!formModel.documentBase64HorsePassport &&
                                            horsePassportAttachmentFile.length === 0
                                            ? true
                                            : false
                                    }
                                    onChange={handleUploadHorsePassportChange}
                                    accept={"image/*,application/pdf"}
                                    disabled={handelDisabledButton()}
                                    ref={horsePassportRef}
                                />
                                {errorHorsePassport && (
                                    <span style={{ color: "red" }}>
                                        {translate("web.requiredField", "Required Field")}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-md-10 col-sm-12 rtl service mt-3">
                        </div>

                        <div className="col-md-2 col-sm-12 rtl service mt-3">
                            <ActionButton
                                type={"bootstrapbtn"}
                                className="btn-info text-white requestBtn mx-auto"
                                label={translate("web.addToList", "Add To List")}
                                text={`<i class="fa-solid fa-circle-plus fa-lg marginrightleft-10"></i>
                                    ${translate("web.addToList", "Add To List")}
                                    `}
                                margin={"auto 10px"}
                                width={"unset"}
                                onClick={handelAddHorse}
                                disabled={handelDisabledButton()}
                            />
                        </div>

                        <div className="scrolableTable mt-5">
                            <table className="table table-striped table-bordered rtl horseResultTable">
                                <thead className="theadHorseSelect">
                                    <tr>
                                        <th className="text-center">
                                            {translate("web.name", "Name") + " (En)"}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.name", "Name") + " (AR)"}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.regno", "Reg.No")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.sire", "Sire")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.dam", "Dam")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.microchipNo", "Microchip No")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.dateFoaled", "Date Foaled")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.color", "Color")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.sex", "Sex")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.horsePassport", "Horse Passport")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.action", "Action")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {formModel.horses?.length > 0 && (
                                        formModel.horses.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.horseName}</td>
                                                <td>{item.horseNameAr}</td>
                                                <td>{item.regNo}</td>
                                                <td>{item.sire}</td>
                                                <td>{item.dam}</td>
                                                <td>{item.microchipNo}</td>
                                                <td>{item.dateFoaled}</td>
                                                <td> {horseColorsData.filter(q => q.id == item.color)[0].nameEn}</td>
                                                <td> {`${translate(`web.${item.sex.toLowerCase()}`, item.sex)}`}</td>
                                                <td>
                                                    <SimpleFileUpload
                                                        isMulti={false}
                                                        initialFiles={
                                                            !!formModel.filePathHorsePassport &&
                                                                !!formModel.documentBase64HorsePassport &&
                                                                horsePassportAttachmentFile.length === 0
                                                                ? [
                                                                    {
                                                                        file: base64ToBlob(
                                                                            formModel.documentBase64HorsePassport,
                                                                            formModel.fileTypeHorsePassport ===
                                                                                ".pdf"
                                                                                ? "application/pdf"
                                                                                : "image/png"
                                                                        ),
                                                                        name: formModel.fileNameHorsePassport,
                                                                    },
                                                                ]
                                                                : [{ file: item.horsePassport, name: item.horsePassport.name }]
                                                        }
                                                        isServer={
                                                            !!formModel.filePathHorsePassport &&
                                                                !!formModel.documentBase64HorsePassport &&
                                                                horsePassportAttachmentFile.length === 0
                                                                ? true
                                                                : false
                                                        }
                                                        //onChange={handleUploadHorsePassportChange}
                                                        accept={"image/*,application/pdf"}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td>
                                                    <ActionButton
                                                        className={"btn-danger requestBtn"}
                                                        type={"requests"}
                                                        label={translate("web.delete", "Delete")}
                                                        text={
                                                            <>
                                                                <i className="fa-solid fa-circle-xmark fa-lg marginrightleft-10"></i>
                                                                {translate("web.delete", "Delete")}
                                                            </>
                                                        }
                                                        margin={"auto 10px"}
                                                        width={"unset"}
                                                        onClick={() => removeHorse(index)}
                                                        disabled={handelDisabledButton()}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>

                        </div>

                    </EahsForm>

                    <EahsForm title={translate("web.ownerDetails", "Owner Details")}>
                        <div className="col-md-12 col-sm-12 rtl p0">
                            <NetaqInput
                                type={"checkbox"}
                                label={translate(
                                    "web.importForOtherOwner",
                                    "Import For Other Owner"
                                )}
                                name="otherOwner"
                                checked={formModel.currentOwner}
                                onChange={(e) => handleFormChange(e, "otherOwner")}
                                disabled={handelDisabledButton()}
                            />
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.ownerName", "Owner Name")}
                                name={"ownerName"}
                                type={"text"}
                                onChange={handleFormChange}
                                value={formModel.ownerName}
                                required={formModel.otherOwner}
                                disabled={handelDisabledOwnerInputs()}
                                ref={ownerNameRef}
                            />
                            {errorOwnerName && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                                label={translate("web.email", "Email")}
                                name={"email"}
                                type={"text"}
                                onChange={handleFormChange}
                                value={formModel.email}
                                required={formModel.otherOwner}
                                disabled={handelDisabledOwnerInputs()}
                                ref={emailRef}
                            />
                            {errorEmail && (
                                <p style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </p>
                            )}
                        </div>

                        {formModel.otherOwner && (
                            <>
                                <div className="col-md-6 col-sm-12 mt-3">
                                    <NetaqInput
                                        label={translate("web.mobile", "Mobile")}
                                        name={"mobile"}
                                        type={"text"}
                                        onChange={handleFormChange}
                                        value={formModel.mobile}
                                        required={true}
                                        disabled={handelDisabledButton()}
                                        ref={mobileRef}
                                    />
                                    {errorMobile && (
                                        <p style={{ color: "red" }}>
                                            {translate("web.requiredField", "Required Field")}
                                        </p>
                                    )}
                                </div>

                                <div className="col-md-6 col-sm-12 mt-3">
                                    <NetaqInput
                                        label={translate("web.agentMobile", "Agent Mobile")}
                                        name={"agentMobile"}
                                        type={"text"}
                                        onChange={handleFormChange}
                                        value={formModel.agentMobile}
                                        required={true}
                                        disabled={handelDisabledButton()}
                                        ref={agentMobileRef}
                                    />
                                    {errorAgentMobile && (
                                        <p style={{ color: "red" }}>
                                            {translate("web.requiredField", "Required Field")}
                                        </p>
                                    )}
                                </div>

                                <div className="col-md-6 col-sm-12 mt-3 mb-25 align-items-center">
                                    <label>
                                        {translate("web.residencePermit", "Residence Permit")}
                                        <i className="colorred"> *</i>
                                    </label>
                                    <div>
                                        <SimpleFileUpload
                                            isMulti={false}
                                            initialFiles={
                                                !!formModel.filePathResidencePermit &&
                                                    !!formModel.documentBase64ResidencePermit &&
                                                    residencePermitAttachmentFile.length === 0
                                                    ? [
                                                        {
                                                            file: base64ToBlob(
                                                                formModel.documentBase64ResidencePermit,
                                                                formModel.fileTypeResidencePermit ===
                                                                    ".pdf"
                                                                    ? "application/pdf"
                                                                    : "image/png"
                                                            ),
                                                            name: formModel.fileNameResidencePermit,
                                                        },
                                                    ]
                                                    : residencePermitAttachmentFile
                                            }
                                            isServer={
                                                !!formModel.filePathResidencePermit &&
                                                    !!formModel.documentBase64ResidencePermit &&
                                                    residencePermitAttachmentFile.length === 0
                                                    ? true
                                                    : false
                                            }
                                            onChange={handleUploadResidencePermitChange}
                                            accept={"image/*,application/pdf"}
                                            disabled={handelDisabledButton()}
                                            ref={residencePermitRef}
                                        />
                                        {
                                            errorResidencePermit && (
                                                <span style={{ color: "red" }}>
                                                    {translate("web.requiredField", "Required Field")}
                                                </span>
                                            )}
                                    </div>
                                </div>
                            </>
                        )}
                    </EahsForm>

                    <EahsForm title={translate("web.shippingAgent", "Shipping Agent")}>
                        <div className="col-md-6 col-sm-12">
                            <label>
                                {translate("web.shippingAgent", "Shipping Agent")}
                                <i className="colorred"> *</i>
                            </label>
                            <Select
                                options={[
                                    {
                                        label: translate("web.shippingAgent", "Shipping Agent"),
                                        value: null,
                                    },
                                    ...(!!getShippingAgentsData &&
                                        getShippingAgentsData.map((a) => ({
                                            label: a.text,
                                            value: a.value,
                                        }))),
                                ]}
                                value={formModel.shippingAgentDrop}
                                onChange={(e) => handleFormChange(e, "shippingAgent")}
                                placeholder={translate("web.shippingAgent", "Shipping Agent")}
                                name={"shippingAgent"}
                                isSearchable
                                noOptionsMessage={() =>
                                    translate("web.noOptions", "No Options")
                                }
                                autoFocus={true}
                                key={"shippingAgent"}
                                isDisabled={handelDisabledButton()}
                                ref={shippingAgentRef}
                            />
                            {errorShippingAgent && (
                                <span style={{ color: "red" }}>
                                    {translate("web.requiredField", "Required Field")}
                                </span>
                            )}
                        </div>
                    </EahsForm>

                    {/* Comments */}
                    {(!!formModel.comment || !!formModel.ownerComment) && (
                        <EahsForm title={translate("web.comments", "Comments")}>
                            {!!formModel.comment && (
                                // eahs Comment
                                <div className="col-md-6 col-sm-12">
                                    <NetaqInput
                                        label={translate("web.eahsComments", "EAHS Comments")}
                                        name={"adminComment"}
                                        type={"textarea"}
                                        maxchars={1150}
                                        value={formModel.comment}
                                        disabled={true}
                                    />
                                </div>
                            )}

                            {(!!formModel.ownerComment || formModel.eventName === "SentBack") && (
                                // owner Comment
                                <div className="col-md-6 col-sm-12">
                                    <NetaqInput
                                        label={translate("web.ownerComment", "Owner Comment")}
                                        name={"ownerComment"}
                                        type={"textarea"}
                                        maxchars={1150}
                                        value={formModel.ownerComment}
                                        onChange={handleFormChange}
                                        disabled={formModel.eventName !== "SentBack" ? true : false}
                                    />
                                </div>
                            )}
                        </EahsForm>
                    )}

                    <EahsForm title={translate("web.terms", "Terms")}>
                        <div className="col-md-12 col-sm-12 rtl p0" >
                            <NetaqInput
                                type={"checkbox"}
                                label={translate(
                                    "web.importHorseTemporaryNote1",
                                    "Import Horse Temporary Note1"
                                )}
                                name="option1"
                                checked={checkedItems.option1}
                                onChange={handleCheckboxChange}
                                disabled={formModel.eventName === "" ? false : true}
                            />
                        </div>

                        <div className="col-md-12 col-sm-12 rtl p0">
                            <NetaqInput
                                type={"checkbox"}
                                label={translate(
                                    "web.ServiceGeneralNote",
                                    "Service General Note"
                                )}
                                name="option2"
                                checked={checkedItems.option2}
                                onChange={handleCheckboxChange}
                                disabled={formModel.eventName === "" ? false : true}
                            />
                        </div>
                    </EahsForm>

                    {/* buttons*/}
                    <div className="col-md-12 col-sm-12 mt-5 text-end">
                        <div className="row mx-0 justify-content-lg-end justify-content-sm-start">
                            {/* draft btn */}
                            {(formModel.eventName === "" || formModel.eventName === "Draft") && (
                                <div className="col-lg-2 my-1">
                                    <ActionButton
                                        type={"bootstrapbtn"}
                                        className="btn btn-outline-danger mx-auto w-100"
                                        label={translate("web.draft", "Draft")}
                                        text={`<i class="fa fa-save fa-lg marginrightleft-10"></i>
                          ${translate("web.draft", "Draft")}
                          `}
                                        margin={"auto 10px"}
                                        width={"100%"}
                                        onClick={() => handelFormSubmit("Draft", "")}
                                        disabled={
                                            formModel.eventName === "" ||
                                                formModel.eventName === "Draft"
                                                ? false
                                                : true
                                        }
                                    />
                                </div>
                            )}

                            {/* pay btn */}
                            {userProfile?.participateWithoutPayment === true && formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                                <div className="col-lg-3 my-1">
                                    <ActionButton
                                        className="btn-info text-white mx-auto"
                                        type={"bootstrapbtn"}
                                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                                        margin={"0"}
                                        width={"100%"}
                                        onClick={() =>
                                            handelFormSubmit("Draft", "no")
                                        }
                                        disabled={
                                            formModel.eventName === "SentBack"
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            )}

                            {formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                                <div className="col-lg-3 my-1">
                                    <ActionButton
                                        className={"btn-success requestBtn mx-0 mx-auto w-100"}
                                        type={"requests"}
                                        label={translate("web.approvePay", "Approve And Pay")}
                                        text={
                                            <>
                                                <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                                                {translate("web.submitPay", "Submit And Pay")}
                                            </>
                                        }
                                        margin={"0"}
                                        onClick={() =>
                                            handelFormSubmit("Draft", "yes")
                                        }
                                        disabled={
                                            formModel.eventName === "SentBack"
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            )}

                            {/* submit btn */}
                            {(getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack") && (
                                <div className="col-lg-2 my-sm-1">
                                    <ActionButton
                                        type={"requests"}
                                        className="btn-success text-white requestBtn w-100 mx-auto"
                                        label={translate("web.submit", "Submit")}
                                        text={
                                            <>
                                                <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                                                {translate("web.submit", "Submit")}
                                            </>
                                        }
                                        margin={"auto 25px"}
                                        width={"100%"}
                                        onClick={() =>
                                            handelFormSubmit("Submit", "")
                                        }
                                        disabled={
                                            getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack"
                                                ? false
                                                : true
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// (
//     (action == null || action === "edit")
//     ||
//     (
//         action === "approve"
//         &&
//         (
//             formModel.eventName === "SentBack"
//             ||
//             (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
//         )
//     )
// )